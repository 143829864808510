import React from 'react';

import { Alert } from 'antd';

interface IProps {
	message?: string;
	children: any;
}

class ErrorBoundary extends React.Component<IProps, any> {
	constructor(props: IProps) {
		super(props);
		this.state = { hasError: false };
	}

	static getDerivedStateFromError(error: any) {
		// Update state so the next render will show the fallback UI.
		return { hasError: true };
	}

	componentDidCatch(error: any, errorInfo: any) {
		// You can also log the error to an error reporting service
		console.log(error, errorInfo);
	}

	render() {
		const { message } = this.props;
		if (this.state.hasError) {
			// You can render any custom fallback UI
			// return <h1>Something went wrong.</h1>;
			return (
				<Alert
					// message="Warning!"
					message={
						message ||
						'Something went wrong - please contact us and mention this issue'
					}
					type="warning"
					showIcon
				/>
			);
		}

		return this.props.children;
	}
}

export default ErrorBoundary;

// // Usage in a component
// class App extends React.Component {
// 	render() {
// 		return (
// 			<ErrorBoundary>
// 				<MyComponent />
// 			</ErrorBoundary>
// 		);
// 	}
// }
