import React from 'react';

import {
	Button,
	Card,
	Checkbox,
	Col,
	Form,
	Input,
	Row,
	Space,
	Typography,
} from 'antd';
import { AxiosError } from 'axios';
import { set } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { useSearchParams } from 'react-router-dom';

import client from '@/lib/http/client';

const ConfirmRegister = () => {
	const [searchParams, setSearchParams] = useSearchParams();
	const [form] = Form.useForm();
	const [loading, setLoading] = React.useState(false);
	const navigate = useNavigate();

	return (
		<Card title="Confirm your email" style={{ minWidth: '428px' }}>
			{localStorage.getItem('email') && (
				<>
					<div
						style={{
							textAlign: 'center',
						}}
					>
						We have sent a verification link to your inbox:
					</div>
					<div style={{ textAlign: 'center' }}>
						{<Typography.Link>{localStorage.getItem('email')}</Typography.Link>}
					</div>
				</>
			)}
			<div
				style={{
					// fontWeight: 'bold',
					textAlign: 'center',
					// padding: '5x 0',
					margin: '5px 0',
				}}
			>
				<Typography.Text strong>Please check your email box</Typography.Text>
			</div>
			<Form
				layout="vertical"
				form={form}
				disabled={loading}
				onFinish={(values) => {
					setLoading(true);
					client
						.post('/users/confirm-email', values)
						.then(() => {
							localStorage.removeItem('email');
							navigate('/auth/login', {
								state: { email: form.getFieldValue('email') },
							});
						})
						.catch((err: AxiosError<any, any>) => {
							setLoading(false);
							if (err.response?.data.message === 'token is incorrect') {
								form.setFields([
									{
										name: 'token',
										errors: ['Verification code is incorrect'],
									},
								]);
							} else {
							}
						});
				}}
			>
				<Form.Item
					label="Email"
					name="email"
					initialValue={
						localStorage.getItem('email') || searchParams.get('email')
					}
					rules={[
						{
							required: true,
						},
					]}
				>
					<Input
						defaultValue={
							localStorage.getItem('email') || searchParams.get('email') || ''
						}
						disabled={
							!!localStorage.getItem('email') || !!searchParams.get('email')
						}
					/>
				</Form.Item>
				<Form.Item
					label="Verification Code"
					name="token"
					initialValue={searchParams.get('token')}
					rules={[
						{
							required: true,
							message: 'Please input your verification code',
						},
					]}
				>
					<Input
						placeholder=""
						defaultValue={searchParams.get('token') || ''}
						disabled={!!searchParams.get('token')}
					/>
				</Form.Item>

				<Form.Item>
					<Button type="primary" htmlType="submit" block loading={loading}>
						Confirm
					</Button>
				</Form.Item>
			</Form>
		</Card>
	);
};

export default ConfirmRegister;
