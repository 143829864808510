import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosRequestConfig } from 'axios';

import client from '@/lib/http/client';

interface ICustomerSubscriptionBody {
	subscription_id: number | string;
	additional_videos_amount: number | string;
	additional_videos_allowed: boolean;
}

function updateCustomerSubscription(data: ICustomerSubscriptionBody) {
	return client.put<any>(`/customer-subscription`, data as AxiosRequestConfig);
}

function getProductSubscription(name: string) {
	return client.get<any>(`/subscriptions/${name}`);
}

export function useUpdateCustomerSubscription() {
	const queryClient = useQueryClient();

	return useMutation(updateCustomerSubscription, {
		onSuccess: () => {
			queryClient.invalidateQueries(['subscriptions']);
		},
	});
}

export function useGetProductSubscription(name: string) {
	return useQuery(['subscriptions', name], () => getProductSubscription(name), {
		enabled: !!name,
	});
}
