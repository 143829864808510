import React, { useEffect, useState } from 'react';

import { Card } from 'antd';

import ChartBar from '@/components/charts/bar';
import ChartSunburst from '@/components/charts/sunburst';

import style from '../style.module.css';

import { sortVerticalGraf } from '../helpers';
import { InfoSection } from '../informationPopop';
import logo from './iab.png';

interface IProps {
	id: string;
	data: any;
}

const ReportContent = (props: IProps) => {
	let [values, setValues] = useState<any[]>([]);
	useEffect(() => {
		if (props.data.iabBarChart.length) {
			const sortedValues = sortVerticalGraf(
				props.data.iabBarChart,
				'Other',
				'label',
			);

			setValues(sortedValues);
		}
	}, []);

	return (
		<div>
			<Card
				title=<div>
					Topic Classifications - Top Level
					<InfoSection
						title="Topic Classifications - Top Level view"
						content="We use the IAB classification standard to group the content generated by accounts into different hierarchical categories. This classification assists in understanding what is being talked about using a universal standard. The top level shows only the highest level categories such as 'Style and fashion' in order to give a general understanding of the focus of the content analysed for the time period selected."
					/>
				</div>
				style={{ marginBottom: '50px' }}
			>
				<div style={{ height: '400px', position: 'relative' }}>
					<ChartBar
						data={values}
						indexBy="label"
						margin={{ top: 50, right: 220, bottom: 50, left: 140 }}
						padding={0.3}
						layout="horizontal"
						valueScale={{ type: 'linear' }}
						indexScale={{ type: 'band', round: true }}
						// colors={(bar) => bar.data.color || 'inherit'}
						labelSkipWidth={12}
						labelSkipHeight={12}
						// labelTextColor={{
						// 	from: 'color',
						// 	modifiers: [['darker', 1.6]],
						// }}
						tooltip={({ data }) => <div>{data.label}</div>}
						ariaLabel="Nivo bar chart demo"
						enableLabel={true}
						barAriaLabel={({ value }) => `${value}%`}
						labelFormat={(value) => `${value}%`}
						valueFormat={(value) => `${value} %`}
						label={(d) => `${d.data.value} %`}
					/>
					{/*
					<a
						href="https://iabtechlab.com/standards/content-taxonomy/"
						target="_blank"
						rel="noreferrer"
					>
						<img className={style.iabLogo} src={logo} alt="" />
					</a>	
	*/}

					<div className={style.iabLogo}>
						Categorised using the{' '}
						<a
							href="https://iabtechlab.com/standards/content-taxonomy/"
							target="_blank"
							rel="noreferrer"
						>
							IAB Tech Lab V3 Taxonomy
						</a>
					</div>
				</div>
			</Card>

			<Card
				title=<div>
					Topic Classifications - Detailed View
					<InfoSection
						title="Topic Classifications - Detailed View"
						content="We use the IAB classification standard to group the content generated by accounts into different hierarchical categories. This classification assists in understanding what is being talked about using a universal standard. The detailed view shows the interaction between high level categories such as 'Style and fashion', and deeper child related categories such as 'beauty' and 'Skin care'."
					/>
				</div>
				style={{ marginBottom: '20px' }}
			>
				<div style={{ height: '800px', position: 'relative' }}>
					<ChartSunburst data={props.data.iabSunburst} />
					{/*
					<a
						href="https://iabtechlab.com/standards/content-taxonomy/"
						target="_blank"
						rel="noreferrer"
					>
						<img className={style.iabLogo} src={logo} alt="" />
						Categorised using the IAB Tech Lab V3 Taxonomy
					</a>
					<a
						href="https://iabtechlab.com/standards/content-taxonomy/"
						target="_blank"
						rel="noreferrer"
					>
						<img className={style.iabLogo} src={logo} alt="" />
					</a>
					*/}

					<div className={style.iabLogo}>
						Categorised using the{' '}
						<a
							href="https://iabtechlab.com/standards/content-taxonomy/"
							target="_blank"
							rel="noreferrer"
						>
							IAB Tech Lab V3 Taxonomy
						</a>
					</div>
				</div>
			</Card>

			<h3 className={style.link}>
				Topic Drift Over Time
				<InfoSection
					title="Topic Drift Over Time"
					content="Analysis of the various topics and keywords discussed by the account can give a unique insight into content generated. This analysis examines the past 4 years topic groupings or the proportion available based on the date range selected for the report."
				/>
			</h3>
			<Card
				title="Topic Keywords (up to four years)"
				style={{ marginBottom: '20px' }}
			>
				<div className={style.topicDriftGrid}>
					{Object.entries(props.data.topicDriftWordClouds).map(
						([key, url], idx) => (
							<div className={style.imageContainer} key={key + idx}>
								<h4>{key}</h4>
								<img src={url as string} alt="" />
							</div>
						),
					)}
				</div>
			</Card>
		</div>
	);
};

export default ReportContent;
