import React, { useState } from 'react';

import { Button, Form, Input, Modal } from 'antd';
import { AxiosError } from 'axios';

import client from '@/lib/http/client';

import style from './style.module.css';

const validateEmail = (email: string) => {
	return String(email)
		.toLowerCase()
		.match(
			/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
		);
};

const AddUser = () => {
	const [form] = Form.useForm();
	const [loading, setLoading] = useState(false);
	const [open, setOpen] = useState(false);

	const showModal = () => {
		setOpen(true);
	};

	const handleOk = async () => {
		const email = form.getFieldValue('search').trim();

		try {
			if (!validateEmail(email)) {
				form.setFields([{ name: 'search', errors: ['Invalid email'] }]);
				return;
			}
			setLoading(true);
			await client.post(`/customers/invite`, { invite_emails: [email] });
			setOpen(false);
		} catch (err) {
			const error = err as AxiosError;
			const data: any = error.response?.data;
			if (data) {
				form.setFields([{ name: 'search', errors: [data.message] }]);
			}
		} finally {
			setLoading(false);
		}
	};

	const resetInput = () => {
		form.setFields([{ name: 'search', errors: undefined }]);
	};

	const handleCancel = () => {
		setOpen(false);
	};
	return (
		<>
			<Button type="primary" onClick={showModal}>
				Add User
			</Button>
			<Modal
				destroyOnClose
				open={open}
				title="Add User"
				onOk={handleOk}
				onCancel={handleCancel}
				footer={[
					<Button key="back" onClick={handleCancel}>
						Cancel
					</Button>,
					<Button
						key="submit"
						type="primary"
						loading={loading}
						onClick={handleOk}
					>
						Add
					</Button>,
				]}
			>
				<Form
					form={form}
					style={{ width: '100%' }}
					// onFinish={(v) => setSearch(v.search)}
				>
					<Form.Item htmlFor="email" name="search" style={{ marginBottom: 0 }}>
						<div className={style.form}>
							<Input
								type="email"
								placeholder="email@mail.com"
								defaultValue={''}
								onChange={resetInput}
							/>
						</div>
					</Form.Item>
				</Form>
			</Modal>
		</>
	);
};

export default AddUser;
