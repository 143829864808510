import { useContext, useEffect } from 'react';

import { Descriptions, Drawer, Space, Tag, message } from 'antd';

import ReportStatus from '@/components/ReportStatus';
import prepareDate from '@/lib/helpers/prepareDate';
import { PageContext } from '@/providers/PageContextProvider';

export default function ViewBatchProcessDetailsDrawer() {
	const { drawer, closeDrawer } = useContext(PageContext);

	// const { data, isError, error, isLoading } = useGetBatchProcessesById(
	// 	drawer?.data?.batch_id,
	// );

	const onClose = () => {
		// form.resetFields();
		closeDrawer();
	};

	// useEffect(() => {
	// 	if (isError) {
	// 		const getError = error as any;

	// 		message.error({
	// 			type: 'error',
	// 			content: `Something went wrong: ${
	// 				getError?.response?.data?.message ?? getError?.message
	// 			}`,
	// 		});
	// 	}
	// }, [isError]);

	// console.log('batch_id', drawer?.data?.batch_id);
	// console.log('useGetBatchProcessesById', data?.data?.data);

	return (
		<Drawer
			open={drawer.isOpen && drawer.type === 'view-batch-process-details'}
			width={'650px'}
			onClose={onClose}
			title="Batch process details"
			// loading={isLoading}
		>
			<Descriptions column={1} bordered size="small">
				{drawer?.data?.batch_id && (
					<Descriptions.Item label="BatchId">
						{drawer?.data?.batch_id}
					</Descriptions.Item>
				)}
				{drawer?.data?.started && (
					<Descriptions.Item label="Date/time requested">
						{prepareDate(drawer?.data?.started!)}
					</Descriptions.Item>
				)}
				{drawer?.data?.status && (
					<Descriptions.Item label="Status">
						<ReportStatus status={drawer?.data?.status} />
					</Descriptions.Item>
				)}
				{drawer?.data?.progress && (
					<Descriptions.Item label="Progress">
						{typeof drawer?.data?.progress === 'number'
							? `${Math.round(drawer?.data?.progress * 100) / 100}%`
							: '-'}
					</Descriptions.Item>
				)}
				{drawer?.data?.tags && (
					<Descriptions.Item label="Tags">
						<Space wrap size={[1, 4]}>
							{drawer?.data?.tags?.map((item: string, idx: number) => (
								<Tag key={item + idx}>{item}</Tag>
							))}
						</Space>
					</Descriptions.Item>
				)}
				{drawer?.data?.actions && (
					<Descriptions.Item label="Actions">
						<Space wrap size={[1, 4]}>
							{drawer?.data?.actions?.map((item: string, idx: number) => (
								<Tag key={item + idx} color="blue">
									{item}
								</Tag>
							))}
						</Space>
					</Descriptions.Item>
				)}

				{/* {data?.data?.data?.expected && (
					<Descriptions.Item label="Expected">
						{data?.data?.data?.expected}
					</Descriptions.Item>
				)}
				{data?.data?.data?.processed && (
					<Descriptions.Item label="Processed">
						{data?.data?.data?.processed}
					</Descriptions.Item>
				)} */}
			</Descriptions>
		</Drawer>
	);
}
