import {
	CommentOutlined,
	EyeOutlined,
	LikeOutlined,
	LinkOutlined,
	PieChartOutlined,
	TagOutlined,
} from '@ant-design/icons';
import { Flex, Typography } from 'antd';

import PieChart from '@/components/charts/pieCopilot';
import { formatNumber } from '@/lib/helpers/numFormat';

import style from '../styles.module.css';

type TProps = {
	data: any;
};

const BLUE_COLOR = '#156082',
	ORANGE_COLOR = '#F9931F';

export default function VideoInsights({ data }: TProps) {
	return (
		<Flex vertical gap={8} align="flex-start">
			{data?.video?.title && (
				<Typography.Text>
					<b>Title:</b> {data?.video?.title}
				</Typography.Text>
			)}

			{data?.video?.description &&
				data?.video?.description !== data?.video?.title && (
					<Typography.Text>
						<b>Description:</b> {data?.video?.description}
					</Typography.Text>
				)}

			{/* START */}
			<Flex
				gap={40}
				justify="flex-start"
				align="center"
				className={style.statsWrapper}
			>
				<Typography.Title
					level={4}
					title="Views"
					className={style.statItemTitle}
				>
					<EyeOutlined
						style={{
							color: data?.video?.stats?.views >= 0 ? BLUE_COLOR : 'gray',
							marginRight: '8px',
						}}
					/>
					<span
						style={{
							color: data?.video?.stats?.views >= 0 ? ORANGE_COLOR : 'gray',
						}}
					>
						{data?.video?.stats?.views >= 0
							? formatNumber(data?.video?.stats?.views)
							: 'n/a'}
					</span>
				</Typography.Title>
				<Typography.Title
					level={4}
					title="Comments"
					className={style.statItemTitle}
				>
					<CommentOutlined
						style={{
							color: data?.video?.stats?.comments >= 0 ? BLUE_COLOR : 'gray',
							marginRight: '8px',
						}}
					/>{' '}
					<span
						style={{
							color: data?.video?.stats?.comments >= 0 ? ORANGE_COLOR : 'gray',
						}}
					>
						{data?.video?.stats?.comments >= 0
							? formatNumber(data?.video?.stats?.comments)
							: 'n/a'}
					</span>
				</Typography.Title>
				<Typography.Title
					level={4}
					title="Likes"
					className={style.statItemTitle}
				>
					<LikeOutlined
						style={{
							color: data?.video?.stats?.likes >= 0 ? BLUE_COLOR : 'gray',
							marginRight: '8px',
						}}
					/>{' '}
					<span
						style={{
							color: data?.video?.stats?.likes >= 0 ? ORANGE_COLOR : 'gray',
						}}
					>
						{data?.video?.stats?.likes >= 0
							? formatNumber(data?.video?.stats?.likes)
							: 'n/a'}
					</span>
				</Typography.Title>
			</Flex>

			<Typography.Title
				level={4}
				title="Hashtags"
				className={style.statItemTitle}
			>
				<TagOutlined
					style={{
						color: data?.video?.hashtags?.length >= 0 ? BLUE_COLOR : 'gray',
						marginRight: '8px',
					}}
				/>
				<span
					style={{
						color: data?.video?.hashtags?.length >= 0 ? ORANGE_COLOR : 'gray',
					}}
				>
					{data?.video?.hashtags?.length >= 0
						? data?.video?.hashtags?.join(' ')
						: 'n/a'}
				</span>
			</Typography.Title>

			<Typography.Title
				level={4}
				title="Mentions"
				className={style.statItemTitle}
			>
				<span
					style={{
						color: data?.video?.mentions?.length >= 0 ? BLUE_COLOR : 'gray',
						display: 'inline-block',
						fontSize: '20px',
						minWidth: '20px',
						marginRight: '8px',
					}}
				>
					@
				</span>
				<span
					style={{
						color: data?.video?.mentions?.length >= 0 ? ORANGE_COLOR : 'gray',
					}}
				>
					{data?.video?.mentions?.length >= 0
						? data?.video?.mentions?.join(' ')
						: 'n/a'}
				</span>
			</Typography.Title>

			<Typography.Title level={4} className={style.statItemTitle} title="Links">
				<LinkOutlined
					style={{
						color: data?.video?.links?.[0] !== 'N/A' ? BLUE_COLOR : 'gray',
						marginRight: '8px',
					}}
				/>
				{data?.video?.links?.[0] === 'N/A' && (
					<span style={{ color: 'gray' }}>n/a</span>
				)}
			</Typography.Title>
			{data?.video?.links?.[0] !== 'N/A' && (
				<ul className={style.linkList}>
					{data?.video?.links?.map((link: string, idx: number) => (
						<li key={link + idx} className={style.linkListItem}>
							<a href={link}>{link}</a>
						</li>
					))}
				</ul>
			)}

			{data?.video?.sentiment &&
				Object.entries(data?.video?.sentiment).some(
					([_, value]) => value && (value as number) > 0,
				) && (
					<div style={{ height: '300px', width: '100%', marginTop: '0.5rem' }}>
						<Typography.Title
							level={5}
							className={style.statItemTitle}
							title="Sentiments"
						>
							Sentiments:
						</Typography.Title>
						<PieChart data={data?.video?.sentiment} />
					</div>
				)}
		</Flex>
	);
}
