import { useContext } from 'react';

import cn from 'classnames';
import { Link } from 'react-router-dom';

import { PageContext } from '@/providers/PageContextProvider';

import style from './style.module.css';

import { ClientExtendedSubscription } from '../subscriptionType';
import { productItems } from './data';

type TProps = {
	currentSubscriptions: ClientExtendedSubscription[];
};

export default function NewProductSubscription({
	currentSubscriptions,
}: TProps) {
	const { openDrawer } = useContext(PageContext);

	return (
		<div
			style={{
				width: '100%',
				maxWidth: '1360px',
				display: 'flex',
				flexGrow: 1,
			}}
		>
			<ul className={style.buttonLinksWrapper}>
				{productItems.map((item, idx) => {
					const isAlreadyInSubscriptions = currentSubscriptions?.some(
						(current) =>
							current?.subscription_name
								?.toLowerCase()
								?.includes(item?.subscriptionName!.toLowerCase()),
					);

					return (
						<li
							key={item.id + idx}
							style={{ borderColor: item.borderColor }}
							className={cn(
								style.listItem,
								isAlreadyInSubscriptions && style.faded,
							)}
							onClick={() =>
								// !isAlreadyInSubscriptions &&
								openDrawer('view-product-subscription', item)
							}
						>
							{/* <Link to={item.path} className={style.link}> */}
							<div className={style.link}>
								<div
									className={style.imageWrapper}
									style={
										item.group === 'report'
											? {
													borderRight: `1px solid ${item.borderColor}`,
													// maxWidth: '150px',
											  }
											: { borderRight: `1px solid ${item.borderColor}` }
									}
								>
									<img
										src={item.image}
										className={style.image}
										style={
											item.group === 'video'
												? {
														transform:
															'translate(7px,7px) scale(1.4) rotateZ(-45deg)',
														objectFit: 'contain',
												  }
												: item.group === 'co-pilot'
												? {
														transform: 'scale(0.75)',
												  }
												: {}
										}
									/>
								</div>

								<div className={style.textWrapper}>
									<p
										className={style.text}
										style={
											item.group === 'datalake' ? { maxWidth: '110px' } : {}
										}
									>
										{item.title}
									</p>

									{item.subtitle && (
										<span className={style.subtitleText}>{item.subtitle}</span>
									)}
								</div>
							</div>
							{/* </Link> */}
							{isAlreadyInSubscriptions && (
								<div className={style.alreadySubscribedBadge}>
									<span
										style={{
											fontSize: '12px',
											marginRight: '4px',
										}}
									>
										✓
									</span>{' '}
									Already subscribed
								</div>
							)}
						</li>
					);
				})}
			</ul>
		</div>
	);
}
