import { useEffect, useState } from 'react';

import { Alert, Card, Typography } from 'antd';

import ChartBar from '@/components/charts/bar';
import ChartBoxPlot from '@/components/charts/boxplot';

import style from '../style.module.css';

import {
	IProps,
	sortVerticalGraf,
	sortVerticalGrafDescription,
} from '../helpers';
import { InfoSection } from '../informationPopop';

const ReportEmotionalVoice = (props: IProps) => {
	const [values, setValues] = useState<any[]>([]);
	const [descriptions, setDescriptions] = useState<{ [key: string]: string }[]>(
		[],
	);

	useEffect(() => {
		if (props.data.personalityTypeChart.personalityBarChart.length) {
			const sortedValues = sortVerticalGraf(
				props.data.personalityTypeChart.personalityBarChart,
				'Others',
				'type',
			);

			setValues(sortedValues);

			const descriptionsArray = sortVerticalGrafDescription(
				sortedValues,
				'Others',
				props,
			);

			setDescriptions(descriptionsArray);
		}
	}, []);

	return (
		<div>
			<Card
				title=<div>
					Personality Type
					<InfoSection
						title="Personality Type"
						content="Using the Myers-Briggs personality type indicator as a baseline, we use different spoken word signals to indicate the general leaning of a particular type. This is not a substitute for a clinical analysis and should not be used as such."
					/>
				</div>
				size="small"
				style={{ marginBottom: '50px' }}
			>
				<div style={{ height: '200px', width: '50%' }}>
					<ChartBar
						data={values}
						valueFormat={(value) => `${value} %`}
						label={(d) => `${d.data.value} %`}
					/>
				</div>

				<div className={style.paragraphs}>
					{descriptions.map((entry, i) => (
						<Typography.Text key={i}>
							<span className={style.badge}>{Object.keys(entry)[0]}</span>{' '}
							{Object.values(entry)[0] as string}
						</Typography.Text>
					))}
				</div>
			</Card>
			<Card
				title=<div>
					Focus & Tone
					<InfoSection
						title="Focus & Tone"
						content="We analyse the spoken word to highlight the tone, leaning and direction of focus of the general overall conversation. This analysis is related to words and speech, not political or other societal leanings."
					/>
				</div>
				style={{ marginBottom: '50px' }}
			>
				<div className={style.cards}>
					<div className={style.card}>
						<div className={style.cardTitle}>Tone of Spoken Word</div>
						<div className={style.cardContent}>
							{props.data.focusAndToneSummary.toneOfSpokenWord.map(
								(x: any, idx: number) => (
									<div key={x?.type + idx}>
										<div className={style.contentValue}>{x.value}%</div>
										<div className={style.contentText}>{x.type}</div>
									</div>
								),
							)}
						</div>
					</div>
					<div className={style.card}>
						<div className={style.cardTitle}>Leaning</div>
						<div className={style.cardContent}>
							{props.data.focusAndToneSummary.leaning.map(
								(x: any, idx: number) => (
									<div key={x?.type + idx}>
										<div className={style.contentValue}>{x.value}%</div>
										<div className={style.contentText}>{x.type}</div>
									</div>
								),
							)}
						</div>
					</div>
					<div className={style.card}>
						<div className={style.cardTitle}>Direction of Focus</div>
						<div className={style.cardContent}>
							{props.data.focusAndToneSummary.directionOfFocus.map(
								(x: any, idx: number) => (
									<div key={x?.type + idx}>
										<div className={style.contentValue}>{x.value}%</div>
										<div className={style.contentText}>{x.type}</div>
									</div>
								),
							)}
						</div>
					</div>
				</div>
			</Card>
			<h3 className={style.link}>Sound Analysis </h3>
			{Array.isArray(props.data.soundAnalysisChart) &&
			props.data.soundAnalysisChart.length > 0 ? (
				<Card
					title=<div>
						Vocal Recording Quality
						<InfoSection
							title="Vocal Recording Quality"
							content={
								<p>
									We analyse the vocal recording quality to determine the
									quality of the recording output. In general, we are looking
									for consistency throughout all the metrics.
								</p>
							}
						/>
					</div>
					size="small"
					style={{ marginBottom: '50px' }}
				>
					<div style={{ height: '600px' }}>
						<ChartBoxPlot data={props.data.soundAnalysisChart} />
					</div>
				</Card>
			) : (
				<Alert
					message="No data for sound analysis in this report"
					type="info"
					showIcon
				></Alert>
			)}
		</div>
	);
};

export default ReportEmotionalVoice;
