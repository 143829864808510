import { useContext, useEffect } from 'react';

import { Link } from 'react-router-dom';

import { PageContext } from '@/providers/PageContextProvider';

import style from './style.module.css';

export default function VideoDataLake() {
	const { setPageTitle, setIsCreditsCounterVisible } = useContext(PageContext);

	useEffect(() => {
		setPageTitle('Video data lake');
		setIsCreditsCounterVisible(false);
		return () => {
			setPageTitle('');
			setIsCreditsCounterVisible(true);
		};
	}, []);

	return (
		<div>
			<h2>Comming soon!</h2>
			<p>All your actionable video intelligence in one place!</p>
		</div>
	);
}
