import React, { Suspense } from 'react';

import { useIdleTimer } from 'react-idle-timer';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import Loader from '@/layouts/loaders/loading';

import './App.css';

import AddNewBatchProcessDrawer from './components/drawers/AddNewBatchProcessDrawer';
import SubscriptionConfigurationDrawer from './components/drawers/SubscriptionConfigurationDrawer';
import ViewBatchProcessDetailsDrawer from './components/drawers/ViewBatchProcessDetailsDrawer';
import ViewCopilotVideoDetailsDrawer from './components/drawers/ViewCopilotVideoDetailsDrawer';
import ViewProductSubscriptionDrawer from './components/drawers/ViewProductSubscriptionDrawer';
import usePreviousPageTracker from './hooks/usePreviousPageTracker';
import { totalAvailableReports } from './layouts/subscription/helper';
import { ClientExtendedSubscription } from './layouts/subscription/subscriptionType';
import { useSubscription } from './layouts/subscription/useSubscription';
import client, { logout } from './lib/http/client';
import { PageContextProvider } from './providers/PageContextProvider';

async function getUser() {
	try {
		const user: any = await client.get<any>('/auth/profile');

		return { user: user.data };
	} catch (err: any) {
		if (err.response.data.message === 'login required') {
			throw new Error('403');
		}
		throw err;
	}
}

const wait = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

interface IUserProvider {
	user: any;
	setUser: React.Dispatch<any>;
	isAdmin: boolean;
	profileDrawlerVisible: boolean;
	setProfileDrawlerVisible: React.Dispatch<any>;
}
export const UserContext = React.createContext<IUserProvider>(
	{} as IUserProvider,
);

interface ISubscriptionProvider {
	subscribe: any;
	repotsCount: number;
	setRepotsCount: React.Dispatch<any>;
	setSubscription: React.Dispatch<any>;
	subscriptions: ClientExtendedSubscription[];
	subscriptionLoading: boolean;
	setSubscriptionLoading: React.Dispatch<any>;
	refetch: () => void;
}

export const SubscriptionContext = React.createContext<ISubscriptionProvider>(
	{} as ISubscriptionProvider,
);

function App(props: any) {
	const [loading, setLoading] = React.useState(true);
	const [user, setUser] = React.useState<any>(null);
	const [repotsCount, setRepotsCount] = React.useState<any>(null);
	const [subscribe, setSubscription] = React.useState<any>(null);
	const [subscriptionLoading, setSubscriptionLoading] = React.useState(true);
	const [profileDrawlerVisible, setProfileDrawlerVisible] =
		React.useState(false);
	const navigate = useNavigate();
	const location = useLocation();

	const { isSubscriptionsValid, subscription, isLoading, isFetching, refetch } =
		useSubscription();

	usePreviousPageTracker();

	React.useEffect(() => {
		if (subscription) {
			// console.log('subscription', subscription);

			setSubscription(subscription);
			setRepotsCount(totalAvailableReports(subscription));
		}
		setSubscriptionLoading(isLoading);
	}, [isLoading, isFetching]);

	React.useEffect(() => {
		getUser()
			.then(({ user }) => {
				setUser(user);
				setLoading(false);
			})
			.catch((err) => {
				if (err.message === '403' || err.response.status === 401) {
					navigate('/auth/login');
				}
			});
	}, [navigate]);

	React.useEffect(() => {
		if (!user) {
			if (location.pathname === '/app' || location.pathname === '/app/') {
				navigate('/app/dashboard/creators');
			}
			return;
		}
		if (!user?.cid) {
			navigate('/app/setup');
			return;
		}
	}, [user, navigate, location.pathname]);

	React.useEffect(() => {
		if (!isLoading) {
			if (!subscription) {
				if (user) {
					const isAdmin = user?.check?.[2] ?? false;
					// if (isAdmin && location.pathname !== '/app/subscribe/completion') {
					// 	navigate('/app/dashboard/subscription');
					// }
					return;
				}
			}
		}
	}, [navigate, isLoading]);

	const onIdle = async () => {
		await logout();
	};

	const onAction = async () => {
		try {
			client.get('/auth/idle');
		} catch (error) {
			await logout();
		}
	};

	const { getRemainingTime } = useIdleTimer({
		onIdle,
		onAction,
		crossTab: true,
		timeout: 10_00 * 60 * 45,
		throttle: 1000 * 60 * 6,
	});

	return (
		<Suspense fallback={<div>Loading...</div>}>
			<Loader loading={loading}>
				<div
					className="App"
					style={{
						overflowY: 'hidden',
					}}
				>
					<UserContext.Provider
						value={{
							user,
							setUser,
							isAdmin: user?.check?.[2] ?? false,
							profileDrawlerVisible,
							setProfileDrawlerVisible,
						}}
					>
						<SubscriptionContext.Provider
							value={{
								subscribe,
								subscriptions: subscription as ClientExtendedSubscription[],
								setSubscription,
								repotsCount,
								setRepotsCount,
								subscriptionLoading,
								setSubscriptionLoading,
								refetch,
							}}
						>
							<PageContextProvider>
								<Outlet context={user} />
								<AddNewBatchProcessDrawer />
								<ViewBatchProcessDetailsDrawer />
								<SubscriptionConfigurationDrawer />
								<ViewProductSubscriptionDrawer />
								<ViewCopilotVideoDetailsDrawer />
							</PageContextProvider>
						</SubscriptionContext.Provider>
					</UserContext.Provider>
				</div>
			</Loader>
		</Suspense>
	);
}

export default App;
