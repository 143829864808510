import { useEffect, useState } from 'react';

import { CommentOutlined, EyeOutlined, LikeOutlined } from '@ant-design/icons';
import {
	Button,
	Drawer,
	Flex,
	Modal,
	Table,
	Typography,
	notification,
} from 'antd';
import { AxiosRequestConfig } from 'axios';

import client from '@/lib/http/client';

import style from '../style.module.css';
import ls from './style.module.css';

import { formatNumber } from '../helpers/utils';
import MusicDetectedIconTooltip from './components/MusicDetectedIconTooltip';
import { ReactComponent as JustificationIcon } from './justification.svg';
import { ReactComponent as TranscriptIcon } from './transcript.svg';
import { ReactComponent as Trusted } from './trusted.svg';

interface IProps {
	data: any;
}

const categoryNames: { [key: string]: string } = {
	drugstobacco: 'Drugs & Tobacco',
	adultcontent: 'Adult Content',
	deathorconflict: 'Death or Conflict',
	armsammunition: 'Arms & Ammunition',
	crimeharmfulacts: 'Crime & Harmful Acts',
	onlinepiracy: 'Online Piracy',
	hatespeech: 'Hate Speech',
	obscenityprofanity: 'Obscenity & Profanity',
	hatefulcontent: 'Hateful Content',
	terrorism: 'Terrorism',
	sensitivesocialissues: 'Sensitive Social Issues',
	misinformation: 'Misinformation',
};

const fetchRisks = async (
	creatorIds: Array<string>,
	risk: string,
	startDate: Date,
	endDate: Date,
) => {
	return (
		await client.get(`/content/risks`, {
			params: {
				risk: risk,
				creatorIds: creatorIds.join(','),
				startDate: startDate,
				endDate: endDate,
			},
		})
	).data;
};
const riskCounts = async (
	creatorIds: Array<string>,
	startDate: Date,
	endDate: Date,
) => {
	return (
		await client.get(`/content/risk-counts`, {
			params: {
				creatorIds: creatorIds.join(','),
				startDate: startDate,
				endDate: endDate,
			},
		})
	).data;
};
const removeItems = (array: any[], indices: number[]) => {
	return array.filter((_, index) => !indices.includes(index));
};

const removeExtracts = async (extracts: any) => {
	const filteredExtracts = removeItems(
		extracts[0].video.risk.extract,
		extracts.map((e: any) => e.key),
	);
	let risk = extracts[0].video;
	delete risk.risk.textAnalytics;
	delete risk.risk.url;
	delete risk.risk.platform;
	const trueKey = Object.keys(risk.risk).find((key) => risk.risk[key] === true);
	const id = extracts[0].video.metaData.id;
	if (filteredExtracts.length === 0) {
		const data: any[] | AxiosRequestConfig<any[]> | undefined = [];
		return await client.put(`/content?id=${id}&risk=${trueKey}`, data);
	} else {
		const data = {
			...risk.risk,
			extract: filteredExtracts,
		};
		return await client.put(`/content?id=${id}&risk=${trueKey}`, data);
	}
};
const fetchCreatorIDs = async (reportId: string) => {
	return (await client.get(`/reports/creatorIDs?reportId=${reportId}`)).data;
};

const defaultMessage = 'Please select a Risk Category to view risk details.';
const BrandSafety = (props: IProps) => {
	const [isLoading, setIsLoading] = useState(false);
	// For setting and showing the transcript respectively
	const [transcript, setTranscript] = useState(<></>);
	const [showTranscript, setShowTranscript] = useState(false);

	// For storing the creator ids
	const [creatorIds, setCreatorIds] = useState([]);

	// Report ID used to find creator ids
	const reportId = props.data.report.reportId;

	// Start and end dates for filtering
	const startDate = new Date(props.data.report.dateRange.from);
	const endDate = new Date(props.data.report.dateRange.to);

	// Setting the risk table content and column header
	const [tableContent, setTableContent] = useState<any>([]);
	const [columnHeader, setColumnHeader] = useState<any>(<></>);

	const [isModalVisible, setIsModalVisible] = useState(false);
	const [selectedExtracts, setSelectedExtracts] = useState<any>([]);
	const [modalDataSource, setModalDataSource] = useState<any>([]);
	const [triggerRerender, setTriggerRerender] = useState(false);
	const [tableRiskCounts, setTableRiskCounts] = useState<any>([]);

	const handleExtractDeletion = async (totalRows: Number) => {
		setIsModalVisible(false);
		if (selectedExtracts.length === 0) {
			notification.info({
				message: 'No extracts selected for deletion',
			});
		} else if (
			selectedExtracts.length > 0 &&
			selectedExtracts.length < totalRows
		) {
			await removeExtracts(selectedExtracts);
			notification.info({
				message: `${selectedExtracts.length} extracts deleted`,
			});
		} else {
			await removeExtracts(selectedExtracts);
			notification.info({
				message: 'All extracts for this video have been deleted',
			});
		}
		setTriggerRerender(!triggerRerender);
	};

	useEffect(() => {
		const getCreatorIds = async () => {
			setIsLoading(true);

			const ids = await fetchCreatorIDs(reportId);
			setCreatorIds(ids);
			const counts = await riskCounts(ids, startDate, endDate);
			for (const category in categoryNames) {
				if (!counts.some((obj: any) => obj.category === category)) {
					counts.push({
						category: category,
						highRisk: 0,
						mediumRisk: 0,
						lowRisk: 0,
					});
					counts.sort((a: any, b: any) => {
						return (
							Object.keys(categoryNames).indexOf(a.category) -
							Object.keys(categoryNames).indexOf(b.category)
						);
					});
				}
			}
			setTableRiskCounts(counts);
			setTableContent(defaultMessage);
			setIsLoading(false);
		};
		getCreatorIds();
	}, [reportId, triggerRerender]);

	// Handles the click event on a number in the GARM table.
	const handleGarmCellClick = async (risk: string) => {
		setIsLoading(true);
		const risks = await fetchRisks(creatorIds, risk, startDate, endDate);
		const counts = await riskCounts(creatorIds, startDate, endDate);
		setTableRiskCounts(counts);
		for (const category in categoryNames) {
			if (!counts.some((obj: any) => obj.category === category)) {
				counts.push({
					category: category,
					highRisk: 0,
					mediumRisk: 0,
					lowRisk: 0,
				});
				counts.sort((a: any, b: any) => {
					return (
						Object.keys(categoryNames).indexOf(a.category) -
						Object.keys(categoryNames).indexOf(b.category)
					);
				});
			}
		}
		setTableContent(risks);
		setColumnHeader(
			<div
				style={{
					display: 'flex',
					justifyContent: 'space-between', // This will space out the items
					alignItems: 'center', // This will vertically align the items
				}}
			>
				<div
					style={{
						textAlign: 'center', // This will center the text
						flex: 1, // This will allow the item to take up the remaining space
					}}
				>
					Showing {risks.length} {categoryNames[risk]} Risk
					{risks.length === 1 ? '' : 's'}
				</div>
				<Button
					type="link"
					style={{ textDecoration: 'underline' }}
					onClick={() => {
						setTableContent(defaultMessage);
						setColumnHeader('');
					}}
				>
					Back
				</Button>
			</div>,
		);
		setIsLoading(false);
	};

	// Set transcript with highlighted extracts and show transcript
	const handleTranscript = (value: {
		transcript: { text: string };
		extract: string[];
	}) => {
		let markedTranscript = value.transcript.text;
		const elements: JSX.Element[] = [];
		let key = 0;

		// sort extracts by order of index in transcript
		value.extract.sort((a: string, b: string) => {
			return (
				markedTranscript?.toLowerCase()?.indexOf(a?.toLowerCase()) -
				markedTranscript?.toLowerCase()?.indexOf(b?.toLowerCase())
			);
		});

		value.extract.forEach((extract: string) => {
			if (value.transcript.text.includes(extract)) {
			}
			let start = markedTranscript?.toLowerCase()?.indexOf(extract?.toLowerCase());
			while (start !== -1) {
				const end = start + extract.length;
				const before = markedTranscript.slice(0, start);
				const marked = markedTranscript.slice(start, end);
				const after = markedTranscript.slice(end);

				if (before) {
					elements.push(<span key={key++}>{before}</span>);
				}
				elements.push(
					<Typography.Text mark key={key++}>
						{marked}
					</Typography.Text>,
				);
				markedTranscript = after;

				start = markedTranscript.indexOf(extract);
			}
		});

		if (markedTranscript) {
			elements.push(<span key={key++}>{markedTranscript}</span>);
		}

		setTranscript(<div style={{ textAlign: 'left' }}>{elements}</div>);
		setShowTranscript(!showTranscript);
	};

	// Show justification for risk
	const showJustification = (justification: string) => {
		Modal.info({
			icon: null,
			title: (
				<div
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
					}}
				>
					<Typography.Title level={5}>Justification </Typography.Title>{' '}
					<JustificationIcon
						style={{
							width: '16px',
							height: '16px',
						}}
					/>
				</div>
			),
			content: (
				<div>
					<Typography.Text>{justification}</Typography.Text>
				</div>
			),
			maskClosable: true,
		});
	};

	// Format time in seconds to HH:MM:SS timestamp
	const formatTime = (time: number) => {
		const hours = Math.floor(time / 3600);
		const minutes = Math.floor((time % 3600) / 60);
		const seconds = Math.floor(time % 60);
		return `${hours.toString().padStart(2, '0')}:${minutes
			.toString()
			.padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
	};
	const CustomHeader = ({
		children,
		...props
	}: {
		children: React.ReactNode;
	}) => (
		<th {...props} style={{ backgroundColor: 'white', border: 'none' }}>
			{children}
		</th>
	);
	const categoryNamesSet = new Set(Object.keys(categoryNames));
	const sortedData = tableRiskCounts.sort((a: any, b: any) => {
		return (
			Array.from(categoryNamesSet).indexOf(a.category) -
			Array.from(categoryNamesSet).indexOf(b.category)
		);
	});

	return (
		<div className={ls.root}>
			<div className={ls.tableContainer}>
				<Table
					bordered
					dataSource={sortedData}
					pagination={false}
					size="small"
					loading={isLoading}
					rowKey="category"
				>
					<Table.Column
						width={'20%'}
						title="Content Category"
						key="Content category"
						dataIndex={'category'}
						render={(value: string) => categoryNames[value]}
					/>
					<Table.Column
						width={'20%'}
						title="Instances"
						key="Instances"
						align="center"
						render={(value) =>
							value.highRisk + value.mediumRisk + value.lowRisk === 0 ? (
								<div style={{ padding: '4px' }}>-</div>
							) : (
								<Button
									onClick={() => {
										handleGarmCellClick(value.category);
									}} // Start loading
									type="link"
									style={{ textDecoration: 'underline' }}
								>
									{value.highRisk + value.mediumRisk + value.lowRisk}
								</Button>
							)
						}
					/>
					<Table.Column
						title={columnHeader}
						key="Risks"
						width={'60%'}
						align="center"
						render={(value, row, index) => {
							const obj = {
								children:
									tableRiskCounts.reduce(
										(
											total: any,
											instance: {
												lowRisk: number;
												mediumRisk: number;
												highRisk: number;
											},
										) =>
											total +
											instance.lowRisk +
											instance.mediumRisk +
											instance.highRisk,
										0,
									) === 0 ? (
										<div
											key={index}
											style={{
												display: 'flex',
												justifyContent: 'center',
												alignItems: 'center',
												height: '100%',
											}}
										>
											<Trusted
												style={{
													width: '75%',
													height: '75%',
												}}
											/>
										</div>
									) : tableContent === defaultMessage ? (
										<div
											key={index}
											style={{
												display: 'flex',
												justifyContent: 'center',
												alignItems: 'center',
												height: '100%',
											}}
										>
											{defaultMessage}
										</div>
									) : (
										<div
											key={index}
											style={{
												display: 'flex',
												flexDirection: 'column',
												height: '563px',
											}}
										>
											<Table
												key="Innertable"
												components={{
													header: {
														cell: CustomHeader,
													},
												}}
												dataSource={tableContent}
												pagination={{
													pageSize: 3,
													position: ['bottomRight'],
												}}
												scroll={{ y: 495 }}
												style={{
													tableLayout: 'fixed',
													width: '35vw',
												}}
											>
												<Table.Column
													title="Video"
													dataIndex={'metaData'}
													width={'20%'}
													fixed={true}
													render={(value) => (
														<Flex
															justify="flex-start"
															align="flex-start"
															vertical={true}
														>
															<div
																style={{
																	height: '100%',
																	display: 'flex',
																	alignItems: 'flex-start',
																	justifyContent: 'flex-start',
																	flexDirection: 'column',
																}}
															>
																<div>
																	{new Date(value.date).toLocaleDateString(
																		'en-US',
																		{
																			day: 'numeric',
																			month: 'short',
																			year: 'numeric',
																		},
																	)}
																</div>
															</div>
															<br />
															<div style={{ display: 'flex', gap: '5px' }}>
																<a
																	href={value.url}
																	target="_blank"
																	rel="noreferrer"
																>
																	<div>{value.platform}</div>
																</a>
															</div>
															<div style={{ display: 'flex', gap: '5px' }}>
																<LikeOutlined />
																<div>{formatNumber(value.stats.likes)}</div>
																{/*<div>{value.stats.likes}</div>*/}
															</div>
															<div style={{ display: 'flex', gap: '5px' }}>
																<CommentOutlined />
																<div>{formatNumber(value.stats.comments)}</div>
																{/*<div>{value.stats.comments}</div>*/}
															</div>
															<div style={{ display: 'flex', gap: '5px' }}>
																<EyeOutlined />
																<div>{formatNumber(value.stats.views)}</div>
																{/*<div>{value.stats.views}</div>*/}
															</div>
														</Flex>
													)}
												></Table.Column>

												<Table.Column
													title="Extracts"
													// dataIndex={'risk'}
													width={'80%'}
													fixed={true}
													// @ts-ignore
													onCell={(record, rowIndex) => {
														return {
															style: {
																overflow: 'hidden',
																textOverflow: 'ellipsis',
																maxWidth: '100%',
																whiteSpace: 'no',
															},
														};
													}}
													render={(value) => {
														let fullTranscript = '';
														let indexToTimeMap: {
															[key: number]: { start: any; end: any };
														} = {};
														value.risk.transcript.segments.forEach(
															(
																segment: { text: string; start: any; end: any },
																i: any,
															) => {
																segment.text = segment.text.replaceAll('.', '');
																const startIdx = fullTranscript.length;
																const endIdx = startIdx + segment.text.length;
																fullTranscript += segment.text;

																for (let idx = startIdx; idx < endIdx; idx++) {
																	indexToTimeMap[idx] = {
																		start: segment.start,
																		end: segment.end,
																	};
																}
															},
														);

														return (
															<div
																style={{
																	display: 'flex',
																	flexDirection: 'column',
																	height: '100%',
																	justifyContent: 'space-between',
																}}
																onMouseDown={(e) => {
																	if (e.shiftKey && e.altKey) {
																		e.preventDefault();
																		setIsModalVisible(true);
																		setModalDataSource(
																			value.risk.extract.map(
																				(extract: any, index: any) => ({
																					key: index,
																					extract,
																					video: value,
																				}),
																			),
																		);
																	}
																}}
															>
																{value.risk.extract.map((extract: string) => {
																	const startIdx = fullTranscript
																		.trim()
																		.toLowerCase()
																		.replaceAll('.', '')
																		.indexOf(
																			extract
																				.trim()
																				.toLowerCase()
																				.replaceAll('.', ''),
																		);

																	if (
																		startIdx !== -1 &&
																		indexToTimeMap[startIdx]
																	) {
																		const startTime = Math.round(
																			indexToTimeMap[startIdx].start,
																		);
																		console.log(value);
																		return (
																			<div
																				style={{
																					display: 'flex',
																					marginBottom: '15px',
																				}}
																			>
																				<div
																					style={{
																						marginRight: 10,
																					}}
																				>
																					{extract}
																				</div>
																				<div
																					style={{
																						textAlign: 'right',
																						marginRight: '10px',
																						fontSize: '0.9em',
																						color: 'grey',
																					}}
																				>
																					{value.metaData.platform ===
																					'Youtube' ? (
																						<a
																							href={
																								value.metaData.url +
																								`&t=${startTime}`
																							}
																							target="_blank"
																							rel="noopener noreferrer"
																						>
																							({formatTime(startTime)})
																						</a>
																					) : (
																						`(${formatTime(startTime)})`
																					)}
																				</div>

																				<br />
																			</div>
																		);
																	}
																})}

																<div
																	style={{
																		display: 'flex',
																		alignItems: 'center',
																	}}
																>
																	<Button
																		type="link"
																		style={style}
																		onClick={() =>
																			showJustification(
																				value.risk.justification,
																			)
																		}
																	>
																		<JustificationIcon
																			style={{ width: '16px', height: '16px' }}
																		/>
																	</Button>
																	<Button
																		type="link"
																		style={style}
																		onClick={() => handleTranscript(value.risk)}
																	>
																		<TranscriptIcon
																			style={{ width: '16px', height: '16px' }}
																		/>
																	</Button>
																	{value?.metaData?.soundType === 'music' && (
																		<MusicDetectedIconTooltip />
																	)}
																</div>
															</div>
														);
													}}
												></Table.Column>
											</Table>
										</div>
									),
								props: {
									rowSpan: 0,
								},
							};
							if (index === 0) {
								obj.props.rowSpan = 12;
							} else if (index < 12) {
								obj.props.rowSpan = 0;
							}
							if (
								tableRiskCounts.reduce(
									(
										total: any,
										instance: {
											lowRisk: number;
											mediumRisk: number;
											highRisk: number;
										},
									) =>
										total +
										instance.lowRisk +
										instance.mediumRisk +
										instance.highRisk,
									0,
								) === 0
							) {
								setColumnHeader('No Risks Found');
							}
							return obj;
						}}
					/>
				</Table>
				<Modal
					title="Delete Extracts"
					open={isModalVisible}
					onOk={async () => {
						await handleExtractDeletion(modalDataSource.length);
					}}
					onCancel={() => setIsModalVisible(false)}
					okText="Delete"
					okButtonProps={{
						style: {
							backgroundColor: 'red',
							borderColor: 'red',
							color: 'white',
						},
					}}
				>
					<Table
						rowSelection={{
							type: 'checkbox',
							onChange: (_selectedRowKeys, selectedRows) => {
								setSelectedExtracts(selectedRows);
							},
						}}
						columns={[
							{
								title: 'Extract',
								dataIndex: 'extract',
								key: 'extract',
							},
						]}
						dataSource={modalDataSource}
					/>
				</Modal>
				<Drawer
					getContainer={false}
					open={showTranscript}
					onClose={() => setShowTranscript(false)}
					width={600}
					placement="right"
					title="Transcript"
				>
					{transcript}
				</Drawer>
			</div>
		</div>
	);
};

export default BrandSafety;
