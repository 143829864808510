import { Tooltip } from 'antd';

import { ReactComponent as MusicNotes } from '../musicNotes.svg';

function MusicDetectedIconTooltip() {
	return (
		<Tooltip title="Music Detected">
			<MusicNotes
				style={{
					height: '16px',
					width: '16px',
					color: '#000',
					cursor: 'pointer',
					padding: '4px 15px 8px',
				}}
			/>
		</Tooltip>
	);
}

export default MusicDetectedIconTooltip;
