import React, { useContext, useEffect, useState } from 'react';

import { Statistic, StatisticProps } from 'antd';
import CountUp from 'react-countup';

import { SubscriptionContext } from '@/App';
import { PageContext } from '@/providers/PageContextProvider';

export const CreditsCounter = ({ count }: { count: number }) => {
	const [prevTotalReports, setPrevTotalReports] = useState(0);
	const { subscriptions } = useContext(SubscriptionContext);
	const { isCreditsCounterVisible, isCopilotCreditsCounterVisible } =
		useContext(PageContext);

	useEffect(() => {
		setPrevTotalReports(count);
	}, [count]);

	if (isCopilotCreditsCounterVisible) {
		return (
			<div>
				<div>
					Available Copilot Credits:{' '}
					{subscriptions?.[0]?.item_count_per_month -
						subscriptions?.[0]?.item_count_used}
					{/* <CountUp
						start={prevTotalReports}
						end={count > 0 ? count : (0 as number)}
						separator=","
					/> */}
				</div>
			</div>
		);
	} else if (isCreditsCounterVisible) {
		return (
			<div>
				<div>
					Available Report Credits:{' '}
					<CountUp
						start={prevTotalReports}
						end={count > 0 ? count : (0 as number)}
						separator=","
					/>
				</div>
			</div>
		);
	}

	return null;
};
