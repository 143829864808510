import client from '@/lib/http/client';

export const fetchCreatorReport = async (
	id: string,
	filter: string[] = [],
): Promise<Report> => {
	return (await client.get(`/reports/${id}?f=${filter.join(',')}`)).data;
};

export interface Report {
	_id: string;
	creatorId?: string;
	summary?: ReportSummary;
	topViewed?: ReportVideos;
	headlines?: ReportVideos;
}
export interface ReportSummary {
	creatorName: string;
	creatorId: string;
	profileImageLink: string;
	platformLinks: string[];
	activeSince: string;
	ageGroup: string;
	categories: string[];
	trustScore: SummaryTrustScore;
	contactInfo: SummaryContactInfo;
	dateRange: string;
	intro: string;
	summaryAnalysis: string;
	language: string;
	lastVideoDate: string;
	location: string;
	overallActivity: SummaryOverallActivity;
	platformMetrics: SummaryPlatformMetrics;
	reportDate: string;
	videoHours: number;
	videosAnalyzed: number;
}

export interface SummaryTrustScore {
	consistency: number;
	empathy: number;
	authenticity: number;
	profanity: number;
	toxicity: number;
	redFlagContent: number;
}

export interface SummaryContactInfo {
	city: string;
	country: string;
	emails: string[];
	phones: any[];
	postcode: string;
	state: string;
}

export interface SummaryOverallActivity {
	Instagram: number;
	TikTok: number;
	Youtube: string;
}

export interface SummaryPlatformMetrics {
	totalFollowers: SummaryMetrics;
	engagementRate: SummaryMetrics;
	postFrequency: SummaryMetrics;
	totalVideos: SummaryMetrics;
}

export interface SummaryMetrics {
	metric: number;
	label: string;
}

export interface PlatformData {
	title: string;
	date: string;
	thumbnail: string;
	url: string;
	platform: string;
	views: number;
	likes: number;
	comments: number;
}

export interface ReportVideos {
	Youtube: PlatformData[];
	Instagram: PlatformData[];
	TikTok: PlatformData[];
}
