import { BarSvgProps, ResponsiveBar } from '@nivo/bar';

// interface IProps extends Omit<BarSvgProps<any>, "height" | "width"> {
//   data: any[];
// }

export const CopilotBarChart = (props: any) => {
	return (
		<ResponsiveBar
			data={props.data}
			keys={['metadata', 'voice']}
			indexBy="brand"
			margin={{ top: 50, right: 60, bottom: 75, left: 60 }}
			padding={0.3}
			valueScale={{ type: 'linear' }}
			indexScale={{ type: 'band', round: true }}
			colors={{ scheme: 'set1' }}
			defs={[
				{
					id: 'dots',
					type: 'patternDots',
					background: 'inherit',
					color: '#38bcb2',
					size: 4,
					padding: 1,
					stagger: true,
				},
				{
					id: 'lines',
					type: 'patternLines',
					background: 'inherit',
					color: '#eed312',
					rotation: -45,
					lineWidth: 6,
					spacing: 10,
				},
			]}
			borderColor={{
				from: 'color',
				modifiers: [['darker', 1.6]],
			}}
			axisTop={null}
			axisRight={null}
			axisBottom={{
				tickSize: 5,
				tickPadding: 10,
				tickRotation: -15,
				legend: '',
				legendPosition: 'middle',
				legendOffset: -32, // Adjust this value
			}}
			axisLeft={{
				tickSize: 5,
				tickPadding: 5,
				tickRotation: 0,
				legend: '',
				legendPosition: 'middle',
				legendOffset: -40,
				format: (value: any) => (Number.isInteger(value) ? value : ''),
			}}
			labelSkipWidth={12}
			labelSkipHeight={12}
			labelTextColor={{
				from: 'color',
				modifiers: [['darker', 1.6]],
			}}
			legends={[
				// @ts-ignore
				{
					anchor: 'top-right',
					direction: 'column',
					justify: false,
					translateX: 50,
					translateY: -50,
					itemsSpacing: 2,
					itemWidth: 100,
					itemHeight: 20,
					itemDirection: 'left-to-right',
					itemOpacity: 0.85,
					symbolSize: 20,
					effects: [
						{
							on: 'hover',
							style: {
								itemOpacity: 1,
							},
						},
					],
				},
			]}
			role="application"
			ariaLabel="Nivo bar chart demo"
		/>
	);
};
