import { ReportStatusData } from '@/interfaces/reportStatus';
import client from '@/lib/http/client';

export function setBadgeColor(r: ReportStatusData, i: number): string {
	if (r.status[0] === 'ready') {
		return '#389e0d';
	}

	if (r.status[0] === 'timeout') {
		return 'red';
	}

	if (r.status[0] === 'cancelled') {
		return 'volcano';
	}

	if (!r.count[i]) {
		return 'grey';
	}

	if (r.count[i] === 0 && r.processed[i] === 0) {
		return 'grey';
	}

	return 'blue';
}

export function setBadgeText(r: ReportStatusData, i: number): string {
	if (r.count[i] === null) {
		return 'Calculating...';
	}

	if (r.count[i] === 0 && r.processed[i] === 0) {
		return '100%'; // No Videos Found
	}

	return `${limitNumberWithinRange(
		(r.processed[i] / r.count[i]) * 100,
		0,
		100,
	).toFixed(0)}%`;
}

export function limitNumberWithinRange(num: number, min: number, max: number) {
	const MIN = min ?? 1;
	const MAX = max ?? 20;
	return Math.min(Math.max(num, MIN), MAX);
}

export function reGenReport(r: ReportStatusData) {
	return {
		[r.creator_id[0]]: {
			_id: r.creator_id[0],
			platforms: r.creator_url,
			name: r.title[0],
			image: r.creator_img[0],
			scrapeTo: r.scrape_to[0],
			searchTags: r.tags && r.tags[0],
		},
	};
}

export function getSortQueryParams(
	column: string,
	query: string | null,
): 'ascend' | 'descend' | undefined {
	if (!query) {
		return undefined;
	}

	const c = query?.split(',').filter((x) => x.includes(column));

	if (c.length === 0) {
		return undefined;
	}

	return c[0]?.split(':')[1] === 'asc' ? 'ascend' : 'descend';
}

export async function copyTextToClipboard(text = '') {
	if ('clipboard' in navigator) {
		return await navigator.clipboard.writeText(text);
	} else {
		return document.execCommand('copy', true, text);
	}
}

export function customRound(num: number) {
	let roundedNum = Math.ceil(num * 2) / 2;
	if (roundedNum - num > 0.25) {
		// If the rounded number differs from the original by more than 0.1
		roundedNum -= 0.5; // Adjust by subtracting 0.5
	}
	return roundedNum;
}
