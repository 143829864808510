import { useContext, useEffect } from 'react';

import { Button, Drawer, Flex, List, Skeleton, Space, Typography } from 'antd';
import Link from 'antd/es/typography/Link';

import { SubscriptionContext } from '@/App';
import { useGetProductSubscription } from '@/api/customer_subscription';
import { productItems } from '@/layouts/subscription/NewProductSubscription/data';
import { PageContext } from '@/providers/PageContextProvider';

export default function ViewProductSubscriptionDrawer() {
	const { drawer, closeDrawer } = useContext(PageContext);
	const { subscriptions } = useContext(SubscriptionContext);

	const { data, isError, error, isLoading } = useGetProductSubscription(
		drawer?.data?.subscriptionName,
	);

	const onClose = () => {
		closeDrawer();
	};

	const productItem = productItems.find((item) => item.id === drawer?.data?.id);

	const subscriptionName =
		typeof data?.data?.[0]?.subscription_name === 'string'
			? data?.data?.[0]?.subscription_name
			: '';
	const features =
		typeof data?.data?.[0]?.features === 'string'
			? data?.data?.[0]?.features?.split('\n')
			: [];

	const isAlreadyInSubscriptions =
		drawer.type === 'view-product-subscription' &&
		subscriptions?.some((current) =>
			current?.subscription_name
				?.toLowerCase()
				?.includes(drawer?.data?.subscriptionName?.toLowerCase()),
		);

	return (
		<Drawer
			open={drawer.isOpen && drawer.type === 'view-product-subscription'}
			width={'650px'}
			onClose={onClose}
			closable={false}
			maskClosable={false}
			loading={isLoading}
			title={
				<Flex gap={16} align="center">
					{isLoading ? (
						<Skeleton.Image active />
					) : (
						<img
							// TODO: update + remove hardcoded images
							src={data?.data?.[0]?.product_image ?? productItem?.image}
							alt="Product image"
							width={120}
							height={'auto'}
							style={{ borderRadius: '10px', overflow: 'hidden' }}
						/>
					)}
					<Typography.Title level={2}>{subscriptionName}</Typography.Title>
				</Flex>
			}
		>
			<Flex justify="flex-end">
				<Space>
					<Button onClick={onClose}>Close</Button>
					<Link href="https://www.socialvoice.ai" target="_blank">
						<Button type="primary" disabled={isAlreadyInSubscriptions}>
							{isAlreadyInSubscriptions ? 'Already subscribed' : 'Buy now'}
						</Button>
					</Link>
				</Space>
			</Flex>

			<Flex vertical gap={10} align="stretch" justify="flex-start">
				<Typography.Title level={5} style={{ marginBottom: 0 }}>
					Description
				</Typography.Title>
				<Typography.Text>{data?.data?.[0]?.description}</Typography.Text>
				{features?.length > 0 && (
					<>
						<Typography.Title level={5} style={{ marginBottom: 0 }}>
							Features
						</Typography.Title>
						<List
							size="small"
							itemLayout="horizontal"
							dataSource={features}
							renderItem={(item, index) => (
								<List.Item key={`feature-${index}`}>
									<List.Item.Meta
										avatar="📌"
										title={
											<span style={{ fontWeight: 400 }}>{item as string}</span>
										}
									/>
								</List.Item>
							)}
						/>
					</>
				)}
			</Flex>
		</Drawer>
	);
}
