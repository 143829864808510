import { useContext, useEffect } from 'react';

import { Button, Col, Drawer, Flex, Form, Select, message } from 'antd';
import { useForm } from 'antd/es/form/Form';
import TextArea from 'antd/es/input/TextArea';

import { useCreateBatchProcess } from '@/api/batch_process';
import { WHISPER_ACTIONS } from '@/const';
import { PageContext } from '@/providers/PageContextProvider';

const layout = {
	labelCol: { span: 5 },
	wrapperCol: { span: 21 },
};

export default function AddNewBatchProcessDrawer() {
	const { drawer, closeDrawer } = useContext(PageContext);

	const [form] = useForm();

	const { mutate, isError, error, isSuccess, isLoading } =
		useCreateBatchProcess();

	useEffect(() => {
		if (isSuccess) {
			message.success({ content: 'Added' });
			form.resetFields();
			closeDrawer();
			return;
		}

		if (isError) {
			const addError = error as any;

			message.error({
				type: 'error',
				content: addError?.response?.data?.message ?? addError?.message,
			});
		}
	}, [isError, isSuccess]);

	const onCancelClick = () => {
		form.resetFields();
		closeDrawer();
	};

	const onSaveClick = async () => {
		// form.resetFields();
		// closeDrawer();

		const values = form.getFieldsValue();

		console.log(`values \n${JSON.stringify(values, null, 2)}`);

		try {
			await form.validateFields();

			const { actions, tags, collectionName, urls } = form.getFieldsValue();

			mutate({
				actions,
				tags,
				// collectionName,
				urls: urls?.split('\n').map((item: string) => item && item?.trim()),
			});
		} catch (error) {
			console.log(error);
		}
	};

	return (
		<Drawer
			open={drawer.isOpen && drawer.type === 'add-new-batch-process'}
			width={'650px'}
			onClose={onCancelClick}
			title="Video on-demand - new batch"
		>
			<Col span={24}>
				<Flex
					justify="end"
					align="center"
					gap={20}
					style={{ marginBottom: '1.25rem' }}
				>
					<Button onClick={onCancelClick}>Cancel</Button>
					<Button type="primary" onClick={onSaveClick} loading={isLoading}>
						Save
					</Button>
				</Flex>
			</Col>
			<Form form={form} {...layout}>
				{/* Collection name
				<Form.Item
					label="Collection name"
					name="collectionName"
					rules={[
						{
							required: true,
							message: 'Name is required',
						},
					]}
				>
					<Input />
				</Form.Item> */}

				{/* Tags */}
				<Form.Item label="Tags" name="tags">
					<Select
						mode="tags"
						style={{ width: '100%' }}
						placeholder="Add tags"
						options={[]}
					/>
				</Form.Item>
				{/* Actions */}

				<Form.Item
					label="Actions"
					name="actions"
					rules={[
						{
							required: true,
							message: 'Actions are required. Choose at least one.',
						},
					]}
				>
					<Select placeholder="Select actions" allowClear mode="multiple">
						{WHISPER_ACTIONS.map((item) => (
							<Select.Option value={item.value} key={item.value}>
								{item.label}
							</Select.Option>
						))}
					</Select>
				</Form.Item>

				{/* URLs to analyse */}
				<Form.Item
					label="URLs to analyse"
					name="urls"
					rules={[
						{
							required: true,
							message: 'URLs are required',
						},
					]}
				>
					<TextArea
						rows={12}
						placeholder={`https://www.youtube.com/watch?v=jNQXAC9IVRw\nhttps://www.instagram.com/p/C-GFnTrJSNi/\nhttps://www.tiktok.com/@look556666/video/7184420791466118446`}
					/>
				</Form.Item>
			</Form>
		</Drawer>
	);
}
