export default function prepareDate(
	dateString: string,
	isShort: boolean = false,
) {
	const date = new Date(dateString);

	// Options for formatting the date DateTimeFormatOptions
	const options: Intl.DateTimeFormatOptions = {
		year: 'numeric',
		month: 'short',
		day: 'numeric',
		hour: isShort ? undefined : 'numeric',
		minute: isShort ? undefined : 'numeric',
		// second: 'numeric',
		//   timeZoneName: 'short',
	};

	// Convert the date to a readable string
	return date.toLocaleDateString('en-GB', options);
}
