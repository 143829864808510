import { Flex, Typography, Tag, Space } from "antd";

import style from "../styles.module.css";

interface TProps {
  data: any;
}

export default function Keywords({ data }: TProps) {
  return (
    <Flex vertical className={style.scrollableWrapper}>
      {data?.video?.keywords?.length > 0 ? (
        <Space size={[0, 4]} style={{ marginBottom: "0.5rem" }} wrap>
          {data?.video?.keywords?.map((word: string, idx: number) => (
            <Tag key={word + idx}>{word}</Tag>
          ))}
        </Space>
      ) : (
        <Typography.Text style={{ textAlign: "left" }}>N/A</Typography.Text>
      )}
    </Flex>
  );
}
