import { Flex, Typography } from 'antd';
import cn from 'classnames';

import { CopilotBarChart } from '@/components/charts/copilot';

import style from '../styles.module.css';

type TProps = {
	data: any;
};

export default function EntityInsights({ data }: TProps) {
	return (
		<Flex vertical gap={4} align="flex-start">
			{data?.video?.mainCategories?.length > 0 && (
				<Typography.Text>
					<b>Categories:</b> {data?.video?.mainCategories?.join(', ')}
				</Typography.Text>
			)}

			{data?.video?.entities?.length > 0 && (
				<Typography.Text
					className={cn(data?.video?.entities?.[0] === 'N/A' && style.grayText)}
				>
					<b>Entities:</b>{' '}
					{data?.video?.entities?.[0] === 'N/A'
						? 'n/a'
						: data?.video?.entities.join(', ')}
				</Typography.Text>
			)}

			{data?.video?.brandMentions && (
				<div style={{ height: '300px', width: '100%', marginTop: '0.25rem' }}>
					<Typography.Text>
						<b>Brand visibility:</b>
					</Typography.Text>
					<CopilotBarChart data={data?.video?.brandMentions} />
				</div>
			)}
		</Flex>
	);
}
