import React from 'react';

import { useMutation } from '@tanstack/react-query';
import { Button, Checkbox, Form, Input, Spin, Steps } from 'antd';
import axios, { AxiosError } from 'axios';

import client from '@/lib/http/client';

import { ButtonTimer } from './buttonTimer';
import { UpdatePasswordForm } from './userProfileInterface';

type iProps = {
	email: string;
};

export const UserResetPassword = () => {
	const verefyEmail = () => {
		return client.post(`users/request-reset-password-loged-in`, {});
	};

	const udatePassword = (formValues: UpdatePasswordForm) => {
		const { confirm, ...data } = formValues;
		data.logout = data.logout ? true : false;
		return client.post(`users/confirm-reset-password-loged-in`, data);
	};

	const { isLoading, mutate } = useMutation({
		mutationFn: verefyEmail,
		onSuccess: () => {
			setStage(1);
			setStepsStatus('process');
		},
		onError: (error) => {
			if (axios.isAxiosError(error)) {
				setStepsStatus('error');
			}
		},
	});
	const { isLoading: isUpdatingPassword, mutate: updatePasswordMutate } =
		useMutation({
			mutationFn: udatePassword,
			onError: (error) => {
				if (axios.isAxiosError(error)) {
					setValidateStatus('error');
					setErrorMessage(error.response?.data?.message);
					setStepsStatus('error');
				}
			},
			onSuccess: () => {
				setStage(2);
				setStepsStatus('finish');
			},
		});

	const [errorMessage, setErrorMessage] = React.useState<undefined | string>(
		undefined,
	);
	const [validateStatus, setValidateStatus] = React.useState<
		'error' | 'success' | 'warning' | 'validating' | undefined
	>(undefined);
	const [stepsStatus, setStepsStatus] = React.useState<
		'error' | 'finish' | 'wait' | 'process' | undefined
	>('process');

	const [stage, setStage] = React.useState(0);

	const onEmailCheck = (): void => {
		mutate();
	};

	const onPasswordChange = (values: UpdatePasswordForm) => {
		updatePasswordMutate(values);
	};

	const next = () => {
		setStage(stage + 1);
	};

	const prev = () => {
		setStage(stage - 1);
	};

	const steps = [
		{
			title: '1',
		},
		{
			title: '2',
		},
		{
			title: '3',
		},
	];
	const items = steps.map((item) => ({ key: item.title, title: '' }));
	return (
		<Spin spinning={isLoading || isUpdatingPassword}>
			<Steps
				current={stage}
				status={stepsStatus}
				items={items}
				style={{ margin: '10px 0 35px' }}
			/>
			{stage === 0 && (
				<Form
					layout="vertical"
					name="reset-password-form-1"
					disabled={isLoading}
					onFinish={onEmailCheck}
				>
					<>
						<p>
							To reset your password, we will send a confirmation code to your
							account mailbox{' '}
						</p>
						<Form.Item>
							<Button
								htmlType="submit"
								type="primary"
								block
								loading={isLoading}
							>
								Send code
							</Button>
						</Form.Item>
					</>
				</Form>
			)}

			{stage === 1 && (
				<>
					<Form
						layout="vertical"
						name="reset-password-form-2"
						disabled={isUpdatingPassword}
						onFinish={onPasswordChange}
					>
						<>
							<Form.Item
								label="Verification code from email"
								name="token"
								rules={[
									{
										required: true,
										message: 'Please input your token from email!',
									},
									({ getFieldValue }) => ({
										validator(_, value) {
											if (!value) {
												setValidateStatus('error');
												setErrorMessage('Please input your token from email!');
												return Promise.resolve();
											} else if (value.length <= 9) {
												setValidateStatus('success');
												setErrorMessage('');
												return Promise.resolve();
											} else {
												setValidateStatus('error');
												setErrorMessage('Code is too long');
												return Promise.reject();
											}
										},
									}),
								]}
								validateStatus={validateStatus || undefined}
								help={errorMessage || undefined}
							>
								<Input placeholder="" autoComplete="off" />
							</Form.Item>

							<Form.Item
								label="New password"
								name="password"
								rules={[
									{
										required: true,
										message: 'Please input your new password!',
									},
									({ getFieldValue }) => ({
										validator(_, value) {
											const passwordRegex =
												/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\x20-\x7E])[A-Za-z\d\x20-\x7E]{8,}$/;
											if (!value || passwordRegex.test(value)) {
												return Promise.resolve();
											}
											return Promise.reject(
												new Error(
													'Password must be at least 8 characters long and contain at least: one uppercase letter, one lowercase letter, one number, one special character',
												),
											);
										},
									}),
								]}
								help={
									<div>
										<p>
											Password must be at least 8 characters long and contain at
											least:
										</p>
										<ul>
											<li>one uppercase letter</li>
											<li>one lowercase letter</li>
											<li>one number</li>
											<li>one special character : (@,$,!,%,*,?,&)</li>
										</ul>
									</div>
								}
								hasFeedback
							>
								<Input.Password placeholder="" autoComplete="new-password" />
							</Form.Item>

							<Form.Item
								name="confirm"
								label="Confirm Password"
								dependencies={['password']}
								hasFeedback
								rules={[
									{
										required: true,
										message: 'Please confirm your password!',
									},
									({ getFieldValue }) => ({
										validator(_, value) {
											if (!value || getFieldValue('password') === value) {
												return Promise.resolve();
											}
											return Promise.reject(
												new Error(
													'The new password that you entered do not match!',
												),
											);
										},
									}),
								]}
							>
								<Input.Password autoComplete="off" />
							</Form.Item>

							<Form.Item name="logout" valuePropName="checked">
								<Checkbox>Sign out from all sessions.</Checkbox>
							</Form.Item>

							<Form.Item>
								<Button
									type="primary"
									htmlType="submit"
									block
									loading={isUpdatingPassword}
								>
									Change password
								</Button>
							</Form.Item>
						</>
					</Form>
					<ButtonTimer sendEmailCode={onEmailCheck} />
				</>
			)}

			{stage === 2 && (
				<div
					style={{
						textAlign: 'center',
					}}
				>
					<p>Your password has been updated.</p>
				</div>
			)}

			<div style={{ marginTop: 24 }}>
				{stage === steps.length && (
					<Button style={{ margin: '0 8px' }} onClick={() => prev()}>
						Back
					</Button>
				)}
			</div>
		</Spin>
	);
};
