// install (please try to align the version of installed @nivo packages)
// yarn add @nivo/boxplot
import React from 'react';

// @ts-ignore
import { ResponsiveBoxPlot } from '@nivo/boxplot';

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.

interface IProps {
	data: any[];
}

const ChartBoxPlot = (props: IProps) => (
	<ResponsiveBoxPlot
		data={props.data}
		margin={{ top: 60, right: 250, bottom: 60, left: 60 }}
		minValue={0}
		maxValue={5}
		subGroupBy=""
		padding={0.25}
		enableGridX={true}
		// tickFormat=""
		axisBottom={null}
		// axisBottom={{
		//   tickSize: 5,
		//   tickPadding: 5,
		//   tickRotation: 0,
		//   legend: "",
		//   legendOffset: 36
		// }}
		axisRight={{
			tickSize: 5,
			tickPadding: 5,
			tickRotation: 0,
			legend: '',
			legendOffset: 0,
		}}
		axisTop={null}
		axisLeft={{
			tickSize: 5,
			tickPadding: 5,
			tickRotation: 0,
			legend: 'value',
			legendPosition: 'middle',
			legendOffset: -40,
		}}
		colors={{ scheme: 'nivo' }}
		borderRadius={2}
		borderWidth={2}
		borderColor={{
			from: 'color',
			modifiers: [['darker', 0.3]],
		}}
		medianWidth={2}
		medianColor={{
			from: 'color',
			modifiers: [['darker', 0.3]],
		}}
		whiskerEndSize={0.6}
		whiskerColor={{
			from: 'color',
			modifiers: [['darker', 0.3]],
		}}
		colorBy="group"
		motionConfig="stiff"
		legends={[
			{
				anchor: 'right',
				direction: 'column',
				justify: false,
				translateX: 100,
				translateY: 0,
				itemWidth: 60,
				itemHeight: 20,
				itemsSpacing: 3,
				itemTextColor: '#999',
				itemDirection: 'left-to-right',
				symbolSize: 20,
				symbolShape: 'square',
				effects: [
					{
						on: 'hover',
						style: {
							itemTextColor: '#000',
						},
					},
				],
			},
		]}
	/>
);

export default ChartBoxPlot;
