import React, { useContext, useEffect } from 'react';

import { CloseCircleOutlined } from '@ant-design/icons';
import { Button, Drawer, Form, Space, Spin } from 'antd';
import { useSearchParams } from 'react-router-dom';

import { SubscriptionContext } from '@/App';
import ErrorBoundary from '@/components/errors/boundary';
import SubscribtionBanner from '@/layouts/subscription/SubscribtionBanner';
import { totalAvailableReports } from '@/layouts/subscription/helper';
import { useSubscription } from '@/layouts/subscription/useSubscription';
import client from '@/lib/http/client';

import style from './style.module.css';

import { ReactComponent as SubscribeSvg } from '../../../../../layouts/subscription/src/Subscribe.svg';
import { ReactComponent as UpdateSubscription } from '../../../../../layouts/subscription/src/UpdateSubscription.svg';
import { ReportProvider } from './context/ReportContext';
import SecondStep from './second-step';

interface IProps {
	addReport: boolean;
	setAddReport: React.Dispatch<React.SetStateAction<boolean>>;
	onFinish?: () => void;
}

const AddReport = (props: IProps) => {
	const [searchParams, setSearchParams] = useSearchParams();

	const [form] = Form.useForm();
	const [search, setSearch] = React.useState('');
	const [loading, setLoading] = React.useState(false);

	// const [subscription, setSubscription] = React.useState<any>(null);
	// const [isSubscriptionValid, setIsSubscriptionValid] = React.useState(false);
	// const [isLoading, setIsLoading] = React.useState(true);

	const { isSubscriptionsValid, subscription, isLoading, refetch } =
		useSubscription();
	const { repotsCount, setRepotsCount } = useContext(SubscriptionContext);
	// const [totalReports, setTotalReports] = React.useState(0);

	// useEffect(() => {
	// 	if (subscription) {
	// 		changeTotalReports(totalAvailableReports(subscription));
	// 	}
	// }, [subscription]);

	// const changeTotalReports = (newTotal: number) => {
	// 	setTotalReports(newTotal);
	// };

	useEffect(() => {
		const platformHandle = searchParams.get('platformHandle');

		if (platformHandle) {
			// setAddReport(true);
		}
	}, [searchParams]);

	const onClose = () => {
		props.setAddReport(false);
		setSearch('');
		searchParams.delete('platformHandle');
		setSearchParams(searchParams);
		if (subscription) {
			setRepotsCount(totalAvailableReports(subscription));
		}
	};

	const onGenerateReport = (payload: any) => {
		setLoading(true);
		client
			.post(`/reports/generate`, payload)
			.then(() => {
				setLoading(false);
				props.setAddReport(false);
				props.onFinish && props.onFinish();
				searchParams.delete('platformHandle');
				setSearchParams(searchParams);
			})
			.then(() => {
				refetch();
			});
	};

	if (isLoading) {
		return <Spin />;
	}

	return (
		<ReportProvider>
			<Drawer
				destroyOnClose
				title="Add new report"
				width={'80%'}
				placement="right"
				closable={false}
				onClose={onClose}
				open={props.addReport}
				getContainer={false}
				extra={
					<Space>
						{/* {subscription && <CreditsCounter count={totalReports} />} */}

						<Button onClick={onClose}>
							<CloseCircleOutlined /> Close
						</Button>
					</Space>
				}
			>
				{isSubscriptionsValid && subscription && (
					<Spin spinning={loading}>
						<div className={style.influencerGrid}>
							<ErrorBoundary message="Something went wrong - please try again">
								<SecondStep
									onGenerateReport={onGenerateReport}
									platformHandle={searchParams.get('platformHandle') ?? ''}
								/>
							</ErrorBoundary>
						</div>
					</Spin>
				)}
				{!isSubscriptionsValid && subscription && (
					<div style={{ display: 'flex', justifyContent: 'center' }}>
						<div style={{ width: '100%', maxWidth: '500px' }}>
							<SubscribtionBanner
								description="It appears that your subscription has expired or your credits have been depleted. Please proceed to the subscription page."
								IconComponent={UpdateSubscription}
							/>
						</div>
					</div>
				)}
				{!isSubscriptionsValid && !subscription && (
					<div style={{ display: 'flex', justifyContent: 'center' }}>
						<div style={{ width: '100%', maxWidth: '500px' }}>
							<SubscribtionBanner
								description="Looks like you haven't chosen a subscription plan yet . But you can
								easily choose a plan by following the link ."
								IconComponent={SubscribeSvg}
							/>
						</div>
					</div>
				)}

				{/* 
				<div className={style.influencerGrid}>
					<FirstStep />
				</div> */}
			</Drawer>
		</ReportProvider>
	);
};

export default AddReport;
