import React from 'react';

import { Button, Card, Form, Input, Typography } from 'antd';
import axios, { AxiosError } from 'axios';
import { useNavigate } from 'react-router-dom';
import { useSearchParams } from 'react-router-dom';

import client from '@/lib/http/client';

import style from './style.module.css';

import logo from '../login/logo.png';

const submit = async (values: any, token: string) => {
	try {
		return await client.post(`customers/jointeam?token=${token}`, {
			...values,
			role: '',
			department_id: 3,
		});
	} catch (err) {
		if (axios.isAxiosError(err)) {
			throw err;
		}
		// eslint-disable-next-line no-throw-literal
		throw err as AxiosError<any, any>;
	}
};

const JoinTeam = () => {
	const [form] = Form.useForm();
	const [loading, setLoading] = React.useState(false);
	const [searchParams] = useSearchParams();

	const navigate = useNavigate();
	return (
		<Card
			title={
				<div className={style.wrapper}>
					<div className={style.logo}>
						<img src={logo} alt="" />
					</div>
					<div className={style.title}>
						<h2>Join team</h2>
					</div>
				</div>
			}
			style={{ minWidth: '428px' }}
		>
			<div
				style={{
					textAlign: 'center',
					margin: '5px 0',
				}}
			>
				{/* <Typography.Text strong>Please check your email box</Typography.Text> */}
			</div>
			<Form
				layout="vertical"
				form={form}
				disabled={loading}
				onFinish={(values) => {
					setLoading(true);
					submit(values, searchParams.get('token') || '')
						.then((res) => {
							navigate('/auth/login');
						})
						.catch((err: AxiosError<any, any>) => {
							console.log(err.response?.data);
							form.setFields(
								Array.isArray(err.response?.data.message)
									? err.response?.data.message.map((v: string) => ({
											name: v.split(' ')[0],
											errors: [v],
									  }))
									: [
											{
												name: err.response?.data.message.split(' ')[0],
												errors: [err.response?.data.message],
											},
									  ],
							);
						})
						.finally(() => {
							setLoading(false);
						});
				}}
			>
				<Form.Item
					label="First Name"
					name="first_name"
					initialValue={''}
					rules={[
						{
							required: true,
						},
					]}
				>
					<Input />
				</Form.Item>
				<Form.Item
					label="Last Name"
					name="last_name"
					initialValue={''}
					rules={[
						{
							required: true,
						},
					]}
				>
					<Input />
				</Form.Item>

				<Form.Item
					label="Password"
					name="password"
					initialValue={''}
					rules={[
						{
							required: true,
						},
					]}
				>
					<Input.Password />
				</Form.Item>

				<Form.Item
					label="Confirm Password"
					name="confirm_password"
					initialValue={''}
					rules={[
						{
							required: true,
						},
					]}
				>
					<Input.Password />
				</Form.Item>

				<Form.Item>
					<Button type="primary" htmlType="submit" block loading={loading}>
						Confirm
					</Button>
				</Form.Item>
			</Form>
		</Card>
	);
};

export default JoinTeam;
