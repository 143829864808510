import React from 'react';

import { CheckCircleOutlined } from '@ant-design/icons';
import { Card } from 'antd';
import { head } from 'lodash';

import style from './style.module.css';

import { TopUpIcon } from './SubscriptionIcons';
import { ClientSubscription } from './subscriptionType';

const SubscriptionCartTopUp: React.FC<{
	subscription: ClientSubscription;
	count: number;
}> = ({ subscription, count }) => {
	return (
		<div style={{ flex: '1 1 0', maxWidth: '350px', width: '100%' }}>
			<Card title={'Top Up'} bordered={false} className={style.andtCard}>
				<div className={style.andtForm}>
					<div>
						<TopUpIcon />
					</div>
					<p>
						You acquire an additional quantity of reports that will help you
						achieve your goal.
					</p>
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							marginTop: '24px',
							justifyContent: 'space-around',
						}}
					>
						<div
							style={{
								display: 'flex',
								alignItems: 'center',
							}}
						>
							<span style={{ fontSize: '1.9rem' }}> {count} </span>
							<p
								style={{
									fontSize: '0.9rem',
									marginLeft: '8px',
									lineHeight: 'normal',
									textAlign: 'left',
									display: 'inline-block',
									maxWidth: 'min-content',
								}}
							>
								Extra Reports
							</p>
						</div>

						<div
							style={{
								height: '100%',
								fontSize: '1.9rem',
							}}
						>
							for
						</div>

						<div
							style={{
								display: 'flex',
								alignItems: 'center',
							}}
						>
							<span style={{ fontSize: '1.9rem' }}>
								€{' '}
								{subscription?.top_up_eur != null
									? +subscription.top_up_eur * count
									: 0}
							</span>
							<p
								style={{
									fontSize: '0.9rem',
									width: '30px',
									marginLeft: '8px',
									lineHeight: 'normal',
								}}
							>
								total amount
							</p>
						</div>
					</div>
				</div>
			</Card>
		</div>
	);
};

export default SubscriptionCartTopUp;
