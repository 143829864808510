import React from 'react';

import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import {
	Alert,
	Avatar,
	Badge,
	Card,
	Descriptions,
	Divider,
	List,
	Popover,
	Progress,
	Rate,
	Space,
	Statistic,
	Tag,
	Tooltip,
} from 'antd';
import dayjs from 'dayjs';
import { InView, useInView } from 'react-intersection-observer';

import { fetchCreatorReport } from '@/interfaces/report';
import { formatNumber } from '@/lib/helpers/numFormat';

import style from '../style.module.css';
import ls from './style.module.css';

import { copyTextToClipboard, customRound } from '../../../helper';
import Platforms from '../../../platforms';
import { InfoSection } from '../informationPopop';
import { IReportData, IReportDataErrors } from '../report.types';
import snapshots from './snaphots.png';
import trustscore from './trustscore.png';

interface IProps {
	id: string;
	data?: IReportData;
	report_id?: string;
	errors?: IReportDataErrors;
}

const ReportSummary = (props: IProps) => {
	const { ref, inView, entry } = useInView();
	const [fetched, setFetched] = React.useState(false);
	const { data, report_id, errors } = props;
	const profileScrape: string[] = ['youtube', 'instagram', 'tiktok'];

	// if (!inView) {
	// 	return (
	// 		<div
	// 			ref={ref}
	// 			style={{
	// 				height: inView ? 'auto' : entry?.target.clientHeight,
	// 				display: InView ? 'block' : 'none',
	// 			}}
	// 		></div>
	// 	);
	// }

	// if (errors && errors['metrics']) {
	// 	return (
	// 		<Alert
	// 			message={errors['metrics'].code}
	// 			description={errors['metrics'].message}
	// 			type="error"
	// 			showIcon
	// 		/>
	// 	);
	// }

	return (
		<div ref={ref}>
			<div className={ls.wrapperGrid}>
				<div className={ls.layout}>
					<div>
						<h3 className={style.link}>
							Summary
							<InfoSection
								title="Summary"
								content={
									<>
										<p>
											General information about the creator including primary
											spoken language, location and top categories discussed.
										</p>
										<p>
											Also highlighted are the video platforms active,
											engagement rate, combined followers, and more.
										</p>
									</>
								}
							/>
						</h3>{' '}
						<div>
							<Descriptions column={1} bordered size="small">
								<Descriptions.Item label="Location">
									{data?.creator?.location}
								</Descriptions.Item>
								<Descriptions.Item label="Language">
									{data?.creator?.language}
								</Descriptions.Item>
								{/* <Descriptions.Item label="Age group">
								{data?.creator?.ageGroup}
							</Descriptions.Item> */}
								<Descriptions.Item label="Main Categories">
									<Space size={[0, 8]} wrap>
										{data?.creator?.mainCategories?.map((c: any) => (
											<Tag key={c}>{c}</Tag>
										))}
									</Space>
								</Descriptions.Item>
								<Descriptions.Item label="Active Since">
									{dayjs(data?.creator?.activeSince).format('DD MMM YYYY')}
								</Descriptions.Item>
							</Descriptions>
						</div>
					</div>

					<div>
						<h3 className={style.link}>
							Report Metadata
							<InfoSection
								title="Report Metadata"
								content={
									<>
										<p>
											Information relating to the report such as when it was
											produced, the date-range it encompasses and a unique
											identifier for the report itself.
										</p>
									</>
								}
							/>
						</h3>{' '}
						<Descriptions column={1} bordered size="small">
							<Descriptions.Item label="Date">
								{dayjs(data?.report?.reportDateTime).format('DD MMM YYYY')}
							</Descriptions.Item>

							<Descriptions.Item label="Videos Analysed">
								{data?.report?.videosAnalyzed}
							</Descriptions.Item>

							<Descriptions.Item label="Video Hours">
								{`${Math.floor(
									(data?.report?.videoSeconds || 0) / 3600,
								)} hours ${Math.floor(
									((data?.report?.videoSeconds || 0) % 3600) / 60,
								)} minutes`}
							</Descriptions.Item>

							<Descriptions.Item label="Last Video Date">
								{dayjs(data?.creator?.latestVideo).format('DD MMM YYYY')}
							</Descriptions.Item>

							<Descriptions.Item label="Report Date Range">
								{dayjs(data?.report?.dateRange.from).format('DD MMM YYYY')}
								{' - '}
								{dayjs(data?.report?.dateRange.to).format('DD MMM YYYY')}
							</Descriptions.Item>

							<Descriptions.Item label="Report ID">
								<div
									style={{ cursor: 'pointer' }}
									onClick={() => copyTextToClipboard(report_id)}
								>
									<Tooltip title={`Click to copy`}>
										{
											report_id || ''
											// .toUpperCase()
											// .split('-')
											// .at(-1)
										}
									</Tooltip>
								</div>
							</Descriptions.Item>

							{data?.reportPlatforms
								?.sort((a, b) => {
									return (
										profileScrape.indexOf(a?.platformName?.toLowerCase()) -
										profileScrape.indexOf(b?.platformName?.toLowerCase())
									);
								})
								?.map((item, i) => (
									<Descriptions.Item
										label={
											item?.platformName?.toLowerCase() == 'youtube'
												? 'YouTube'
												: item?.platformName
										}
										key={i}
									>
										<Avatar
											src={process.env.REACT_APP_CDN_STORAGE + '' + item.image}
											style={{ marginRight: '10px' }}
										></Avatar>

										<a href={item.channelUrl} target="_blank" rel="noreferrer">
											{item.channelName}
										</a>
									</Descriptions.Item>
								))}
						</Descriptions>
					</div>

					{/* Score */}
				</div>

				<div className={ls.trustScode}>
					<div className={ls.scoreLayout}>
						<div
							className={style.imageContainer}
							style={{
								display: 'flex',
								justifyContent: 'center',
								margin: '20px 0',
							}}
						>
							<img src={trustscore} alt="" style={{ maxWidth: '50%' }} />
						</div>
						<div className={ls.scoreTitle}>
							<div
								style={{
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
								}}
							>
								<div style={{ marginLeft: '4em' }}>Trust My Voice ™</div>
								<InfoSection
									title="Trust Score"
									content={
										<>
											<p>
												The SocialVoice Trust Score (tm) is a unique system
												designed to allow an 'at a glance' confidence metric of
												the accounts activity over the date range of the report.
											</p>
											<p>
												Each of the scores given are summaries of the individual
												data-points gathered and detailed in various sections of
												the overall report.
											</p>
										</>
									}
								/>
							</div>
							<Rate
								allowHalf
								disabled
								style={{ fontSize: '14px' }}
								value={customRound(
									Object.values(data?.metrics || {})
										.filter((x) => x > 0)
										.reduce((acc, cur) => acc + cur, 0) /
										Object.values(data?.metrics || {}).filter((x) => x > 0)
											.length,
								)}
							/>
						</div>
						<div className={ls.trustScore}>
							<div className={ls.scoreItem}>
								<div className={ls.scoreKey}>Consistency</div>
								<div className={ls.rate}>
									<Rate
										allowHalf
										disabled
										value={data?.metrics?.consistency || undefined}
										style={{ fontSize: '10px' }}
									/>
									<div>{data?.metrics?.consistency.toFixed(1)}</div>
								</div>
							</div>

							<div className={ls.scoreItem}>
								<div className={ls.scoreKey}>Empathy</div>
								<div className={ls.rate}>
									<Rate
										allowHalf
										disabled
										value={data?.metrics?.empathy || undefined}
										style={{ fontSize: '10px' }}
									/>
									<div>{data?.metrics?.empathy.toFixed(1)}</div>
								</div>
							</div>

							<div className={ls.scoreItem}>
								<div className={ls.scoreKey}>Authenticity</div>
								<div className={ls.rate}>
									<Rate
										allowHalf
										disabled
										value={data?.metrics?.authenticity || undefined}
										style={{ fontSize: '10px' }}
									/>
									<div>{data?.metrics?.authenticity.toFixed(1)}</div>
								</div>
							</div>

							<Divider style={{ margin: '35px 0' }} />

							<div className={ls.scoreItem}>
								<div className={ls.scoreKey}>Profanity</div>
								<div className={ls.rate}>
									<Rate
										allowHalf
										disabled
										value={data?.metrics?.profanity || undefined}
										style={{ fontSize: '10px' }}
									/>
									<div>{data?.metrics?.profanity.toFixed(1)}</div>
								</div>
							</div>

							<div className={ls.scoreItem}>
								<div className={ls.scoreKey}>Toxicity</div>
								<div className={ls.rate}>
									<Rate
										allowHalf
										disabled
										value={data?.metrics?.toxicity || undefined}
										style={{ fontSize: '10px' }}
									/>
									<div>{data?.metrics?.toxicity.toFixed(1)}</div>
								</div>
							</div>

							<div className={ls.scoreItem}>
								<div className={ls.scoreKey}>Red Flag Content</div>
								<div className={ls.rate}>
									<Rate
										allowHalf
										disabled
										value={data?.metrics?.redFlagContent || undefined}
										style={{ fontSize: '10px' }}
									/>
									<div>{data?.metrics?.redFlagContent.toFixed(1)}</div>
								</div>
							</div>

							{/* END */}
						</div>
					</div>
				</div>
			</div>
			<h3 className={style.link}>
				Creator Self Introduction
				<InfoSection
					title="Creator Self Introduction"
					content={
						<>
							<p>
								This is an introduction that has been written by the account
								holder and displayed on the largest and/or most recent platform
								they are active on.
							</p>
						</>
					}
				/>
			</h3>{' '}
			<p style={{ marginBottom: '50px', whiteSpace: 'pre-line' }}>
				{data?.selfDescription}
			</p>
			<h3 className={style.link}>
				Summary Analysis of Content
				<InfoSection
					title="Summary Analysis of Content"
					content={
						<>
							<p>
								The summary describes the account being reported on at a high
								level, including follower count, language used, topics covered
								and their SocialVoice trust score.
							</p>
						</>
					}
				/>
			</h3>{' '}
			<p style={{ marginBottom: '50px' }}>{data?.creatorDescription}</p>
			<div className={ls.engagement}>
				<div className={ls.stats}>
					{/* Activity */}

					<div className={ls.activity} style={{ position: 'relative' }}>
						<h3
							style={{
								position: 'absolute',
								right: 0,
								top: 0,
								margin: 0,
								height: 0,
							}}
						>
							<InfoSection
								title="Followers per platform"
								style={{
									padding: '0',
									height: '0',
								}}
								circleStyle={{ fontSize: '12px', height: '12px' }}
								content={
									<>
										<p>
											This bar chart is a visual representation of the
											percentage of followers across each platform.
										</p>
									</>
								}
							/>
						</h3>
						<div className={ls.barcontainer}>
							<Tooltip
								title={`${
									data?.followersPercents.Youtube.toFixed(1) || 0
								}% - ${formatNumber(
									data?.creator?.followersPerPlatform?.Youtube || 0,
								)}`}
							>
								<div
									className={ls.bar}
									style={{
										height: `${data?.followersPercents.Youtube || 0}%`,
										backgroundColor: '#cd201f',
									}}
								></div>
							</Tooltip>
							<div className={ls.platformIcon}>
								<Platforms platform="https://www.youtube.com/" />
							</div>
						</div>
						<div className={ls.barcontainer}>
							<Tooltip
								title={`${
									data?.followersPercents.Instagram.toFixed(1) || 0
								}% - ${formatNumber(
									data?.creator?.followersPerPlatform?.Instagram || 0,
								)}`}
							>
								<div
									className={ls.bar}
									style={{
										height: `${data?.followersPercents.Instagram || 0}%`,
										backgroundColor: '#f77737',
									}}
								></div>
							</Tooltip>
							<div className={ls.platformIcon}>
								<Platforms platform="https://www.instagram.com/" />
							</div>
						</div>
						<div className={ls.barcontainer}>
							<Tooltip
								title={`${
									data?.followersPercents.TikTok.toFixed(1) || 0
								}% - ${formatNumber(
									data?.creator?.followersPerPlatform?.TikTok || 0,
								)}`}
							>
								<div
									className={ls.bar}
									style={{
										height: `${data?.followersPercents.TikTok || 0}%`,
									}}
								></div>
							</Tooltip>
							<div className={ls.platformIcon}>
								<Platforms platform="https://www.tiktok.com/" />
							</div>
						</div>
					</div>

					{/* Statistics */}
					<div className={ls.statistics}>
						<div className={ls.statisticsItem}>
							<Statistic
								title=<div>
									Followers
									<InfoSection
										title="Followers"
										style={{ padding: '0 5px' }}
										circleStyle={{ fontSize: '12px' }}
										content={
											<>
												<p>
													The total number of followers across all platforms.
													This includes:
												</p>
												<ul>
													<li>Instagram Followers</li>
													<li>TikTok Followers</li>
													<li>YouTube Subscribers</li>
												</ul>
											</>
										}
									/>
								</div>
								value={formatNumber(data?.creator?.totalFollowers || 0)}
								precision={1}
							/>
							{/* <Badge
								status="success"
								text={data?.summary?.platformMetrics.totalFollowers.label}
							/> */}
						</div>
						<div className={ls.statisticsItem}>
							<Statistic
								title=<div>
									Engagement
									<InfoSection
										title="Engagement Rate"
										style={{ padding: '0 5px' }}
										circleStyle={{ fontSize: '12px' }}
										content={
											<>
												<p>
													The engagement rate is calculated by the average
													engagement (likes and comments) per post divided by
													the total number of followers.
												</p>
												{/* <p>
													If each follower engaged with every post, the
													engagement rate would be 100%
												</p> */}
											</>
										}
									/>
								</div>
								value={data?.creator?.engagementRate || 0}
								suffix={'%'}
								precision={2}
							/>
							{/* <Badge
								color="blue"
								text={data?.summary?.platformMetrics.engagementRate.label}
							/> */}
						</div>

						<div className={ls.statisticsItem}>
							<Statistic
								title=<div>
									Total Posts
									<InfoSection
										title="Total Posts"
										style={{ padding: '0 5px' }}
										circleStyle={{ fontSize: '12px' }}
										content={
											<>
												<p>
													The total number of posts across all platforms. This
													includes:
												</p>
												<ul>
													<li>Instagram Videos (including Reels)</li>
													<li>Instagram Photos</li>
													<li>TikTok Videos</li>
													<li>YouTube Videos</li>
												</ul>
												<p>
													This total is calculated for all time posts, not just
													the report date range .
												</p>
											</>
										}
									/>
								</div>
								value={formatNumber(data?.creator?.totalVideos || 0)}
								precision={0}
							/>
							{/* <Badge
								color="blue"
								text={data?.summary?.platformMetrics.totalVideos.label}
							/> */}
						</div>

						<div className={ls.statisticsItem}>
							<Statistic
								title=<div>
									Post Frequency
									<InfoSection
										title="Post Frequency"
										style={{ padding: '0 5px' }}
										circleStyle={{ fontSize: '12px' }}
										content={
											<>
												<p>
													The frequency of posts across all platforms. This can
													be one of the following:
												</p>
												<ul>
													<li>
														Daily (if the creator averages one or more posts per
														day)
													</li>
													<li>
														Weekly (if the creator averages one or more posts
														per week)
													</li>
													<li>
														Monthly (if the creator averages fewer than one post
														per week)
													</li>
												</ul>
												<p>
													This frequency is calculated for videos in the report
													date range.
												</p>
											</>
										}
									/>
								</div>
								value={data?.creator?.postFrequency}
								precision={2}
							/>
							{/* <Badge
								status="success"
								text={data?.summary?.platformMetrics.postFrequency.label}
							/> */}
						</div>
					</div>
				</div>

				<div className={ls.audienceEngagement}>
					<h3 className={style.link}>Audience Engagement</h3>{' '}
					<List
						itemLayout="horizontal"
						size="small"
						bordered
						header={<strong>All Platforms</strong>}
						dataSource={[
							{
								title: 'Comment Rate',
								// content: `${data?.engagement?.commentRate?.value}% ${data?.engagement.commentRate.comment}`,
								content: `@${data?.creator.creatorName} ${data?.engagement.commentRate.value}`,
								value: data?.engagement?.commentRate?.value,
								color: 'green',
							},
							{
								title: 'Like/Comment Ratio',
								// content: data?.engagement.likeCommentRatio.comment,
								content: `@${data?.creator.creatorName} ${data?.engagement.likeCommentRatio.value}`,
								value: data?.engagement.likeCommentRatio.value,
								color: 'blue',
							},
							{
								title: 'Likes Spread',
								// content: data?.engagement.likesSpread.comment,
								content: `${data?.engagement.likesSpread.value}`,
								value: data?.engagement.likesSpread.value,
								color: 'blue',
							},
						]}
						renderItem={(item: any, index) => (
							<List.Item key={index}>
								<List.Item.Meta
									title={item.title}
									description={item.comment}
								></List.Item.Meta>
								<Badge color={item.color} /> {item.content}
							</List.Item>
						)}
					/>
				</div>
			</div>
		</div>
	);
};

export default ReportSummary;
