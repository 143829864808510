import React from 'react';

import { CheckCircleOutlined } from '@ant-design/icons';
import { useQuery } from '@tanstack/react-query';
import { Button, Card, Col, Flex, Form, Row, Spin } from 'antd';

import client from '@/lib/http/client';

import style from './style.module.css';

import SubscriptionCartFormItem from './SubscriptionCartFormItem';
import { subscription } from './subscriptionType';

const SubscriptionPlans = () => {
	const fetchSubscriptions = async () => {
		return await client.get<subscription[]>(`/subscriptions`);
	};

	const { data: subscriptions, isLoading } = useQuery({
		// queryKey: ['subscriptions'],
		queryFn: async () => await fetchSubscriptions(),
		cacheTime: 0,
	});

	if (isLoading) {
		return <Spin />;
	}

	return (
		<div className={`container subscription-wrapper`}>
			{/* <Navbar isAdmin={isAdmin} /> <Outlet context={{ x: 1 }} / */}
			<div
				style={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					width: '100%',
				}}
			>
				<div className={style.siteCardWrapper}>
					<Flex gap="large" className={style.andtRow} wrap="wrap">
						{subscriptions?.data
							.sort((a, b) => {
								if (a.per_month_eur > b.per_month_eur) return 1;
								if (a.per_month_eur < b.per_month_eur) return -1;
								return 0;
							})
							?.map((subscription: subscription, index: any) => (
								<SubscriptionCartFormItem
									key={index}
									subscription={subscription}
									index={index}
									subscribe={true}
									subscriptionId={subscription.id}
								/>
							))}
					</Flex>
				</div>
			</div>
		</div>
	);
};

export default SubscriptionPlans;
