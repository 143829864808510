import { useContext, useEffect, useState } from 'react';

import {
	CheckOutlined,
	CopyOutlined,
	EyeInvisibleOutlined,
	EyeOutlined,
} from '@ant-design/icons';
import {
	Button,
	Checkbox,
	Col,
	Drawer,
	Flex,
	Form,
	Input,
	InputNumber,
	Tooltip,
	Typography,
	message,
} from 'antd';
import TextArea from 'antd/es/input/TextArea';

import { useGetNewApiKey } from '@/api/batch_process';
import { useUpdateCustomerSubscription } from '@/api/customer_subscription';
import { VIDEO_INSIGHTS_ACTIONS } from '@/const';
import { ClientExtendedSubscription } from '@/layouts/subscription/subscriptionType';
import { copyTextToClipboard } from '@/pages/app/dashboard/creators/helper';
import { PageContext } from '@/providers/PageContextProvider';

import styles from './styles.module.css';

const hiddenString = Array.from({ length: 260 })
	.map((_) => '*')
	.join('');

const layout = {
	labelCol: { span: 7 },
	wrapperCol: { span: 21 },
};

export default function SubscriptionConfigurationDrawer() {
	const { drawer, closeDrawer } = useContext(PageContext);
	let subscription = drawer?.data as ClientExtendedSubscription;

	const [apiKey, setApiKey] = useState({ value: '', visible: false });
	const [isCopied, setIsCopied] = useState(false);

	let timerId: NodeJS.Timeout;

	const [form] = Form.useForm();
	const { mutate, isError, isSuccess, error, isLoading } =
		useUpdateCustomerSubscription();

	const {
		data: newApiKeyData,
		isFetching: isNewKeyLoading,
		isSuccess: isNewKeySuccess,
		isError: isNewKeyError,
		error: newKeyError,
		refetch: refetchNewApiKey,
	} = useGetNewApiKey();

	const onClose = () => {
		form.resetFields();
		setApiKey((cur) => ({ ...cur, visible: false }));
		setIsCopied(false);
		closeDrawer();
	};

	const onCopyClick = () => {
		copyTextToClipboard(form.getFieldValue('api_key'));
		setIsCopied(true);
		message.info('API key copied');

		timerId = setTimeout(() => setIsCopied(false), 3000);
	};

	const onSaveClick = async () => {
		try {
			await form.validateFields();

			const { additional_videos_allowed, additional_videos_amount } =
				form.getFieldsValue();

			mutate({
				additional_videos_allowed,
				additional_videos_amount,
				subscription_id: subscription?.subscription_id!,
			});
		} catch (error) {
			console.log(error);
		}
	};

	const onChangeApiKeyClick = () => refetchNewApiKey();

	useEffect(() => {
		if (isSuccess) {
			message.success({ content: 'Saved' });
			onClose();
		}

		if (isError) {
			const getError = error as any;

			message.error({
				type: 'error',
				content: `Something went wrong: ${
					getError?.response?.data?.message ?? getError?.message
				}`,
			});
		}
	}, [isError, isSuccess]);

	useEffect(() => {
		if (isNewKeySuccess && newApiKeyData) {
			message.success({ content: 'API key changed', key: Date.now() });
			localStorage.setItem('sv_go_key', newApiKeyData?.data?.token);

			form.setFieldValue('api_key', newApiKeyData?.data?.token);
			setApiKey((cur) => ({ ...cur, value: newApiKeyData?.data?.token }));
			console.log('newApiKey token', newApiKeyData?.data?.token);
		}

		if (isNewKeyError) {
			const getError = newKeyError as any;

			message.error({
				type: 'error',
				content: `Something went wrong: ${
					getError?.response?.data?.message ?? getError?.message
				}`,
			});
		}
	}, [form, isNewKeyError, isNewKeySuccess, newApiKeyData]);

	useEffect(() => {
		if (drawer.isOpen && drawer.type === 'subscription-configuration') {
			form.setFieldsValue({
				api_key: drawer?.data?.api_key,
				additional_videos_amount: drawer?.data?.additional_videos_amount,
				additional_videos_allowed: drawer?.data?.additional_videos_allowed,
			});
			setApiKey((cur) => ({ ...cur, value: drawer?.data?.api_key }));
		}
	}, [drawer]);

	useEffect(() => {
		return () => {
			clearTimeout(timerId);
		};
	}, []);

	return (
		<Drawer
			open={drawer.isOpen && drawer.type === 'subscription-configuration'}
			width={'650px'}
			onClose={onClose}
			title="Modify Plan"
		>
			<Col span={24}>
				<Flex
					justify="end"
					align="center"
					gap={20}
					style={{ marginBottom: '1.25rem' }}
				>
					<Button onClick={onClose}>Cancel</Button>
					<Button type="primary" onClick={onSaveClick} loading={isLoading}>
						Save
					</Button>
				</Flex>
			</Col>

			<Form form={form} {...layout}>
				<Typography.Title level={4} style={{ textAlign: 'center' }}>
					{subscription?.subscription_name}
				</Typography.Title>
				<Form.Item
					name="additional_videos_allowed"
					valuePropName="checked"
					wrapperCol={{ offset: 0, span: 16 }}
					// shouldUpdate
				>
					<Checkbox>{`Allow additional video processing above ${subscription?.additional_videos_amount} allowance`}</Checkbox>
				</Form.Item>

				<Form.Item
					label="Additional videos allowed"
					name="additional_videos_amount"
					wrapperCol={{ offset: 0, span: 5 }}
					style={{ marginBottom: '0.125rem' }}
				>
					<InputNumber style={{ width: '100%' }} min={0} max={10000} />
				</Form.Item>
				<p style={{ fontStyle: 'italic', marginTop: 0, paddingLeft: '175px' }}>
					Additional charge per additional individual video:{' '}
					{subscription?.top_up_eur}
				</p>
				{/* Tags */}
				<Col style={{ position: 'relative' }}>
					<Form.Item
						label="API access token"
						name="api_key"
						wrapperCol={{ offset: 0, span: 13 }}
					>
						<Flex style={{ position: 'relative' }}>
							<TextArea
								rows={6}
								className={styles.tokenTextArea}
								readOnly
								value={
									apiKey?.visible ? (apiKey.value as string) : hiddenString
								}
							/>
							<div className={styles.buttonsWrapper}>
								<Tooltip title={apiKey?.visible ? 'Hide' : 'Reveal'}>
									<Button
										icon={
											apiKey?.visible ? (
												<EyeInvisibleOutlined />
											) : (
												<EyeOutlined />
											)
										}
										onClick={() =>
											setApiKey((cur) => ({ ...cur, visible: !cur.visible }))
										}
										disabled={!apiKey.value}
									/>
								</Tooltip>
								<Tooltip title="Copy">
									<Button
										icon={isCopied ? <CheckOutlined /> : <CopyOutlined />}
										onClick={onCopyClick}
										disabled={!apiKey.value}
									/>
								</Tooltip>
							</div>
						</Flex>
					</Form.Item>

					<Button
						type="link"
						style={{ position: 'absolute', right: 0, top: 0 }}
						onClick={onChangeApiKeyClick}
						loading={isNewKeyLoading}
					>
						{!apiKey.value ? 'Get token' : 'Change'}
					</Button>
				</Col>

				<a href="#" target="_blank">
					API documentation
				</a>

				<p className={styles.listTitle}>Video insight actions included:</p>
				<ul style={{ marginTop: 0 }}>
					{VIDEO_INSIGHTS_ACTIONS.map((item, idx) => (
						<li key={item?.slice(0, 3) + idx}>{item}</li>
					))}
				</ul>
			</Form>
		</Drawer>
	);
}
