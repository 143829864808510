import React from 'react';

import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';

import { UserContext } from '@/App';
import image from '@/lib/assets/trust-my-voice.svg';

import style from './style.module.css';

interface IProps {
	children?: React.ReactNode;
}

const OnBoardingLayout = (props: IProps) => {
	const navigate = useNavigate();
	const path = useLocation();
	const { user } = React.useContext(UserContext);

	if (user && user.cid !== null) {
		navigate('/app/dashboard/creators', { replace: true });
		return <></>;
	}

	return (
		<div className={style.view}>
			<div></div>
			<div>
				<div className={style.form}>
					<Outlet context={{ x: 1 }} />
				</div>
			</div>
			<div className={style.trust}>
				{/* <img src={image} alt="" style={{ height: '500px', width: '500px' }} /> */}
			</div>
		</div>
	);
};

export default OnBoardingLayout;
