import { useEffect } from 'react';

import { useLocation } from 'react-router-dom';

const AVOID_PATHS = ['/auth/login', '/auth/register', '/auth/reset-password'];

function usePreviousPageTracker() {
	const location = useLocation();

	useEffect(() => {
		if (!AVOID_PATHS.includes(location.pathname)) {
			localStorage.setItem('previousPage', location.pathname);
		}
	}, [location]);
}

export default usePreviousPageTracker;
