import React, { useContext, useEffect } from 'react';

import {
	AuditOutlined,
	BankOutlined,
	CheckSquareOutlined,
	CreditCardOutlined,
	FileTextOutlined,
	FolderOpenOutlined,
	HomeOutlined,
	LogoutOutlined,
	ProfileOutlined,
	SearchOutlined,
	SettingOutlined,
	UserOutlined,
	UsergroupAddOutlined,
} from '@ant-design/icons';
import { Button, Dropdown, Typography } from 'antd';
import { Link } from 'react-router-dom';

import { SubscriptionContext } from '@/App';
import { CreditsCounter } from '@/layouts/subscription/CreditsCounter';
import { totalAvailableReports } from '@/layouts/subscription/helper';
import { useSubscription } from '@/layouts/subscription/useSubscription';
// Import your Coming Soon component
import client from '@/lib/http/client';
import { UserProfieDrawer } from '@/pages/app/settings/users/UserProfieDrawer';
import { PageContext } from '@/providers/PageContextProvider';

import style from './style.module.css';

import gradient from './gradient.svg';
import logo from './logo.png';

//SHUBHAM

const Navbar = ({
	isAdmin,
	setProfileDrawlerVisible,
}: {
	isAdmin: boolean;
	setProfileDrawlerVisible: (state: boolean) => void;
}) => {
	/*SHUBHAM*/
	const { subscribe, repotsCount } = useContext(SubscriptionContext);
	const { pageTitle } = useContext(PageContext);

	const logout = async () => {
		// Send a request to the logout endpoint
		//await fetch('/auth/logout', { method: 'POST' });
		await client.get('/auth/logout');

		// Clear the user's session or authentication token
		localStorage.removeItem('token');

		// Redirect the user to the login page
		window.location.href = '/auth/login';
	};

	// const { isSubscriptionsValid, subscription, isLoading } = useSubscription();
	const [totalReports, setTotalReports] = React.useState(0);
	// useEffect(() => {
	// 	if (subscribe) {
	// 		setTotalReports(totalAvailableReports(subscribe));
	// 	}
	// }, [subscribe]);
	/*SHUBHAM*/
	return (
		<nav className={style.navbar}>
			<div className={style.layout}>
				<div className={style.left}>
					<div className={style.logo}>
						<img src={logo} alt="" />
					</div>

					<div className={style.menu}>
						<Button
							style={{ padding: '5px', color: 'black' }}
							href="/app/dashboard/product-main-menu"
							type="link"
							// type="ghost"
							// href="/app/dashboard"
						>
							<HomeOutlined className={style.icon} />
						</Button>

						{/* <FolderOpenOutlined className={style.icon} /> */}
					</div>

					{pageTitle && <h3 className={style.pageTitle}>{pageTitle}</h3>}
				</div>

				<div className={style.right}>
					<div className={style.menu}>
						{subscribe && <CreditsCounter count={repotsCount} />}
						{isAdmin ? (
							<Dropdown
								trigger={['click']}
								placement="bottomRight"
								menu={{
									items: [
										{
											label: (
												<Link to={'/app/dashboard/users'}>manage users</Link>
											),
											key: 'manage users',
											icon: <UsergroupAddOutlined />,
										},
										{
											label: (
												<Link to={'/app/dashboard/activity'}>
													activity history
												</Link>
											),
											key: 'activity history',
											icon: <AuditOutlined />,
										},
										{
											label: (
												<Link to={'/app/dashboard/organisation'}>
													organisation details
												</Link>
											),
											key: 'organisation details',
											icon: <BankOutlined />,
										},
										{
											label: (
												<Link to={'/app/dashboard/subscription'}>
													subscription settings
												</Link>
											),
											key: 'subscription settings',
											icon: <CheckSquareOutlined />,
										},
										{
											label: <Link to={'/app/dashboard/billing'}>billing</Link>,
											key: 'billing',
											icon: <CreditCardOutlined />,
										},
									],
								}}
							>
								<SettingOutlined className={style.icon} />
							</Dropdown>
						) : null}
						{/*<UserOutlined className={style.icon} />*/}
						{/*SHUBHAM*/}
						<Dropdown
							trigger={['click']}
							placement="bottomRight"
							menu={{
								items: [
									// {
									// 	label: (
									// 		<Link to={'/app/dashboard/profile-settings'}>Settings</Link>
									// 	),
									// 	key: 'manage users',
									// 	icon: <SettingOutlined />,
									// },
									{
										label: 'Log Out',
										onClick: () => logout(),
										key: 'log out',
										icon: <LogoutOutlined />,
									},
									// TODO: profile
									// {
									// 	label: <div>Profile</div>,
									// 	key: 'profile',
									// 	icon: <ProfileOutlined />,
									// 	onClick: () => {
									// 		setProfileDrawlerVisible(true);
									// 	},
									// },
								],
							}}
						>
							<UserOutlined className={style.icon} />
						</Dropdown>

						{/*SHUBHAM*/}
					</div>
				</div>
			</div>

			<div className={style.gradient}>
				<img src={gradient} alt="" />
			</div>
		</nav>
	);
};

export default Navbar;
