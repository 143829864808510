import { Flex, Tooltip, Typography } from 'antd';

import prepareDate from '@/lib/helpers/prepareDate';
import timeFromNow from '@/lib/helpers/timeFromNow';

import styles from './styles.module.css';

type TProps = {
	video: {
		Title: string;
		Thumbnail: string;
		Url: string;
		CreationDate: string;
		Views: string;
	};
};

export default function VideoCard({ video }: TProps) {
	return (
		<li className={styles.listItem}>
			<a href={video?.Url} className={styles.videoLink}>
				<Flex vertical align="flex-start" gap={2}>
					{video?.Thumbnail && (
						<div className={styles.thumbnailWrapper}>
							<img
								src={video?.Thumbnail}
								alt={video?.Title ?? 'video image'}
								className={styles.thumbnail}
							/>
						</div>
					)}

					{video?.Title && (
						<Typography.Text className={styles.title}>
							{video?.Title}
						</Typography.Text>
					)}

					{video?.Views && (
						<Flex>
							<Typography.Text className={styles.metaText}>
								{video?.Views} views
							</Typography.Text>
							{video?.CreationDate && (
								<Tooltip title={prepareDate(video?.CreationDate)}>
									<Typography.Text className={styles.metaText}>
										&nbsp;&nbsp;•&nbsp;&nbsp;
										{timeFromNow(new Date(video?.CreationDate))}
									</Typography.Text>
								</Tooltip>
							)}
						</Flex>
					)}
				</Flex>
			</a>
		</li>
	);
}
