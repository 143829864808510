import React from 'react';

import { CheckCircleOutlined } from '@ant-design/icons';
import { Button, Card, Form } from 'antd';
import { useNavigate } from 'react-router-dom';

import style from './style.module.css';

import { subsriptionIcons } from './SubscriptionIcons';
import { subscription } from './subscriptionType';

type SubscriptionCartFormItemProps = {
	subscription: subscription; // replace Subscription with the actual type
	index: number;
	subscribe: boolean;
	subscriptionId: number;
};

const SubscriptionCartFormItem: React.FC<SubscriptionCartFormItemProps> = ({
	subscription,
	index,
	subscribe,
	subscriptionId,
}) => {
	const navigate = useNavigate();

	const onFinish = (subscription: subscription) => {
		navigate('/app/subscribe', { state: { subscription } });
		// Here you can send the subscriptionId to the backend
	};
	const Id = Number(subscription.id);

	const IconComponent = subsriptionIcons[subscriptionId]
		? subsriptionIcons[subscriptionId]
		: subsriptionIcons[1];

	return (
		<div
			key={index}
			style={{
				transform: 'scale(0.9)',
				flex: '1 1 0',
				maxWidth: '350px',
				width: '100%',
			}}
		>
			<Card
				title={subscription.subscription_name}
				bordered={false}
				className={style.andtCard}
			>
				<Form
					name={`subscription_form_${index}`}
					onFinish={(values) => onFinish(subscription)}
					className={style.andtForm}
				>
					<div>
						<IconComponent />
					</div>
					<p>{subscription.description}</p>
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
						}}
					>
						<span style={{ fontSize: '1.9rem' }}>
							$ {subscription.per_month_eur}
						</span>
						<p
							style={{
								textAlign: 'left',
								display: 'inlineBlock',
								maxWidth: 'min-content',
								fontSize: '0.9rem',

								marginLeft: '8px',
								lineHeight: 'normal',
							}}
						>
							per month
						</p>
					</div>
					<div style={{}}>
						{subscribe && (
							<Form.Item>
								<Button
									type="primary"
									htmlType="submit"
									size="large"
									className={style.subscribeButton}
									style={{ width: '100%' }}
								>
									Subscribe
								</Button>
							</Form.Item>
						)}
						<ul
							style={{
								textAlign: 'left',
								fontSize: '0.9rem',
								listStyleType: 'none',
								paddingLeft: '12px',
								marginTop: '34px',
								marginBottom: '10px',
							}}
						>
							<li key={subscription?.id + index} className={style.includes}>
								<CheckCircleOutlined className={style.checkicon} />
								<p style={{ margin: 'unset' }}>
									Reports per month: {subscription.item_count_per_month}
								</p>
							</li>
							{subscription.item_count_per_month !== 1 && (
								<li
									key={subscription?.id + index + 'item_count_per_month'}
									className={style.includes}
								>
									<CheckCircleOutlined className={style.checkicon} />
									<p style={{ margin: 'unset' }}>
										Cost per report: $
										{(
											subscription.per_month_eur /
											subscription.item_count_per_month
										).toFixed(2)}
									</p>
								</li>
							)}
							{subscription.top_up_eur !== null && (
								<li
									key={subscription?.id + index + 'top_up_eur'}
									className={style.includes}
								>
									<CheckCircleOutlined className={style.checkicon} />
									<p style={{ margin: 'unset' }}>
										Additional Top-up (per report): $ {subscription.top_up_eur}
									</p>
								</li>
							)}
							{/* {subscription.features.map((feature, index) => (
                    <li key={index}>
                        <CheckCircleOutlined style={{ marginRight: '10px' }} />
                        {feature}
                    </li>
                ))} */}
						</ul>
					</div>
				</Form>
			</Card>
		</div>
	);
};

export default SubscriptionCartFormItem;
