import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';

import Search from 'antd/es/input/Search';
import debounce from 'lodash.debounce';

const EMPTY_STRING = '';
const MAX_SEARCH_FILTER_DEBOUNCE_INTERVAL = 1000;

interface SearchFilterProps {
	value?: string;
	onChange: (value: string) => void;
	placeholder: string;
}
// eslint-disable-next-line max-lines-per-function
const SearchFilter: React.FC<SearchFilterProps> = ({
	value,
	onChange,
	placeholder,
}) => {
	const [internalValue, setInternalValue] = useState(value);

	const [loading, setLoading] = useState(false);

	useEffect(() => {
		setInternalValue(value);
	}, [value]);

	const handleDebouncedChange = useCallback(
		debounce(async (newValue: string) => {
			setLoading(true);
			await onChange(newValue);
			setLoading(false);
		}, MAX_SEARCH_FILTER_DEBOUNCE_INTERVAL),
		[onChange],
	);
	const handleInternalValueChange = useCallback(
		(event: ChangeEvent<HTMLInputElement>) => {
			setInternalValue(event.target.value);
			handleDebouncedChange(event.target.value);
		},
		[setInternalValue, handleDebouncedChange],
	);

	return (
		<Search
			key="search"
			// style={{ margin: '20px 0 40px' }}
			placeholder={placeholder}
			enterButton="Search"
			size="large"
			onChange={handleInternalValueChange}
			onSearch={onChange}
			value={internalValue}
			loading={loading}
			disabled={loading}
		/>
	);
};

export default SearchFilter;
