import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios, { AxiosRequestConfig } from 'axios';

const svGoApiClient = axios.create({
	// withCredentials: true,
	baseURL: process.env.REACT_APP_SV_GO_API_URL
		? process.env.REACT_APP_SV_GO_API_URL + '/v1'
		: 'http://localhost:3010/v1',
	timeout: 30000, // 30 seconds timeout,
	// headers: {
	// 	Authorization: `Bearer ${process.env.REACT_APP_TEMP_API_KEY}`,
	// },
});

const preparedApiUrl = process.env.REACT_APP_API_URL + '/v1';

const svCopilotApiClient = axios.create({
	baseURL: preparedApiUrl,
	// 'https://dev.socialvoice.ai/v1/v1',
	timeout: 30000, // 30 seconds timeout,
});

svGoApiClient.interceptors.request.use((config) => {
	try {
		const accessToken = localStorage?.getItem('sv_go_key');

		const parsedToken: string | null = accessToken ? accessToken : '';

		if (parsedToken) {
			config!.headers!.Authorization = `Bearer ${parsedToken}`;
		}
	} catch (error) {
		console.log('axios request interceptor: ', error);
	}

	return config;
});

svCopilotApiClient.interceptors.request.use((config) => {
	try {
		const accessToken = localStorage?.getItem('sv_go_key');

		const parsedToken: string | null = accessToken ? accessToken : '';

		if (parsedToken) {
			config!.headers!.Authorization = `Bearer ${parsedToken}`;
		}
	} catch (error) {
		console.log('axios request interceptor: ', error);
	}

	return config;
});

interface IOptions {
	page?: number;
	limit?: number;
}

interface ICopilotVideosOptions extends IOptions {
	onlyUser?: boolean;
	userId?: number;
	sortBy?: 'dateProcessed';
	sortOrder?: 'asc' | 'desc';
}

interface IBatchProcess {
	collectionName?: string;
	actions: string[];
	tags?: string[];
	urls: string[];
	limit?: number;
}

function getAllProcesses({ page = 1, limit = 10 }: IOptions) {
	return svGoApiClient.get<any>(
		`/process?page=${page}&limit=${limit}&type=video`,
	);
}

function getAllCopilotVideos({
	page = 1,
	limit = 10,
	onlyUser = false,
	userId,
}: ICopilotVideosOptions) {
	const queryParams = onlyUser
		? `page=${page}&limit=${limit}&onlyUser=true&userId=${userId}`
		: `page=${page}&limit=${limit}`;

	return svCopilotApiClient.get<any>(`/video/copilot-posts?${queryParams}`);
}

function getNewApiKey() {
	return svGoApiClient.get<any>(`/gen-api-key`);
}

function getProcessesById(id: string) {
	return svGoApiClient.get<any>(`/process/${id}`);
}

function createBatchProcess(batchProcess: IBatchProcess) {
	return svGoApiClient.post<any>(`/video`, batchProcess as AxiosRequestConfig);
}
export async function downloadBatchJsonData(id: string) {
	return await svGoApiClient.get(`/process/${id}/content/json`, {
		responseType: 'blob',
	});
}

export function useGetAllCustomerProcesses(
	{ page = 1, limit = 10 }: IOptions,
	isRefreshEnabled: boolean = false,
) {
	return useQuery(
		['processes', page, limit],
		() =>
			getAllProcesses({
				page,
				limit,
			}),
		{
			keepPreviousData: false,
			refetchOnWindowFocus: false,
			staleTime: 1000 * 60 * 5,
			refetchInterval: isRefreshEnabled ? 1000 * 10 : false,
		},
	);
}

export function useGetAllCopilotVideos(
	options: ICopilotVideosOptions,
	isRefreshEnabled: boolean = false,
) {
	return useQuery(['copilot-videos'], () => getAllCopilotVideos(options), {
		keepPreviousData: false,
		refetchOnWindowFocus: false,
		staleTime: 1000 * 60 * 5,
		refetchInterval: isRefreshEnabled ? 1000 * 10 : false,
	});
}

export function useGetBatchProcessesById(id: string) {
	return useQuery(['processes', id], () => getProcessesById(id), {
		keepPreviousData: true,
		refetchOnWindowFocus: false,
		staleTime: 1000 * 60 * 5,
		enabled: typeof id !== 'undefined',
	});
}

export function useCreateBatchProcess() {
	const queryClient = useQueryClient();

	return useMutation(createBatchProcess, {
		onSuccess: () => {
			queryClient.invalidateQueries();
		},
	});
}

export function useDownloadBatchProcessJson(id: string) {
	return useQuery(['batchJsonData', id], () => downloadBatchJsonData(id), {
		keepPreviousData: true,
		refetchOnWindowFocus: false,
		enabled: typeof id !== 'undefined',
	});
}

export function useGetNewApiKey() {
	return useQuery(['api-key'], () => getNewApiKey(), {
		refetchOnWindowFocus: false,
		enabled: false,
	});
}
