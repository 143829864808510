import React from 'react';

import { Empty } from 'antd';
import { Link } from 'react-router-dom';

const UnauthorizedView: React.FC = () => {
	return (
		<div>
			<h1>Unauthorized</h1>
			<p>You are not authorized to view this page.</p>
			<Empty description="No Access" />
			<Link to="/app/dashboard/product-main-menu">Return to Dashboard</Link>
		</div>
	);
};

export default UnauthorizedView;
