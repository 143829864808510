import { useContext, useEffect, useState } from 'react';

import { BookOutlined, ReloadOutlined } from '@ant-design/icons';
import {
	Alert,
	Button,
	Checkbox,
	Flex,
	Space,
	Spin,
	Table,
	Tooltip,
	message,
} from 'antd';

import { useGetAllCopilotVideos } from '@/api/batch_process';
import useDrawer from '@/hooks/useDrawer';
import { PageContext } from '@/providers/PageContextProvider';

import style from './style.module.css';

import { IPagination } from '../video-intelligence-on-demand';
import { columns, mockData } from './data';

export default function VideoInsightsCoPilot() {
	// const [isLoading, setIsLoading] = useState(true);
	const { setPageTitle, openDrawer, setIsCopilotCreditsCounterVisible } =
		useContext(PageContext);
	const [pagination, setPagination] = useState<IPagination>({
		page: 1,
		pageCount: 1,
		total: 0,
		limit: 10,
	});
	const [filterUserInsights, setFilterUserInsights] = useState(true);

	const { data, isLoading, isFetching, refetch } = useGetAllCopilotVideos({
		page: pagination.page,
		limit: pagination.limit,
		onlyUser: filterUserInsights,
		userId: 1,
	});

	const total = data?.data?.data?.length ?? 1;
	const items = data?.data?.data ?? [];

	// const total = mockData?.length;
	// const items = mockData;

	useEffect(() => {
		setPageTitle('Video insights co-pilot');
		setIsCopilotCreditsCounterVisible(true);

		// setTimeout(() => setIsLoading(false), 500);
		return () => {
			setPageTitle('');
			setIsCopilotCreditsCounterVisible(false);
		};
	}, []);

	useEffect(() => {
		refetch();
	}, [filterUserInsights]);

	return (
		<div>
			{/* <h2>Comming soon!</h2> */}

			<div>
				<Flex
					align="center"
					justify="space-between"
					style={{ marginBottom: '1.5rem' }}
				>
					<h2>Copilot videos history</h2>

					<Space>
						<Tooltip title="Refresh">
							<Button onClick={() => refetch()} icon={<ReloadOutlined />} />
						</Tooltip>
					</Space>
				</Flex>

				<Flex vertical gap={16} style={{ width: '100%' }}>
					<Checkbox
						checked={filterUserInsights}
						onChange={() => setFilterUserInsights((prev) => !prev)}
					>
						My Co-pilot insights
					</Checkbox>

					<Table
						loading={isLoading || isFetching}
						style={{ width: '100%' }}
						// onChange={onTableChange}
						dataSource={items}
						// dataSource={mockData}
						columns={[
							...columns,
							{
								title: 'Actions',
								dataIndex: 'actions',
								key: 'actions',
								width: 90,
								flex: true,
								render: (_: any, record: any) => {
									return (
										<Tooltip title="View details">
											<Button
												onClick={() =>
													openDrawer('view-copilot-video-details', record)
												}
												icon={<BookOutlined />}
											/>
										</Tooltip>
									);
								},
							},
						]}
						rowKey={'id'}
						bordered
						scroll={{ x: 1100 }}
						pagination={{
							total,
							showSizeChanger: true,
							current: pagination.page,
							pageSizeOptions: [10, 25, 50],
							onChange: (page: number, pageSize: number) => {
								setPagination((cur) => ({ ...cur, page, limit: pageSize }));
							},
						}}
					/>
				</Flex>
			</div>
		</div>
	);
}
