import axios from 'axios';

const client = axios.create({
	withCredentials: true,
	// baseURL: 'http://localhost:3006/v1',
	baseURL: process.env.REACT_APP_API_URL + '/v1' || 'http://localhost:3006/v1',
	timeout: 30000, // 30 seconds timeout
});

client.interceptors.response.use(
	(res) => {
		return res;
	},

	async (error) => {
		const prevRequest = error.config;
		if (error.response.status === 401 && !prevRequest.sent) {
			prevRequest.sent = true;
			try {
				await client.post('/auth/refresh');
				return client(prevRequest);
			} catch (error) {
				await client.get('/auth/logout');
				window.location.href = '/auth/login';

				return Promise.reject(error);
			}
		}
		return Promise.reject(error);
	},
);

client.interceptors.response.use(
	(response) => {
		return Promise.resolve(response);
	},
	(error) => {
		if (
			error.response.status === 401
			// || error.response.status === 403
		) {
			window.location.href = '/auth/login';
			return Promise.reject(error);
		}
		return Promise.reject(error);
	},
);

export async function logout() {
	const { data } = await client.get('/auth/logout/idle');
	if (data.logout) {
		window.location.href = '/auth/login';
	}
}

export default client;
