import React from 'react';

import { Result } from 'antd';

interface IProps {
	loading: boolean;
	children: React.ReactNode;
}

const Loader = ({ loading, children }: IProps) => {
	if (loading) {
		return (
			<div
				style={{
					width: '100%',
					height: '100%',
					minHeight: '100vh',
					display: 'flex',
					flexDirection: 'column',

					justifyContent: 'center',
					alignItems: 'center',
				}}
			>
				<Result
					status="404"
					title="Checking details"
					subTitle="Wait few seconds"
					// extra={<Button type="primary">Back Home</Button>}
				/>
			</div>
		);
	}

	return <>{children}</>;
};

export default Loader;
