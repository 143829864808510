import React from 'react';

import { EyeOutlined, FundViewOutlined, HeartTwoTone } from '@ant-design/icons';
import { useQuery } from '@tanstack/react-query';
import { Card, Popover } from 'antd';
import { useInView } from 'react-intersection-observer';

import { formatNumber } from '@/lib/helpers/numFormat';

import ls from './style.module.css';

import { IReportDataCreatorVideos } from '../report.types';
import placeholder from './placeholder.png';

interface IProps {
	id: string;
	videos?: IReportDataCreatorVideos;
}

const ReportPlatformVideos = (props: IProps) => {
	// const { ref, inView, entry } = useInView();
	// const [fetched, setFetched] = React.useState(false);

	// const headlines = useQuery({
	// 	queryKey: ['report-headlines', props.id],
	// 	queryFn: () => {
	// 		setFetched(true);
	// 		return fetchCreatorReport(props.id, ['headlines', '_id']);
	// 	},
	// 	enabled: !!props.id && inView && !fetched,
	// });
	const customOrder = ['youtube', 'instagram', 'tiktok'];
	const validurl = (url: string) => {
		if (url.includes('undefined') || url.includes('https') || !url) {
			return false;
		}
		return true;
	};

	return (
		<div>
			{Object.entries(props.videos || {})
				.sort(([keyA, valueA], [keyB, valueB]) => {
					const platformA = keyA?.toLowerCase();
					const platformB = keyB?.toLowerCase();
					return (
						customOrder.indexOf(platformA) - customOrder.indexOf(platformB)
					);
				})
				.filter(([key, value]) => Object.keys(value).length > 0)
				.map(([key, value]) => {
					return (
						<Card
							title={key?.toLowerCase() === 'youtube' ? 'YouTube' : key}
							// loading={headlines.isLoading}
							style={{ marginBottom: '20px' }}
							key={key}
						>
							<div
								style={{
									display: 'grid',
									gridTemplateColumns:
										'repeat(auto-fill, minmax(min(10rem, 100%), 1fr))',
									columnGap: '10px',
									rowGap: '10px',
								}}
							>
								{value.map((x: any, idx: number) => (
									<div key={x.thumbnail + idx}>
										<a href={x.url} target="_blank" rel="noreferrer">
											<div className={ls.videoWrapper}>
												{validurl(x.thumbnail) ? (
													<img
														src={`${process.env.REACT_APP_CDN_STORAGE}${x.thumbnail}`}
														style={{ width: '100%', height: 'auto' }}
														alt={(x.title as string).slice(0, 35)}
													/>
												) : (
													<img
														src={placeholder}
														style={{ width: '100%', height: '100%' }}
														alt={(x.title as string).slice(0, 35)}
														crossOrigin="anonymous"
													/>
												)}

												<div className={ls.videoInfo}>
													<span className={ls.title}>
														<Popover
															content={
																<div
																	style={{
																		whiteSpace: 'pre-wrap',
																		maxWidth: '300px',
																	}}
																>
																	{x.title}
																</div>
															}
															trigger="hover"
														>
															{x.title.substring(0, 25) + '...'}
														</Popover>
													</span>
													<div className={ls.videoStats}>
														<div>
															<EyeOutlined /> {formatNumber(x.views)}
														</div>
														<div>
															<HeartTwoTone twoToneColor="#eb2f96" />{' '}
															{formatNumber(x.likes)}
														</div>
													</div>
												</div>
											</div>
										</a>
									</div>
								))}
							</div>
						</Card>
					);
				})}
		</div>
	);
};

export default ReportPlatformVideos;
