import React from 'react';

import { Skeleton, Space } from 'antd';

export const FormSkeleton = () => {
	return (
		<Space
			style={{
				maxWidth: '350px',
				display: 'flex',
				flexDirection: 'column',
			}}
		>
			<Skeleton.Input
				active={true}
				size={'large'}
				style={{ width: '350px', marginTop: '20px' }}
			/>
			<Skeleton.Input
				active={true}
				size={'large'}
				style={{ width: '350px', marginTop: '20px' }}
			/>
			<Skeleton.Input
				active={true}
				size={'large'}
				style={{ width: '350px', marginTop: '20px' }}
			/>
			<Skeleton.Input
				active={true}
				size={'large'}
				style={{ width: '350px', marginTop: '20px' }}
			/>
		</Space>
	);
};
