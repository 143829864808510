export interface IProps {
	data: any;
}

export function sortVerticalGraf(data: any[], value: string, key: string) {
	const filteredData = data.filter((item) => item.value !== 0);

	return filteredData.sort((a: any, b: any) => {
		if (a[key] === value) return -1;
		if (b[key] === value) return 1;
		return a.value - b.value;
	});
}

export function sortVerticalGrafDescription(
	data: any[],
	key: string,
	props: IProps,
) {
	let descriptionsArray: any[] = [];
	data.forEach((x: any) => {
		const typeName = x.type;
		if (typeName === key) return;
		descriptionsArray.push({
			[typeName]: props.data.personalityTypeChart.personalityTypeText[typeName],
		});
	});
	return descriptionsArray.reverse();
}
