import cn from "classnames";

import styles from "./styles.module.css";

interface IProps {
  text: string;
  searchTerm: string;
  className?: string; // Optional: CSS class for highlighted text
}

export default function TranscriptionText({
  text,
  searchTerm,
  className,
}: IProps) {
  if (!text) return null;

  if (!searchTerm) return <span>{text}</span>; // If no search term, return original text

  // Create a regular expression to find all occurrences of the search term (case insensitive)
  const regex = new RegExp(`(${searchTerm})`, "gi");

  // Split text by the search term and wrap matches in a span with a highlight class
  const parts = text.split(regex);

  return (
    <span>
      {parts.map((part, index) =>
        part.toLowerCase() === searchTerm.toLowerCase() ? ( // Check if the part is the search term
          <span key={index} className={cn(styles.highlight, className)}>
            {part}
          </span>
        ) : (
          part // Render non-matching parts as normal text
        )
      )}
    </span>
  );
}
