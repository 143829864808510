import ReactPlayer from 'react-player';

import { CDN_URL } from '@/const';
import prepareImageSrc from '@/lib/helpers/prepareImageSrc';

export default function VideoPlayer({ url = '', preview = '' }) {
	if (preview?.length > 0) {
		return (
			<ReactPlayer
				url={url}
				controls
				config={{
					file: {
						attributes: {
							controlsList: 'nofullscreen',
						},
					},
				}}
				light={
					<img
						src={
							preview?.startsWith('http')
								? preview
								: CDN_URL + prepareImageSrc(preview)
						}
						alt="Video thumbnail"
					/>
				}
				// width={300}
				// height={224}
			/>
		);
	}

	return <ReactPlayer url={url} controls />;
}
