import { useState } from 'react';

import { useQuery } from '@tanstack/react-query';
import {
	Button,
	Card,
	Col,
	Descriptions,
	Drawer,
	Form,
	Input,
	InputNumber,
	Radio,
	Row,
	Space,
} from 'antd';
import Title from 'antd/es/skeleton/Title';

import client from '@/lib/http/client';

function OrganizationInfo() {
	const [isEditMode, setIsEditMode] = useState(false);
	const [orgInfo, setOrgInfo] = useState<any>({
		organisation_name: 'My Organization',
		size: 'Medium',
		employee_count: 50,
		offices_count: 1,
		tax_id: '123-456-789',
		address_1: '123 Main St',
		phone: '123-456-7890',
		billing_email_address: 'info@myorg.com',
	});

	const reports = useQuery({
		queryKey: ['customer'],
		queryFn: async () => {
			const response = await client.get<any>(`/customers`);
			setOrgInfo({ ...response.data });

			return response.data;
		},

		// enabled: search.length > 0,
	});

	const onFinish = (values: any) => {
		setOrgInfo(values);
		setIsEditMode(false);
	};
	const handleCancel = () => {
		setIsEditMode(false);
		form.resetFields();
	};
	const [form] = Form.useForm();
	return (
		<Card
			title={
				<div
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
					}}
				>
					<h4>Organization Information</h4>
					{/* <Button type="primary" onClick={() => setIsEditMode(true)}>
						Edit
					</Button> */}
				</div>
			}
		>
			<Descriptions bordered>
				<Descriptions.Item label="Company Name" span={3}>
					{orgInfo.organisation_name}
				</Descriptions.Item>

				<Descriptions.Item label="Number of Employees" span={1.5}>
					{orgInfo.employee_count}
				</Descriptions.Item>
				<Descriptions.Item label="Number of Offices" span={1.5}>
					{orgInfo.offices_count}
				</Descriptions.Item>
				<Descriptions.Item label="Tax ID" span={1.5}>
					{orgInfo.tax_id}
				</Descriptions.Item>
				{/* <Descriptions.Item label="Phone" span={1.5}>
					{orgInfo.phone}
				</Descriptions.Item> */}
				<Descriptions.Item label="Address" span={3}>
					<div>
						{orgInfo.address_1}, {orgInfo.city}, {orgInfo.country_name}
					</div>
				</Descriptions.Item>
				<Descriptions.Item label="Postcode" span={3}>
					{orgInfo.postcode}
				</Descriptions.Item>
				<Descriptions.Item label="Email" span={3}>
					{orgInfo.billing_email_address}
				</Descriptions.Item>
			</Descriptions>

			<Drawer
				title={
					<div
						style={{
							display: 'flex',
							justifyContent: 'space-between',
							alignItems: 'center',
						}}
					>
						<h4>Edit Organisation Details</h4>
						<div style={{ textAlign: 'right' }}>
							<Button
								style={{ border: 'none', boxShadow: 'none' }}
								onClick={() => {
									handleCancel();
								}}
							>
								<span style={{ textDecoration: 'underline' }}>Cancel</span>
							</Button>
							<Button
								type="primary"
								onClick={() => {
									form.submit();
								}}
								style={{ marginRight: 8 }}
							>
								Save
							</Button>
						</div>
					</div>
				}
				width={720}
				onClose={() => {
					setIsEditMode(false);
					form.resetFields();
				}}
				closeIcon={null}
				visible={isEditMode}
				bodyStyle={{ paddingBottom: 80 }}
			>
				<Form
					layout="vertical"
					form={form}
					initialValues={orgInfo}
					onFinish={onFinish}
				>
					<Form.Item label="Company Name" name="companyName">
						<Input />
					</Form.Item>
					<Row gutter={16}>
						<Col span={16}>
							<Form.Item label="Number of Employees" name="numberOfEmployees">
								<Radio.Group buttonStyle="solid">
									<Space direction="horizontal">
										<Radio.Button value="1-10">1-10</Radio.Button>
										<Radio.Button value="10-25">10-25</Radio.Button>
										<Radio.Button value="25-75">25-75</Radio.Button>
										<Radio.Button value="75+">75+</Radio.Button>
									</Space>
								</Radio.Group>
							</Form.Item>
						</Col>
						<Col span={8}>
							<Form.Item label="Number of Offices" name="numberOfOffices">
								<InputNumber />
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={16}>
						<Col span={8}>
							<Form.Item label="Tax ID" name="taxId">
								<Input />
							</Form.Item>
						</Col>
						<Col span={8}>
							<Form.Item label="Phone" name="phone">
								<Input />
							</Form.Item>
						</Col>
						<Col span={8}>
							<Form.Item label="Email" name="email">
								<Input />
							</Form.Item>
						</Col>
					</Row>
					<Form.Item label="Address" name="address">
						<Input />
					</Form.Item>
				</Form>
			</Drawer>
		</Card>
	);
}

export default OrganizationInfo;
