import React from 'react';

import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as UpdateSubscription } from './src/UpdateSubscription.svg';

interface Iprops {
	description: string;
	IconComponent: React.ComponentType;
}

const SubscribtionBanner = ({ description, IconComponent }: Iprops) => {
	const navigate = useNavigate();
	return (
		<div
			style={{
				marginTop: '100px',
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
			}}
		>
			<div
				style={{
					width: '100%',
					maxWidth: '300px',
				}}
			>
				<IconComponent />
			</div>
			<h2 style={{ textAlign: 'center' }}>{description}</h2>
			<Button
				type="primary"
				size="large"
				style={{ width: '80%', marginTop: '20px' }}
				onClick={() => navigate('/app/dashboard/subscription')}
			>
				Subscription Details
			</Button>
		</div>
	);
};

export default SubscribtionBanner;
