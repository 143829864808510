import { useState } from 'react';

import { Alert, Card, Radio } from 'antd';
import { Pagination } from 'antd';

import { ChartBarVertical } from '@/components/charts/bar';

import style from '../style.module.css';

import { InfoSection } from '../informationPopop';

interface IProps {
	data: any;
}

const ReportBrandMentions = (props: IProps) => {
	const [displayMode, setDisplayMode] = useState('top10');

	const [currentPage, setCurrentPage] = useState(1);
	const [pageSize, setPageSize] = useState(10);
	// const itemsPerPage = 10; // Change this to the number of items you want per page
	const paginatedData = props.data.brandMentionBarChart.slice(
		(currentPage - 1) * pageSize,
		currentPage * pageSize,
	);
	return (
		<div>
			<Card
				title=<div>
					Voice vs Metadata - Entity Mention Visibility
					<InfoSection
						title="Voice vs Metadata - summary"
						content="SocialVoice technology extracts mentions of brands both in the meta-data (tags, descriptions and titles) of a video, and also in the spoken word of the video. We then compare the data identified in the Metadata versus the spoken word, to reveal the previously hidden mentions of brands that are critically important to be aware of. The summary metrics give highlights that reveal the brand value that is being missed by not being able to analyse the spoken word."
					/>
					<br></br>
					<i style={{ fontSize: '14px', textDecoration: '', color: 'grey' }}>
						Brands, Organisations, etc.
					</i>
				</div>
				style={{ marginBottom: '50px' }}
			>
				<div className={style.cards}>
					<div className={style.card}>
						<div className={style.cardTitle}>Combined Total</div>
						<div className={style.cardContent}>
							<div>
								<div className={style.contentValue}>
									{props.data?.brandMentionSummary?.combinedTotal}
								</div>
							</div>
						</div>
					</div>
					<div className={style.card}>
						<div className={style.cardTitle}>Metadata</div>
						<div className={style.cardContent}>
							<div>
								<div className={style.contentValue}>
									{props.data.brandMentionSummary?.metaData?.total}
								</div>
							</div>
							<div>
								<div className={style.contentValue}>
									{props.data.brandMentionSummary?.metaData?.percentage}%
								</div>
							</div>
						</div>
					</div>
					<div className={style.card}>
						<div className={style.cardTitle}>Voice Mentions</div>
						<div className={style.cardContent}>
							<div>
								<div className={style.contentValue}>
									{props.data.brandMentionSummary?.notMetaData?.total}
								</div>
							</div>
							<div>
								<div className={style.contentValue}>
									{props.data.brandMentionSummary?.notMetaData?.percentage}%
								</div>
							</div>
						</div>
					</div>
				</div>
			</Card>

			<Card
				title=<div>
					Entity Visibility
					<InfoSection
						title="Entity Visibility"
						content="SocialVoice technology extracts mentions of brands both in the meta-data (tags, descriptions and titles) of a video, and also in the spoken word of the video. The visibility analysis highlights the top brands that were identified in the spoken word and compares their visibility compared to only searching in the metadata of the video itself."
					/>
				</div>
				style={{ marginBottom: '50px' }}
			>
				<div style={{ height: '600px' }}>
					<ChartBarVertical data={paginatedData} />
				</div>
				<Pagination
					showSizeChanger
					current={currentPage}
					total={props.data.brandMentionBarChart.length}
					pageSize={pageSize}
					onChange={(page) => setCurrentPage(page)}
					onShowSizeChange={(current, size) => {
						setCurrentPage(1);
						setPageSize(size);
					}}
				/>
			</Card>

			<Card
				title=<div>
					Most Recent Topics
					<InfoSection
						title="Most Recent Topics"
						content="The visualisation indicates the most important topic keywords mentioned in this content in the last 6 months. The larger the font size of the topic keyword, the more mentions it has had relative to the other topic keywords in the list."
					/>
				</div>
				style={{ marginBottom: '20px' }}
			>
				{props.data.topicWordCloudLast6Months ? (
					<div className={style.imageContainer}>
						<img
							style={{ width: '60%' }}
							src={props.data.topicWordCloudLast6Months}
							alt="image"
						/>
					</div>
				) : (
					<Alert
						message="Most Recent Topics"
						description="Missing data for most recent topics"
						type="warning"
						showIcon
					></Alert>
				)}
			</Card>
		</div>
	);
};

export default ReportBrandMentions;
