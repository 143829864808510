import { Dispatch } from 'react';

import { SearchOutlined } from '@ant-design/icons';
import { Flex, Input, Typography } from 'antd';

import style from '../styles.module.css';

import TranscriptionText from '../TranscriptionText';

interface TProps {
	data: any;
	search: string;
	setSearch: any;
}

export default function Transcript({ data, search, setSearch }: TProps) {
	return (
		<Flex vertical className={style.scrollableWrapper}>
			<Typography.Text style={{ textAlign: 'left' }}>
				{data?.video?.transcription?.length > 0 && (
					<Flex className={style.searchWrapper} gap={8} align="center">
						<Typography.Text style={{ width: 'max-content', minWidth: '50px' }}>
							Search
						</Typography.Text>
						<Input
							value={search}
							onChange={(e) => setSearch(e.target.value)}
							placeholder="Type to search"
						/>
						<SearchOutlined className={style.searchIcon} />
					</Flex>
				)}
				{data?.video?.transcription?.length > 0 ? (
					<TranscriptionText
						text={data?.video?.transcription}
						searchTerm={search}
					/>
				) : (
					'N/A'
				)}
			</Typography.Text>
		</Flex>
	);
}
