import { CheckCircleOutlined, SyncOutlined } from '@ant-design/icons';
import { Tag } from 'antd';

export default function ReportStatus({ status }: { status: string }) {
	switch (status) {
		case 'processing':
			return (
				<Tag icon={<SyncOutlined spin />} color="processing">
					{status}
				</Tag>
			);

		case 'ready':
			return (
				<Tag icon={<CheckCircleOutlined />} color="green">
					{status}
				</Tag>
			);

		case 'failed':
		case 'timeout':
			return (
				<Tag icon={<CheckCircleOutlined />} color="red">
					{status}
				</Tag>
			);

		case 'cancelled':
			return (
				<Tag icon={<CheckCircleOutlined />} color="volcano">
					{status}
				</Tag>
			);

		default:
			return null;
	}
}
