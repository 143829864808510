import { useEffect, useState } from 'react';

export type TDrawer =
	| 'add-new-batch-process'
	| 'view-batch-process-details'
	| 'view-copilot-video-details'
	| 'view-product-subscription'
	| 'subscription-configuration';

export interface IDrawerProps {
	isOpen: boolean;
	type: TDrawer | null;
	data: any;
}

const useDrawer = () => {
	const [drawer, setDrawer] = useState<IDrawerProps>({
		isOpen: false,
		type: null,
		data: null,
	});

	const openDrawer = (type: TDrawer, data?: any) => {
		setDrawer({
			isOpen: true,
			type,
			data,
		});
	};

	const closeDrawer = () => {
		setDrawer({
			isOpen: false,
			type: null,
			data: null,
		});
	};

	return { drawer, isOpen: drawer.isOpen, openDrawer, closeDrawer };
};

export default useDrawer;
