import { Reducer } from 'react';

import {
	ReportsAction,
	influencerResult,
	initialState,
	searchInfluencer,
	searchInfluencerResult,
} from '../@types';

const retportReducer: Reducer<initialState, ReportsAction> = (
	state: initialState,
	action,
) => {
	switch (action.type) {
		case 'GET_INFLUENCERS':
			return {
				...state,
				users: action.payload,
				loading: false,
			};
		case 'ADD_SEARCH_INFLUENCER':
			return {
				...state,
				searchInfluencers: [
					...state.searchInfluencers,
					action.payload as searchInfluencer,
				],
			};
		case 'UPDATE_SEARCH_INFLUENCER_COUNT':
			return {
				...state,
				searchInfluencersCounter: action.payload as unknown as number,
			};

		case 'UPDATE_INFLUENCER_SEARCH':
			return {
				...state,
				searchInfluencers: action.payload as Array<searchInfluencer>,
			};
		case 'UPDATE_INFLUENCER_SEARCH_ITEM':
			const updatedSearchInfluencers = state.searchInfluencers.map(
				(influencer, index) => {
					if (influencer.id === action.payload.id) {
						return {
							...influencer,
							[action.payload.name]: action.payload.value,
						};
					}
					return influencer;
				},
			);

			return {
				...state,
				searchInfluencers: updatedSearchInfluencers,
			};
		case 'ADD_SEARCH_RESULT_INFLUENCER':
			return {
				...state,
				searchInfluencersResults:
					action.payload as Array<searchInfluencerResult>,
			};
		case 'SET_LOADING':
			return {
				...state,
				loading: true,
			};

		case 'BUTTON_SEARCH_INFLUENCER_HANDLER':
			return {
				...state,
				activeAddInfluencer: action.payload as unknown as boolean,
			};
		default:
			return state;
	}
};

export default retportReducer;
