import { Flex, List, Typography } from 'antd';
import cn from 'classnames';

import { CDN_URL } from '@/const';
import placeholderImage from '@/lib/assets/placeholder.png';
import { formatNumber } from '@/lib/helpers/numFormat';
import prepareImageSrc from '@/lib/helpers/prepareImageSrc';

import style from '../styles.module.css';

import VideoCard from '../VideoCard';

type TProps = {
	data: any;
};

export default function CreatorInsights({ data }: TProps) {
	return (
		<Flex vertical gap={4} align="flex-start">
			<Flex gap={12} style={{ width: '100%', marginBottom: '1rem' }}>
				{data?.creator?.thumbnail ? (
					<img
						src={
							data?.creator?.thumbnail?.startsWith('http')
								? data?.creator?.thumbnail
								: CDN_URL + prepareImageSrc(data?.creator?.thumbnail)
						}
						alt={data?.creator?.name}
						className={style.thumbnail}
					/>
				) : (
					<img src={placeholderImage} width={130} height={124} />
				)}

				<Flex vertical gap={4} style={{ width: '100%' }}>
					<Typography.Text>
						<b>Name:</b> {data?.creator?.name}
					</Typography.Text>

					{data?.creator?.channelName && (
						<Typography.Text>
							<b>Channel:</b>{' '}
							<a href={data?.creator?.channelUrl} target="_blank">
								{data?.creator?.channelName}
							</a>
						</Typography.Text>
					)}

					{data?.creator?.followers && (
						<Typography.Text>
							<b>Followers:</b> {formatNumber(data?.creator?.followers ?? 0)}
						</Typography.Text>
					)}
				</Flex>
			</Flex>

			{data?.creator?.description && (
				<Typography.Text>
					<b>Description:</b> {data?.creator?.description}
				</Typography.Text>
			)}

			{data?.recentVideos?.length > 0 && (
				<>
					<Typography.Title level={5}>Recent videos</Typography.Title>

					<Flex gap={10} wrap component={'ul'} className={style.videosList}>
						{data?.recentVideos?.map((item: any, idx: number) => (
							<VideoCard key={item?.url + idx} video={item} />
						))}
					</Flex>
				</>
			)}
		</Flex>
	);
}
