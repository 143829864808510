import React from 'react';

import { ResponsiveSunburst } from '@nivo/sunburst';

interface IProps {
	data: any;
}

const ChartSunburst = (props: IProps) => {
	return (
		<ResponsiveSunburst
			// @ts-ignore
			data={props.data}
			margin={{ top: 10, right: 10, bottom: 10, left: 10 }}
			id="name"
			value="loc"
			arcLabel="id"
			cornerRadius={2}
			animate={true}
			isInteractive={true}
			borderColor="white"
			colors={{ scheme: 'pastel2' }}
			childColor={{
				from: 'color',
				modifiers: [['brighter', 0.1]],
			}}
			enableArcLabels={true}
			arcLabelsSkipAngle={15}
			arcLabelsTextColor={{
				from: 'color',
				modifiers: [['darker', 1.4]],
			}}
			{...props}
		/>
	);
};

export default ChartSunburst;
