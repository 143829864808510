import React from 'react';

import {
	CheckOutlined,
	CloseCircleOutlined,
	CloseOutlined,
	EditOutlined,
} from '@ant-design/icons';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
	Button,
	Collapse,
	Descriptions,
	Drawer,
	Form,
	Input,
	Space,
	Spin,
	Switch,
} from 'antd';
import axios from 'axios';

import client from '@/lib/http/client';

import { userProfileInterface } from './userProfileInterface';
import { UserResetPassword } from './userResetPassword';

type iProps = {
	id: number;
	visble: boolean;
	setProfileDrawlerVisible: (state: boolean) => void;
};

export const UserProfieDrawer: React.FC<iProps> = ({
	id,
	visble,
	setProfileDrawlerVisible,
}) => {
	const fetchUsers = (id: number) => {
		return client.get<userProfileInterface>(`/users/${id}`);
	};

	const { data, isLoading, refetch } = useQuery({
		queryKey: [`userprofile-${id}`],
		queryFn: () => fetchUsers(id),
	});
	const udateUser = (updateUser: any) => {
		return client.post(`users/user-update/${id}`, {
			...updateUser,
		});
	};
	const mutation = useMutation({
		mutationFn: udateUser,
		onError: (error) => {
			if (axios.isAxiosError(error)) {
				setValidateStatus('error');
				setErrorMessage(error.response?.data?.message);
			}
		},
		onSuccess: () => {
			setEdit(false);
			setValidateStatus(undefined);
			setErrorMessage(undefined);
		},
	});

	const [edit, setEdit] = React.useState(false);
	const [firstName, setFirstName] = React.useState(data?.data.first_name);
	const [lastName, setLastName] = React.useState(data?.data.last_name);
	const [email, setEmail] = React.useState(data?.data.email);
	const [admin, setAdmin] = React.useState(data?.data.system_admin);
	const [errorMessage, setErrorMessage] = React.useState<undefined | string>(
		undefined,
	);
	const [validateStatus, setValidateStatus] = React.useState<
		'error' | 'success' | 'warning' | 'validating' | undefined
	>(undefined);

	const [form] = Form.useForm();

	React.useEffect(() => {
		setFirstName(data?.data.first_name);
		setLastName(data?.data.last_name);
		setEmail(data?.data.email);
		setAdmin(data?.data.system_admin);
	}, [isLoading]);

	type DataEditProps = {
		setField: React.Dispatch<React.SetStateAction<string | undefined>>;
		event: React.ChangeEvent<HTMLInputElement>;
	};

	const dataEdit = ({ setField, event }: DataEditProps) => {
		setField(event.target.value);
	};

	const dataEditBoolean = ({
		setField,
		data,
	}: {
		setField: React.Dispatch<React.SetStateAction<boolean | undefined>>;
		data: boolean;
	}) => {
		setField(data);
		refetch();
	};

	const cancelEdit = () => {
		setEdit(false);
		setFirstName(data?.data.first_name);
		setLastName(data?.data.last_name);
		setEmail(data?.data.email);
		setAdmin(data?.data.system_admin);
		setValidateStatus(undefined);
		setErrorMessage(undefined);
	};

	const updateProfile = async () => {
		mutation.mutate({
			first_name: firstName,
			last_name: lastName,
			email,
			id,
			system_admin: admin,
		});
	};
	const [stage, setStage] = React.useState(1);

	return (
		<div>
			<Drawer
				title="Profile"
				width={'500px'}
				placement="right"
				closable={false}
				onClose={() => {}}
				open={visble}
				getContainer={false}
				// headerStyle={{ backgroundColor: edit ? 'orange' : 'white' }}
				extra={
					<Space>
						<Button
							onClick={() => {
								edit ? form.submit() : setEdit(!edit);
							}}
							type={edit ? 'primary' : 'default'}
						>
							{edit ? (
								<>
									<EditOutlined /> Update
								</>
							) : (
								<>
									{' '}
									<EditOutlined /> Edit
								</>
							)}
						</Button>

						<Button
							onClick={() => {
								edit ? cancelEdit() : setProfileDrawlerVisible(false);
							}}
							type={edit ? 'primary' : 'default'}
							style={{ backgroundColor: edit ? 'orange' : 'white' }}
						>
							{edit ? (
								<>
									<CloseCircleOutlined /> Cancel
								</>
							) : (
								<>
									{' '}
									<CloseCircleOutlined /> Close
								</>
							)}
						</Button>
					</Space>
				}
			>
				<Spin spinning={isLoading || mutation.isLoading}>
					<Form
						form={form}
						onFinish={() => {
							updateProfile();
						}}
					>
						{data?.data && (
							<Descriptions bordered column={1} size="small">
								<Descriptions.Item label="First name">
									{edit ? (
										<Form.Item
											style={{ marginTop: '20px' }}
											validateStatus={validateStatus || undefined}
											help={errorMessage || undefined}
										>
											<Input
												value={firstName}
												name="first_name"
												onChange={(event) =>
													dataEdit({ setField: setFirstName, event })
												}
											/>
										</Form.Item>
									) : (
										firstName
									)}
								</Descriptions.Item>

								<Descriptions.Item label="Last name">
									{edit ? (
										<Form.Item
											style={{ marginTop: '20px' }}
											validateStatus={validateStatus || undefined}
											help={errorMessage || undefined}
										>
											<Input
												value={lastName}
												name="last_name"
												onChange={(event) =>
													dataEdit({ setField: setLastName, event })
												}
											/>
										</Form.Item>
									) : (
										lastName
									)}
								</Descriptions.Item>

								<Descriptions.Item label="Email">
									{/* {edit ? (
									<Input
										value={email}
										name="email"
										onChange={(event) =>
											dataEdit({ setField: setEmail, event })
										}
									/>
								) : (
									email
								)} */}
									{email}
								</Descriptions.Item>

								<Descriptions.Item label="Account status">
									{data.data.account_status}
								</Descriptions.Item>

								{/* <Descriptions.Item label="System admin">
								<Switch
									disabled={!edit}
									checkedChildren={<CheckOutlined />}
									unCheckedChildren={<CloseOutlined />}
									// defaultChecked={admin}
									onChange={(checked) =>
										dataEditBoolean({ setField: setAdmin, data: checked })
									}
									checked={admin && data?.data.system_admin}
								/>
							</Descriptions.Item> */}

								<Descriptions.Item label="Last login date">
									{data.data.last_login
										? new Date(data.data.last_login).toLocaleDateString() +
										  ', ' +
										  new Date(data.data.last_login).toLocaleTimeString([], {
												hour: 'numeric',
												minute: '2-digit',
										  })
										: '-'}
								</Descriptions.Item>
								<Descriptions.Item label="Last login ip">
									{data.data.last_login_ip || '-'}
								</Descriptions.Item>
							</Descriptions>
						)}
					</Form>

					<Collapse
						style={{ marginTop: '20px' }}
						size="small"
						items={[
							{
								key: email,
								label: 'Reset password',
								children: <UserResetPassword />,
							},
						]}
					/>
				</Spin>
			</Drawer>
		</div>
	);
};
