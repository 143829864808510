import React, { useEffect, useState } from 'react';

import {
	PaymentElement,
	useElements,
	useStripe,
} from '@stripe/react-stripe-js';
import { StripePaymentElementOptions } from '@stripe/stripe-js';
import { Button } from 'antd';

import style from './style.module.css';

export default function CheckoutForm() {
	const stripe = useStripe();
	const elements = useElements();

	const [message, setMessage] = useState<string | undefined | null>(null);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		if (!stripe) {
			return;
		}

		const clientSecret = new URLSearchParams(window.location.search).get(
			'payment_intent_client_secret',
		);

		if (!clientSecret) {
			return;
		}

		stripe
			.retrievePaymentIntent(clientSecret)
			.then(({ paymentIntent }) => {
				console.log(paymentIntent);
				switch (paymentIntent?.status) {
					case 'succeeded':
						setMessage('Payment succeeded!');

						break;
					case 'processing':
						setMessage('Your payment is processing.');
						break;
					case 'requires_payment_method':
						setMessage('Your payment was not successful, please try again.');
						break;
					default:
						setMessage('Something went wrong.');
						break;
				}
			})
			.then((x) => {
				return setTimeout(() => {}, 10000);
			});
	}, [stripe]);

	const handleSubmit = async (e: any) => {
		e?.preventDefault();

		if (!stripe || !elements) {
			// Stripe.js hasn't yet loaded.
			// Make sure to disable form submission until Stripe.js has loaded.
			return;
		}

		setIsLoading(true);
		const url = new URL(window.location.href);
		url.search = '';

		const { error } = await stripe.confirmPayment({
			elements,
			confirmParams: {
				// Make sure to change this to your payment completion page
				return_url: `${url.protocol}//${url.hostname}${
					url.port ? ':' + url.port : ''
				}/app/subscribe/completion`,
			},
		});

		// This point will only be reached if there is an immediate error when
		// confirming the payment. Otherwise, your customer will be redirected to
		// your `return_url`. For some payment methods like iDEAL, your customer will
		// be redirected to an intermediate site first to authorize the payment, then
		// redirected to the `return_url`.
		if (error.type === 'card_error' || error.type === 'validation_error') {
			setMessage(error.message);
		} else {
			setMessage('An unexpected error occurred.');
		}

		setIsLoading(false);
	};

	const paymentElementOptions: StripePaymentElementOptions = {
		layout: 'tabs',
	};

	return (
		<div className={style.stripeFormWrapper}>
			<form id="payment-form" onSubmit={handleSubmit}>
				<PaymentElement id="payment-element" options={paymentElementOptions} />
				<Button
					type="primary"
					disabled={isLoading || !stripe || !elements}
					id="submit"
					htmlType="submit"
					className={style.stripeSubmitButton}
				>
					<span id="button-text">
						{isLoading ? (
							<div className="spinner" id="spinner">
								LOADING
							</div>
						) : (
							'Subscribe'
						)}
					</span>
				</Button>
				{/* Show any error or success messages */}
				{message && <div id="payment-message">{message}</div>}
			</form>
		</div>
	);
}
