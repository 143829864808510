import React from 'react';

import { Space } from 'antd';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';

import { UserContext } from '@/App';
import Navbar from '@/components/navbar';
import image from '@/lib/assets/trust-my-voice.svg';
import { UserProfieDrawer } from '@/pages/app/settings/users/UserProfieDrawer';

import style from './style.module.css';

interface IProps {
	children?: React.ReactNode;
}

const DashboardLayout = (props: IProps) => {
	const navigate = useNavigate();
	const path = useLocation();
	const { user, isAdmin, profileDrawlerVisible, setProfileDrawlerVisible } =
		React.useContext(UserContext);

	return (
		<div className={`container`}>
			<Navbar
				isAdmin={isAdmin}
				setProfileDrawlerVisible={setProfileDrawlerVisible}
			/>{' '}
			<Outlet context={{ x: 1 }} />
			<UserProfieDrawer
				id={user?.uid}
				visble={profileDrawlerVisible}
				setProfileDrawlerVisible={setProfileDrawlerVisible}
			/>
		</div>
	);
};

export default DashboardLayout;
