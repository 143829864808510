import React from 'react';

import { Alert, message } from 'antd';

import './ErrorBoundaryReport.css'; // Import the custom css
import { InfoCircleTwoTone } from '@ant-design/icons'; // Import the custom icon

import {
	IReportDataErrors,
	IReportDataErrorsValue,
} from '@/pages/app/dashboard/creators/creator/report-new/report.types';

interface IProps {
	message: IReportDataErrors;
	topics: string[];
	children: JSX.Element;
	style?: React.CSSProperties; // Optional style 
	className?: string; // Optional class 
}

class ErrorBoundaryReport extends React.Component<IProps, any> {
	constructor(props: IProps) {
		super(props);
		this.state = { hasError: false };
	}

	static getDerivedStateFromError(error: any) {
		// Update state so the next render will show the fallback UI.
		return { hasError: true };
	}

	componentDidCatch(error: any, errorInfo: any) {
		// You can also log the error to an error reporting service
	}

	render() {
		const topic = Object.keys(this.props.message).find((key: string) =>
			this.props.topics.includes(key),
		);
		if (this.state.hasError || !!topic) {
			// You can render any custom fallback UI
			// return <h1>Something went wrong.</h1>;

			if (topic) {
				return (
					<Alert
						className='unique-error-boundary-report' 
						style={this.props.style} 
						// message={`${topic.toUpperCase()} `}
						message={this.props.message[topic].message}
						type="warning"
						banner
						showIcon
						icon={this.props.className === 'unique-error-boundary-report' ? <InfoCircleTwoTone style={{ color: '#1677FF' }} /> : undefined}
					/>
				);
			}
			return (
				<Alert
					className='unique-error-boundary-report' 
					style={this.props.style} 
					message="Warning!"
					description="Something went wrong - please contact us and mention this issue"
					type="warning"
					showIcon
				/>
			);
		}

		return this.props.children;
	}
}

export default ErrorBoundaryReport;

// // Usage in a component
// class App extends React.Component {
// 	render() {
// 		return (
// 			<ErrorBoundary>
// 				<MyComponent />
// 			</ErrorBoundary>
// 		);
// 	}
// }
