import { ClientSubscription } from './subscriptionType';

export const totalAvailableReports = (subscriptions: ClientSubscription[]) => {
	return subscriptions.reduce((total, subscription) => {
		return (
			total +
			(subscription.item_count_per_month +
				(subscription.sum_count_topup ?? 0) -
				subscription.item_count_used -
				(subscription.sum_used_count_topup ?? 0) -
				(subscription.sum_in_process_topup ?? 0))
		);
	}, 0);
};
