import { ReactComponent as single } from './src/1.svg';
import { ReactComponent as twenty } from './src/2.svg';
import { ReactComponent as forty } from './src/3.svg';
import { ReactComponent as sixty } from './src/4.svg';
import { ReactComponent as topUpicon } from './src/topUp.svg';

export const subsriptionIcons: {
	[key: number]: React.FunctionComponent<
		React.SVGProps<SVGSVGElement> & { title?: string | undefined }
	>;
} = { 1: single, 2: twenty, 3: forty, 4: sixty };

export const TopUpIcon = topUpicon;
