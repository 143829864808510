import { InfoCircleOutlined } from '@ant-design/icons';
import { Button, Modal } from 'antd';

export const InfoSection = ({
	title,
	content,
	style,
	circleStyle,
}: {
	title: string;
	content: any;
	style?: any;
	circleStyle?: any;
}) => (
	<Button
		type="link"
		style={style}
		onClick={() => {
			Modal.info({
				title: title,
				content: <div>{content}</div>,
				maskClosable: true,
			});
		}}
	>
		<InfoCircleOutlined style={circleStyle} />
	</Button>
);
