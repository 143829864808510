import React, { useState } from 'react';
import { Button, Card, Form, Input, Checkbox } from 'antd';
import './style.userform.css'

function ProfileSettings() {
	const [form] = Form.useForm();
	const [changePassword, setChangePassword] = useState(false);

	const onFinish = (values: any) => {
		console.log('Received values of form: ', values);
	};

	return (
		<Card
			title={
				<div
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
					}}
				>
					<h4>Profile Settings</h4>
					<div>
						<Button
							style={{ marginRight: 8 }}
							onClick={() => form.resetFields()}
						>
							Cancel
						</Button>
						<Button type="primary" onClick={() => form.submit()}>
							Save
						</Button>
					</div>
				</div>
			}
		>
			<div className='custom-form'>
			<Form form={form} layout="horizontal" onFinish={onFinish}>
				<Form.Item className="form-item" label="First Name" name="firstName" labelCol={{ span: 2 }} wrapperCol={{ span: 8 }}>
					<Input />
				</Form.Item>
				<Form.Item className="form-item" label="Last Name" name="lastName" labelCol={{ span: 2 }} wrapperCol={{ span: 8 }}>
					<Input />
				</Form.Item>
				<Form.Item className="form-item" label="Email" name="email" labelCol={{ span: 2 }} wrapperCol={{ span: 8 }}>
					<Input />
				</Form.Item>
				<Form.Item className="form-item" label=" " name="changePassword" labelCol={{ span: 2 }} wrapperCol={{ span: 8 }} colon={false}>
					<Checkbox
						checked={changePassword}
						onChange={e => setChangePassword(e.target.checked)}
					>
						Change Password
					</Checkbox>
				</Form.Item>
				{changePassword && (
					<>
						<Form.Item className="form-item" label="New Password" name="newPassword" labelCol={{ span: 2 }} wrapperCol={{ span: 8 }}>
							<Input.Password className="password-input" />
						</Form.Item>
						<Form.Item className="form-item" label="Repeat Password" name="repeatNewPassword" labelCol={{ span: 2 }} wrapperCol={{ span: 8 }}>
							<Input.Password className="password-input" />
						</Form.Item>
					</>
				)}
			</Form>
			</div>
		</Card>
	);
}

export default ProfileSettings;