import { List, Typography } from 'antd';
import cn from 'classnames';

import style from '../styles.module.css';

type TProps = {
	data: any;
};

export default function KeyInsights({ data }: TProps) {
	return (
		<>
			<Typography.Title level={5} className={cn(style.tabTitle, style.m0)}>
				Summary
			</Typography.Title>
			<Typography.Text
				className={cn(
					style.text,
					data?.video?.summary === 'N/A' && style.grayText,
				)}
			>
				{data?.video?.summary === 'N/A' ? 'n/a' : data?.video?.summary}
			</Typography.Text>
			{data?.video?.keyInsights?.length > 0 && (
				<>
					<Typography.Title level={5} className={cn(style.tabTitle, style.m0)}>
						Key insights
					</Typography.Title>
					{data?.video?.keyInsights?.[0] !== 'N/A' ? (
						<List
							itemLayout="horizontal"
							className={style.keyInsights}
							dataSource={data?.video?.keyInsights}
							renderItem={(item, index) => (
								<List.Item>
									<List.Item.Meta
										avatar={<span>{(item as string)?.slice(0, 2)}</span>}
										description={
											<span style={{ color: '#000' }}>
												{(item as string)?.slice(2)}
											</span>
										}
									/>
								</List.Item>
							)}
						/>
					) : (
						<span style={{ color: 'gray' }}>n/a</span>
					)}
				</>
			)}
		</>
	);
}
