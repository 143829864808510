export default function downloadZipFile(id: string, data: any) {
	const blob = new Blob([data], {
		type: data?.type,
	});
	const url = URL.createObjectURL(blob);
	const link = document.createElement('a');
	link.href = url;
	link.setAttribute('download', `process_${id}`);
	document.body.appendChild(link);
	link.click();
	document.body.removeChild(link);
}
