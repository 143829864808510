import { PropsWithChildren, createContext, useReducer } from 'react';

import { initialState, reportsContextType } from '../@types';
import retportReducer from './ReportReducer';

const ReportContext = createContext<reportsContextType | null>(null);

export const ReportProvider = ({ children }: PropsWithChildren) => {
	const initialState: initialState = {
		loading: false,
		activeAddInfluencer: false,
		searchInfluencersCounter: 0,
		searchInfluencers: [{ id: '0', instagram: '', tiktok: '', youtube: '' }],
		searchInfluencersResults: [
			{
				id: '0',
				Instagram: {
					name: '',
					avatar: '',
					socialLink: '',
					subsCount: '',
					external: false,
				},
				TikTok: {
					name: '',
					avatar: '',
					socialLink: '',
					subsCount: '',
					external: false,
				},
				Youtube: {
					name: '',
					avatar: '',
					socialLink: '',
					subsCount: '',
					external: false,
				},
			},
		],
	};

	const [state, dispatch] = useReducer(retportReducer, initialState);

	return (
		<ReportContext.Provider
			value={{
				state,
				dispatch,
			}}
		>
			{children}
		</ReportContext.Provider>
	);
};

export default ReportContext;
