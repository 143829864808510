import client from '@/lib/http/client';

export const fetchCreator = async (id: string): Promise<Creator> => {
	return (await client.get(`/creators/${id}`)).data;
};

export interface Creator {
	_id: string;
	profileName: string;
	personName: string;
	companyName: string;
	language: string;
	location: string;
	profileImageURL: string;
	iabClassification: string[];
	platformHandles: string[];
	website: CreatorWebsite;
	contactInformation: CreatorContactInformation;
	platforms: CreatorPlatforms;
}
export interface CreatorWebsite {
	url: string;
	description: string;
	language: string;
	seo: string;
	accessibility: string;
	performance: string;
	bestPractices: string;
	logoURL: string;
	desktopScreenshot: string;
	mobileScreenshot: string;
	createdOn: string;
}

export interface CreatorContactInformation {
	city: string;
	state: string;
	postcode: string;
	country: string;
	phones: string[];
	emails: string[];
}

export interface CreatorPlatforms {
	Facebook: CreatorPlatformsFacebook;
	Twitter: CreatorPlatformsTwitter;
	Instagram: CreatorPlatformsInstagram;
	Youtube: CreatorPlatformsYoutube;
	Pinterest: CreatorPlatformsPinterest;
	Twitch: CreatorPlatformsTwitch;
	TikTok: CreatorPlatformsTikTok;
	createdOn: string;
	lastUpdatedOn: string;
}

export interface CreatorPlatformsFacebook {
	platform: string;
	platformId: string;
	username: string;
	fullname: string;
	url: string;
	profileImageURL: string;
	description: string;
	about: string;
	createdOn: string;
	connections: CreatorPlatformsFacebookConnections;
	posts: CreatorPlatformsFacebookPosts;
	stats: CreatorPlatformsFacebookStats;
	statsHistory: any[];
}

export interface CreatorPlatformsFacebookConnections {
	followers: string;
}

export interface CreatorPlatformsFacebookPosts {
	posts: string;
	photos: string;
	videos: string;
}

export interface CreatorPlatformsFacebookStats {
	likes: string;
	comments: string;
	reviews: string;
	rating: string;
}

export interface CreatorPlatformsTwitter {
	platformId: string;
	username: string;
	fullname: string;
	url: string;
	profileImageURL: string;
	description: string;
	about: string;
	createdOn: string;
	connections: CreatorPlatformsTwitterConnections;
	posts: CreatorPlatformsTwitterPosts;
	stats: CreatorPlatformsTwitterStats;
	statsHistory: any[];
}

export interface CreatorPlatformsTwitterConnections {
	followers: string;
	following: string;
}

export interface CreatorPlatformsTwitterPosts {
	tweets: string;
	retweeets: string;
	media: string;
}

export interface CreatorPlatformsTwitterStats {
	likes: string;
	comments: string;
}

export interface CreatorPlatformsInstagram {
	platformId: string;
	username: string;
	fullname: string;
	url: string;
	profileImageURL: string;
	description: string;
	about: string;
	createdOn: string;
	connections: CreatorPlatformsInstagramConnections;
	posts: CreatorPlatformsInstagramPosts;
	stats: CreatorPlatformsInstagramStats;
	statsHistory: any[];
}

export interface CreatorPlatformsInstagramConnections {
	followers: string;
	following: string;
}

export interface CreatorPlatformsInstagramPosts {
	posts: string;
	photos: string;
	videos: string;
	guides: string;
	reels: string;
}

export interface CreatorPlatformsInstagramStats {
	likes: string;
	comments: string;
	views: string;
}

export interface CreatorPlatformsYoutube {
	platformId: string;
	username: string;
	fullname: string;
	url: string;
	profileImageURL: string;
	description: string;
	about: string;
	createdOn: string;
	connections: CreatorPlatformsYoutubeConnections;
	posts: CreatorPlatformsYoutubePosts;
	stats: CreatorPlatformsYoutubeStats;
	statsHistory: any[];
}

export interface CreatorPlatformsYoutubeConnections {
	subscribers: string;
}

export interface CreatorPlatformsYoutubePosts {
	videos: string;
	shorts: string;
	playlists: string;
}

export interface CreatorPlatformsYoutubeStats {
	likes: string;
	comments: string;
	views: string;
}

export interface CreatorPlatformsPinterest {
	platformId: string;
	username: string;
	fullname: string;
	url: string;
	profileImageURL: string;
	description: string;
	about: string;
	createdOn: string;
	connections: CreatorPlatformsPinterestConnections;
	posts: CreatorPlatformsPinterestPosts;
	stats: CreatorPlatformsPinterestStats;
	statsHistory: any[];
}

export interface CreatorPlatformsPinterestConnections {
	followers: string;
	following: string;
}

export interface CreatorPlatformsPinterestPosts {
	posts: string;
	photos: string;
	videos: string;
	pins: string;
}

export interface CreatorPlatformsPinterestStats {
	likes: string;
	comments: string;
	views: string;
}

export interface CreatorPlatformsTwitch {
	platformId: string;
	username: string;
	fullname: string;
	url: string;
	profileImageURL: string;
	description: string;
	about: string;
	createdOn: string;
	connections: CreatorPlatformsTwitchConnections;
	posts: CreatorPlatformsTwitchPosts;
	stats: CreatorPlatformsTwitchStats;
	statsHistory: any[];
}

export interface CreatorPlatformsTwitchConnections {
	followers: string;
}

export interface CreatorPlatformsTwitchPosts {
	videos: string;
}

export interface CreatorPlatformsTwitchStats {
	views: string;
}

export interface CreatorPlatformsTikTok {
	platformId: string;
	username: string;
	fullname: string;
	url: string;
	profileImageURL: string;
	description: string;
	about: string;
	createdOn: string;
	connections: CreatorPlatformsTikTokConnections;
	posts: CreatorPlatformsTikTokPosts;
	stats: CreatorPlatformsTikTokStats;
	statsHistory: any[];
}

export interface CreatorPlatformsTikTokConnections {
	followers: string;
	following: string;
}

export interface CreatorPlatformsTikTokPosts {
	videos: string;
}

export interface CreatorPlatformsTikTokStats {
	likes: string;
	comments: string;
	shares: string;
	views: string;
}
