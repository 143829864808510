import { useEffect, useState } from 'react';

import { RedoOutlined } from '@ant-design/icons';
import { Button, Tag } from 'antd';

interface iProps {
	sendEmailCode: () => void;
}

export const ButtonTimer = ({ sendEmailCode }: iProps) => {
	const [timer, setTimer] = useState(45);
	const [isButtonDisabled, setIsButtonDisabled] = useState(true);

	useEffect(() => {
		let countdown: NodeJS.Timeout;
		if (isButtonDisabled) {
			countdown = setInterval(() => {
				setTimer((prevTimer) => {
					if (prevTimer <= 1) {
						clearInterval(countdown);
						setIsButtonDisabled(false);
						return 0;
					} else {
						return prevTimer - 1;
					}
				});
			}, 1000);
		}

		return () => countdown && clearInterval(countdown);
	}, [isButtonDisabled]);

	const handleClick = () => {
		if (!isButtonDisabled) {
			setIsButtonDisabled(true);
			setTimer(45);
			sendEmailCode();
		}
	};

	return (
		<div>
			<p>
				Didn't get email? <br />{' '}
				{timer ? (
					<>
						Resend after:
						<Tag color="green">{timer} sec</Tag>
					</>
				) : (
					''
				)}
			</p>

			<Button
				type="primary"
				style={{ width: '100%' }}
				onClick={handleClick}
				disabled={isButtonDisabled}
			>
				Resend token <RedoOutlined />
			</Button>
		</div>
	);
};
