import { useContext, useEffect, useState } from 'react';

import {
	Descriptions,
	Drawer,
	Flex,
	Space,
	Table,
	Tabs,
	Tag,
	Typography,
	message,
} from 'antd';
import cn from 'classnames';

import ReportStatus from '@/components/ReportStatus';
import TabsControls, { titles } from '@/components/common/TabsControls';
import VideoPlayer from '@/components/common/VideoPlayer';
import { CDN_URL } from '@/const';
import robotImage from '@/lib/assets/co-pilot.png';
import placeholder from '@/lib/assets/placeholder.png';
import prepareDate from '@/lib/helpers/prepareDate';
import prepareImageSrc from '@/lib/helpers/prepareImageSrc';
import { PageContext } from '@/providers/PageContextProvider';

import style from './styles.module.css';

import { columns } from './components/columns';
import CreatorInsights from './components/tabs/CreatorInsights';
import EntityInsights from './components/tabs/EntityInsights';
import KeyInsights from './components/tabs/KeyInsights';
import Keywords from './components/tabs/Keywords';
import Transcript from './components/tabs/Transcript';
import VideoInsights from './components/tabs/VideoInsights';

export type TActiveMenuTab =
	| 'key-insights'
	| 'creator-insight'
	| 'video-insight'
	| 'entity-insights'
	| 'brand-mentions'
	| 'sentiments'
	| 'table';

export default function ViewCopilotVideoDetailsDrawer() {
	const { drawer, closeDrawer } = useContext(PageContext);
	const [search, setSearch] = useState('');
	const [activeMenuTab, setActiveMenuTab] =
		useState<TActiveMenuTab>('key-insights');

	// const { data, isError, error, isLoading } = useGetBatchProcessesById(
	// 	drawer?.data?.batch_id,
	// );

	const onClose = () => {
		// form.resetFields();
		closeDrawer();
	};

	// useEffect(() => {
	// 	if (isError) {
	// 		const getError = error as any;

	// 		message.error({
	// 			type: 'error',
	// 			content: `Something went wrong: ${
	// 				getError?.response?.data?.message ?? getError?.message
	// 			}`,
	// 		});
	// 	}
	// }, [isError]);

	const data = drawer?.data;

	return (
		<Drawer
			open={drawer.isOpen && drawer.type === 'view-copilot-video-details'}
			width={'740px'}
			onClose={onClose}
			closable
			title={
				<Flex gap={16} align="center">
					<img
						src={robotImage}
						alt="Product image"
						width={60}
						height={'auto'}
						style={{ borderRadius: '10px', overflow: 'hidden' }}
					/>
					<Typography.Title level={2}>Copilot video details</Typography.Title>
				</Flex>
			}
			// loading={isLoading}
		>
			<Flex vertical gap={10} justify={'center'} style={{ width: '100%' }}>
				<Flex className={style.contentWrapper} vertical>
					{/* <Flex className={style.leftSideWrapper} vertical> */}
					<div className={style.imageWrapper}>
						{data?.video?.cdnUrl && (
							<VideoPlayer
								preview={data?.video?.thumbnail}
								url={data?.video?.cdnUrl}
							/>
						)}
						{!data?.video?.cdnUrl && (
							<>
								{data?.video?.thumbnail ? (
									<img
										src={
											data?.video?.thumbnail?.startsWith('http')
												? data?.video?.thumbnail
												: CDN_URL + prepareImageSrc(data?.video?.thumbnail)
										}
										alt=""
									/>
								) : (
									<img src={placeholder} alt="'Image not found' placeholder" />
								)}
							</>
						)}
					</div>

					<div className={style.textWrapper}>
						{/* <Typography.Title level={5} className={style.heading}>
                    Transcript
                  </Typography.Title> */}
						<Tabs
							defaultActiveKey="Transcript"
							size="small"
							items={[
								{
									key: 'transcript',
									label: 'Transcript',
									children: (
										<Transcript
											data={data}
											search={search}
											setSearch={setSearch}
										/>
									),
								},
								{
									key: 'keywords',
									label: 'Keywords',
									children: <Keywords data={data} />,
								},
								{
									key: 'summary',
									label: 'Summary',
									children: <KeyInsights data={data} />,
								},
								{
									key: 'creator-insights',
									label: 'Creator insights',
									children: <CreatorInsights data={data} />,
								},
								{
									key: 'video-insights',
									label: 'Video insights',
									children: <VideoInsights data={data} />,
								},
								{
									key: 'entity-insights',
									label: 'Entity insights',
									children: <EntityInsights data={data} />,
								},
							]}
						/>
					</div>
				</Flex>
				{/* </Flex> */}
			</Flex>
		</Drawer>
	);
}
