import React from 'react';

import {
	Button,
	Card,
	Checkbox,
	Col,
	Form,
	Input,
	Row,
	Typography,
} from 'antd';
import { message } from 'antd';
import { useNavigate } from 'react-router-dom';

import client from '@/lib/http/client';

import style from './style.module.css';

import logo from '../login/logo.png';

const Register = () => {
	const [form] = Form.useForm();
	const [loading, setLoading] = React.useState(false);
	const navigate = useNavigate();
	return (
		<Card
			title={
				<div className={style.wrapper}>
					<div className={style.logo}>
						<img src={logo} alt="" />
					</div>
					<div className={style.title}>
						<h2>Create new account</h2>
					</div>
				</div>
			}
			hoverable
		>
			<Form
				layout="vertical"
				form={form}
				onFinish={(values) => {
					setLoading(true);
					client
						.post('/auth/register', values)
						.then((response) => {
							if (typeof response.data === 'string') {
								if (
									response.data.startsWith(
										'error: duplicate key value violates',
									)
								) {
									throw new Error('Email already exists');
								}
							}
							localStorage.setItem('email', values.email);
							navigate('/auth/confirm');
							// setLoading(false);
							// form.resetFields();
						})
						.catch((err: any) => {
							setLoading(false);
							console.log(err);
							if (err.message === 'Email already exists') {
								form.setFields([
									{
										name: 'email',
										errors: ['Email already exists'],
									},
								]);
							}
							if (err.response?.data.message[0].startsWith('password')) {
								form.setFields([
									{
										name: 'password',
										errors: ['Password is not strong enough'],
									},
								]);
							}
						});
				}}
			>
				<Row gutter={16}>
					<Col>
						<Form.Item
							label="First name"
							name="first_name"
							required
							rules={[
								{
									required: true,
									whitespace: true,
									message: 'Please input your first name',
								},
							]}
						>
							<Input placeholder="" />
						</Form.Item>
					</Col>
					<Col>
						<Form.Item
							label="Last name"
							name="last_name"
							rules={[
								{ required: true, message: 'Please input your last name' },
							]}
						>
							<Input placeholder="" />
						</Form.Item>
					</Col>
				</Row>
				<Form.Item
					label="Email"
					name="email"
					rules={[
						{
							required: true,
							message: 'Please input your email',
						},
						{
							type: 'email',
							message: 'The input is not valid email',
						},
					]}
				>
					<Input placeholder="" />
				</Form.Item>

				<Form.Item
					label="Password"
					name="password"
					rules={[
						{
							required: true,
							message: 'Please input your password',
						},
					]}
					help=<div>
						<p>
							Password must be at least 8 characters long and contain at least:
						</p>
						<ul>
							<li>one uppercase letter</li>
							<li>one lowercase letter</li>
							<li>one number</li>
							<li>one special character</li>
						</ul>
					</div>
				>
					<Input.Password />
				</Form.Item>

				{/* <Form.Item
            label="Confirm password"
            name="confirm_password"
            rules={[{ required: true }]}
          >
            <Input.Password />
          </Form.Item> */}

				<Form.Item
					label=""
					name="accept_agreement"
					rules={[
						{
							required: true,
							message: 'You must accept the agreement to continue.',
						},
					]}
					valuePropName="checked"
				>
					<Checkbox name="accept_agreement">
						By clicking this you agree to our{' '}
						<Typography.Link href="#" style={{ padding: 0 }}>
							Terms & conditions
						</Typography.Link>
					</Checkbox>
				</Form.Item>
				<Form.Item>
					<Button type="primary" htmlType="submit" block loading={loading}>
						Create new account
					</Button>
				</Form.Item>

				<Form.Item style={{ textAlign: 'center' }}>
					<Typography.Text>
						Already have an account?{' '}
						<Typography.Link
							onClick={() =>
								navigate('/auth/login', {
									state: { email: form.getFieldValue('email') },
								})
							}
						>
							Login here
						</Typography.Link>
					</Typography.Text>
				</Form.Item>
			</Form>
		</Card>
	);
};

export default Register;
