import React from 'react';

import { AntDesignOutlined } from '@ant-design/icons';
import { useQuery } from '@tanstack/react-query';
import { Avatar, Badge, Descriptions, Space, Spin, Statistic, Tag } from 'antd';

import { fetchCreator } from '@/interfaces/creator';
import { fetchCreatorReport } from '@/interfaces/report';
import { formatNumber } from '@/lib/helpers/numFormat';
import client from '@/lib/http/client';

import style from './style.module.css';

import Platforms from '../platforms';

interface IProps {
	id: string;
}

const Creator = ({ id }: IProps) => {
	const creator = useQuery({
		queryKey: ['creator', id],
		queryFn: () => fetchCreator(id),
		enabled: !!id,
	});

	const summary = useQuery({
		queryKey: ['summary', id, 'summary'],
		queryFn: () => fetchCreatorReport(id, ['summary']),
		enabled: !!id,
	});

	return (
		<Spin
			spinning={creator.isLoading && summary.isLoading}
			tip="Loading..."
			size="large"
		>
			<div className={style.root}>
				<div className={style.avatarContainer}>
					<div className={style.avatar}>
						<Avatar
							size={{ xs: 24, sm: 32, md: 40, lg: 64, xl: 80, xxl: 100 }}
							icon={<AntDesignOutlined />}
							src={creator.data?.profileImageURL}
						/>
						<h3>{creator.data?.profileName}</h3>
					</div>
					<div className={style.socialMedia}>
						{creator.data?.platformHandles?.map((platform: any) => (
							<a
								href={platform}
								target="_blank"
								rel="noreferrer"
								key={platform.id}
							>
								<Platforms platform={platform} />
							</a>
						))}
					</div>
				</div>

				<div className={style.descriptionContainer}>
					<Descriptions column={1} bordered size="small">
						<Descriptions.Item label="Location">
							{summary.data?.summary?.location}
						</Descriptions.Item>
						<Descriptions.Item label="Language">
							{summary.data?.summary?.language}
						</Descriptions.Item>
						<Descriptions.Item label="Age group">
							{summary.data?.summary?.ageGroup}
						</Descriptions.Item>
						<Descriptions.Item label="Main Categories">
							<Space size={[0, 8]} wrap>
								{summary.data?.summary?.categories.map((c) => (
									<Tag key={c}>{c}</Tag>
								))}
							</Space>
						</Descriptions.Item>
						<Descriptions.Item label="Active Since">
							{summary.data?.summary?.activeSince}
						</Descriptions.Item>
					</Descriptions>

					<div className={style.statistics}>
						<div className={style.statisticsItem}>
							<Statistic
								title="Followers"
								value={formatNumber(
									summary.data?.summary?.platformMetrics.totalFollowers
										.metric || 0,
								)}
								precision={2}
							/>
							<Badge
								status="success"
								text={
									summary.data?.summary?.platformMetrics.totalFollowers.label
								}
							/>
						</div>
						<div className={style.statisticsItem}>
							<Statistic
								title="Engagement rate"
								value={
									summary.data?.summary?.platformMetrics.engagementRate.metric
								}
								suffix={'%'}
								precision={2}
							/>
							<Badge
								color="blue"
								text={
									summary.data?.summary?.platformMetrics.engagementRate.label
								}
							/>
						</div>

						<div className={style.statisticsItem}>
							<Statistic
								title="Total videos"
								value={formatNumber(
									summary.data?.summary?.platformMetrics.totalVideos.metric ||
										0,
								)}
								precision={0}
							/>
							<Badge
								color="blue"
								text={summary.data?.summary?.platformMetrics.totalVideos.label}
							/>
						</div>

						<div className={style.statisticsItem}>
							<Statistic
								title="Post frequency"
								value={
									summary.data?.summary?.platformMetrics.postFrequency.metric
								}
								precision={2}
							/>
							<Badge
								status="success"
								text={
									summary.data?.summary?.platformMetrics.postFrequency.label
								}
							/>
						</div>
					</div>
				</div>
			</div>
		</Spin>
	);
};

export default Creator;
