import { useMutation, useQuery } from '@tanstack/react-query';
import { Descriptions, Input, Spin } from 'antd';

import client from '@/lib/http/client';

interface IProps {
	userId: number;
	edit: boolean;
}

const UserDetail = (props: IProps) => {
	const user = useQuery({
		queryKey: ['user', props.userId],
		queryFn: () => client.get(`/users/${props.userId}`),
		enabled: !!props.userId,
	});

	return (
		<div>
			<Spin spinning={user.isLoading}>
				<Descriptions bordered column={1} size="small">
					<Descriptions.Item label="User ID">
						{user.data?.data.id}
					</Descriptions.Item>

					<Descriptions.Item label="First Name">
						{props.edit ? (
							<Input value={user.data?.data.first_name} />
						) : (
							user.data?.data.first_name
						)}
					</Descriptions.Item>

					<Descriptions.Item label="Last Name">
						{props.edit ? (
							<Input value={user.data?.data.last_name} />
						) : (
							user.data?.data.last_name
						)}
					</Descriptions.Item>

					<Descriptions.Item label="Email">
						{user.data?.data.email}
					</Descriptions.Item>

					<Descriptions.Item label="Account status">
						{user.data?.data.account_status}
					</Descriptions.Item>

					<Descriptions.Item label="System Admin">
						{user.data?.data.system_admin.toString()}
					</Descriptions.Item>

					<Descriptions.Item label="Last Login">
						{user.data?.data.last_login
							? new Date(user.data?.data.last_login).toLocaleString()
							: '-'}
					</Descriptions.Item>
				</Descriptions>
			</Spin>
		</div>
	);
};

export default UserDetail;
