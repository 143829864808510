import React from 'react';

import { Button, Card, Flex, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';

import styles from './styles.module.css';

import logo from '../../pages/auth/login/logo.png';

const NotFound = () => {
	const navigate = useNavigate();

	const goBack = () => {
		navigate(-1); // This will navigate to the previous page
	};

	return (
		<Flex className={styles.wrapper} align="flex-start" justify="center">
			<Card>
				<Flex align="center" vertical>
					<img src={logo} alt="Social Voice logo" />
					<Typography.Title level={1} className={styles.title}>
						404 - Page Not Found
					</Typography.Title>
					<Typography.Text>
						Sorry, the page you're looking for does not exist.
					</Typography.Text>
					<Button onClick={goBack} type="primary" className={styles.backButton}>
						Go back
					</Button>
				</Flex>
			</Card>
		</Flex>
	);
};

export default NotFound;
