import {
	CommentOutlined,
	EyeOutlined,
	LikeOutlined,
	LinkOutlined,
	PieChartOutlined,
	TagOutlined,
} from '@ant-design/icons';
import { Avatar, Flex, Space, Tag, Typography } from 'antd';
import { SortOrder } from 'antd/es/table/interface';
import Link from 'antd/es/typography/Link';

import { CDN_URL } from '@/const';
import { formatNumber } from '@/lib/helpers/numFormat';
import prepareDate from '@/lib/helpers/prepareDate';
import prepareImageSrc from '@/lib/helpers/prepareImageSrc';

import styles from './style.module.css';

const BLUE_COLOR = '#156082',
	ORANGE_COLOR = '#F9931F';

const mockData = [
	{
		id: 'asdasd',
		processedDate: '2024-09-05T11:43:08Z',
		creationDate: '2024-08-28T17:05:08Z',
		platform: 'instagram',
		creator: {
			name: 'willsmith',
			channelId: 'willsmith',
			channelName: 'willsmith',
			channelUrl: 'https://www.instagram.com/willsmith',
			url: 'https://www.instagram.com/willsmith',
			thumbnail:
				'Instagram/profile/avatar/afce6edc774c64a7dcd72118de1361746bae4eee.png',
			followers: '70504883',
			description: 'Same kid from West Philly. W.O.A. Out now👇🏾',
		},
		video: {
			title:
				'We asked you to tag your Ride or Die for making an impact in your community and you all delivered! We got to meet three amazing organizations @blackboysreadtoo_bflo @artinspiresme1 @blackgirlsdoengineer and surprise them with micro-grants to support their work. Check ‘em out!',
			url: 'https://www.instagram.com/reel/C_OKK3Evl9M',
			cdnUrl: 'https://storage.googleapis.com/svcdn/public/C_OKK3Evl9M.mp4',
			description:
				'We asked you to tag your Ride or Die for making an impact in your community and you all delivered! We got to meet three amazing organizations @blackboysreadtoo_bflo @artinspiresme1 @blackgirlsdoengineer and surprise them with micro-grants to support their work. Check ‘em out!',
			links: ['N/A'],
			hashtags: null,
			mentions: null,
			thumbnail:
				'Instagram/content/thumbnail/d5324f67-64b1-41ed-89c4-756e56abeccd.png',
			keywords: [
				'Jada Smith Family',
				'Smith Family Foundation',
				'Ride or Die',
				'Jada Smith',
				'Smith Family',
				'Die Impact Challenge',
				'Rider',
				'Family Foundation',
				'Ride',
				'Die',
				'Family Foundation issued',
				'Impact',
				'Jada',
				'Smith',
				'Family',
				'Impact Challenge',
				'Die Impact',
				'friend',
				'Rekhia',
				'Foundation',
			],
			brands: [
				'Rekhia',
				'the Will and Jada Smith Family Foundation',
				'the Ride or Die Impact',
			],
			entities: ['N/A'],
			topics: [],
			summary: 'N/A',
			keyInsights: ['N/A'],
			transcription:
				" My friend, how are you? Hey, Rekhia, do you have a second to chat? Thank you both for being here. In June, the Will and Jada Smith Family Foundation issued the Ride or Die Impact Challenge. We ask you to tag your ride or die and tell us what you do for your community together. Here are three organizations powered by Ride or Die leaders that the WJSFF is supporting with small grants. Y'all are the real Rider Di's. You are my Rider Di for creating impact in the life of all the young people that we serve. I thought about it man, these are my Rider Di. I couldn't do this work at Black Girls to engineer without them. You are my Rider Di. We will be getting a grant from the Will and Jada Smith Family Foundation. That's amazing! I'm really excited to keep doing these literacy pop-ups. I can't wait to pick back up on the Barbershop book clubs, getting books into the hands of our boys. I'm just really excited to make an impact and see how this next year is going to go. And I know the girls are going to appreciate this as well.",
			language: 'en',
			keywordCloud: [
				'Jada Smith Family',
				'Smith Family Foundation',
				'Ride or Die',
				'Jada Smith',
				'Smith Family',
				'Die Impact Challenge',
				'Rider',
				'Family Foundation',
				'Ride',
				'Die',
				'Family Foundation issued',
				'Impact',
				'Jada',
				'Smith',
				'Family',
				'Impact Challenge',
				'Die Impact',
				'friend',
				'Rekhia',
				'Foundation',
			],
			mainCategories: ['Careers>Apprenticeships', 'Books and Literature'],
			stats: {
				views: 211824,
				likes: 4385,
				comments: 30,
				creationDate: '2024-08-21T11:24:08Z',
				duration: '72.706',
			},
			sentiment: {
				positive: '0.0',
				negative: '0.0',
				neutral: '0.0',
			},
			brandMentions: [
				{
					brand: 'Rekhia',
					voice: 1,
					metaData: 0,
				},
				{
					brand: 'the Will and Jada Smith Family Foundation',
					voice: 2,
					metaData: 0,
				},
				{
					brand: 'the Ride or Die Impact',
					voice: 1,
					metaData: 0,
				},
			],
		},
		recentVideos: null,
	},
	{
		id: 'asdddgfg',
		processedDate: '2024-09-04T09:03:08Z',
		creationDate: '2024-08-25T12:03:08Z',
		platform: 'instagram',
		creator: {
			name: 'willsmith',
			channelId: 'willsmith',
			channelName: 'willsmith',
			channelUrl: 'https://www.instagram.com/willsmith',
			url: 'https://www.instagram.com/willsmith',
			thumbnail:
				'Instagram/profile/avatar/afce6edc774c64a7dcd72118de1361746bae4eee.png',
			followers: '70504883',
			description: 'Same kid from West Philly. W.O.A. Out now👇🏾',
		},
		video: {
			title: `We brought @BadBoys to Houston for a few phenomenal folks who are making an impact on their community... next we’re looking for a few more who are giving back in different ways!
            Tag your Ride or Die in the comments and lemme know what you two do to make a difference in your community. Volunteer at your local library? Clean up your neighborhood park? Started your own nonprofit? Shout ‘em out!`,
			url: 'https://www.instagram.com/reel/C_OKK3Evl9M',
			cdnUrl: 'https://storage.googleapis.com/svcdn/public/C_OKK3Evl9M.mp4',
			description: `We brought @BadBoys to Houston for a few phenomenal folks who are making an impact on their community... next we’re looking for a few more who are giving back in different ways!
            Tag your Ride or Die in the comments and lemme know what you two do to make a difference in your community. Volunteer at your local library? Clean up your neighborhood park? Started your own nonprofit? Shout ‘em out!`,
			links: ['N/A'],
			hashtags: null,
			mentions: null,
			thumbnail:
				'Instagram/content/thumbnail/d5324f67-64b1-41ed-89c4-756e56abeccd.png',
			keywords: [
				'Impact',
				'Jada',
				'Smith',
				'Family',
				'Impact Challenge',
				'Die Impact',
				'friend',
				'Rekhia',
				'Foundation',
			],
			brands: [
				'Rekhia',
				'the Will and Jada Smith Family Foundation',
				'the Ride or Die Impact',
			],
			entities: ['N/A'],
			topics: [],
			summary: 'N/A',
			keyInsights: ['N/A'],
			transcription:
				" My friend, how are you? Hey, Rekhia, do you have a second to chat? Thank you both for being here. In June, the Will and Jada Smith Family Foundation issued the Ride or Die Impact Challenge. We ask you to tag your ride or die and tell us what you do for your community together. Here are three organizations powered by Ride or Die leaders that the WJSFF is supporting with small grants. Y'all are the real Rider Di's. You are my Rider Di for creating impact in the life of all the young people that we serve. I thought about it man, these are my Rider Di. I couldn't do this work at Black Girls to engineer without them. You are my Rider Di. We will be getting a grant from the Will and Jada Smith Family Foundation. That's amazing! I'm really excited to keep doing these literacy pop-ups. I can't wait to pick back up on the Barbershop book clubs, getting books into the hands of our boys. I'm just really excited to make an impact and see how this next year is going to go. And I know the girls are going to appreciate this as well.",
			language: 'en',
			keywordCloud: [
				'Jada Smith Family',
				'Smith Family Foundation',
				'Ride or Die',
				'Jada Smith',
				'Smith Family',
				'Die Impact Challenge',
				'Rider',
				'Family Foundation',
				'Ride',
				'Die',
				'Family Foundation issued',
				'Impact',
				'Jada',
				'Smith',
				'Family',
				'Impact Challenge',
				'Die Impact',
				'friend',
				'Rekhia',
				'Foundation',
			],
			mainCategories: ['Careers>Apprenticeships', 'Books and Literature'],
			stats: {
				views: 506824,
				likes: 7985,
				comments: 53,
				creationDate: '2024-08-28T17:05:08Z',
				duration: '72.706',
			},
			sentiment: {
				positive: '0.0',
				negative: '0.0',
				neutral: '0.0',
			},
			brandMentions: [
				{
					brand: 'Rekhia',
					voice: 1,
					metaData: 0,
				},
				{
					brand: 'the Will and Jada Smith Family Foundation',
					voice: 2,
					metaData: 0,
				},
				{
					brand: 'the Ride or Die Impact',
					voice: 1,
					metaData: 0,
				},
			],
		},
		recentVideos: null,
	},
];

type TMockDataItem = (typeof mockData)[0];

const columns = [
	{
		title: 'id',
		dataIndex: 'id',
		key: 'id',
		sorter: false,
		hidden: true,
		width: 150,
		flex: true,
		ellipsis: true,
		render: (_: any, record: TMockDataItem) => {
			return <p title={record?.id}>{record?.id?.split('-')?.at(-1)}</p>;
		},
	},
	{
		title: 'Date/time processed',
		dataIndex: 'processedDate',
		key: 'processedDate',
		// sorter: true,
		// sortDirections: ['ascend', 'descend', 'ascend'] as SortOrder[],
		width: 200,
		flex: true,
		// hidden: true,
		render: (_: any, record: TMockDataItem) => {
			return (
				<>
					{record?.processedDate
						? prepareDate(record?.processedDate)
						: 'no date'}
				</>
			);
		},
	},
	{
		title: 'Creator',
		dataIndex: 'creator',
		key: 'creator',
		// sorter: true,
		width: 200,
		flex: true,
		// hidden: true,
		render: (_: any, record: TMockDataItem) => {
			return (
				<Space size={[4, 1]}>
					<Avatar
						src={
							record?.creator?.thumbnail?.startsWith('http')
								? record?.creator?.thumbnail
								: CDN_URL + prepareImageSrc(record?.creator?.thumbnail)
						}
						alt={`${record?.creator?.channelName} avatar`}
					></Avatar>{' '}
					<Link href={record?.creator?.channelUrl}>
						{record?.creator?.channelName}
					</Link>
				</Space>
			);
		},
	},
	{
		title: 'Title',
		dataIndex: 'title',
		key: 'title',
		// sorter: true,
		// sortDirections: ['ascend', 'descend', 'ascend'] as SortOrder[],
		// width: 200,
		flex: true,
		ellipsis: true,
		// hidden: true,
		render: (_: any, record: TMockDataItem) => {
			return (
				<Typography.Text>{record?.video?.title || 'no title'}</Typography.Text>
			);
		},
	},
	{
		title: 'Date/time posted',
		dataIndex: 'creationDate',
		key: 'creationDate',
		// sorter: true,
		// sortDirections: ['ascend', 'descend', 'ascend'] as SortOrder[],
		width: 200,
		flex: true,
		// hidden: true,
		render: (_: any, record: TMockDataItem) => {
			return (
				<>
					{record?.video?.stats?.creationDate
						? prepareDate(record?.video?.stats?.creationDate)
						: 'no date'}
				</>
			);
		},
	},
	{
		title: 'Stats',
		dataIndex: 'stats',
		key: 'stats',
		// sorter: true,
		// sortDirections: ['ascend', 'descend', 'ascend'] as SortOrder[],
		width: 210,
		flex: true,
		// hidden: true,
		render: (_: any, record: TMockDataItem) => {
			return (
				<Flex
					gap={40}
					justify="flex-start"
					align="center"
					// className={style.statsWrapper}
				>
					<Typography.Text title="Views" className={styles.iconTextItem}>
						<EyeOutlined
							style={{
								color: record?.video?.stats?.views >= 0 ? BLUE_COLOR : 'gray',
							}}
						/>
						<span
							style={{
								width: 'max-content',
								color: record?.video?.stats?.views >= 0 ? ORANGE_COLOR : 'gray',
							}}
						>
							{record?.video?.stats?.views >= 0
								? formatNumber(record?.video?.stats?.views)
								: 'n/a'}
						</span>
					</Typography.Text>

					<Typography.Text title="Likes" className={styles.iconTextItem}>
						<LikeOutlined
							style={{
								color: record?.video?.stats?.likes >= 0 ? BLUE_COLOR : 'gray',
							}}
						/>{' '}
						<span
							style={{
								width: 'max-content',
								color: record?.video?.stats?.likes >= 0 ? ORANGE_COLOR : 'gray',
							}}
						>
							{record?.video?.stats?.likes >= 0
								? formatNumber(record?.video?.stats?.likes)
								: 'n/a'}
						</span>
					</Typography.Text>

					<Typography.Text title="Comments" className={styles.iconTextItem}>
						<CommentOutlined
							style={{
								color:
									record?.video?.stats?.comments >= 0 ? BLUE_COLOR : 'gray',
							}}
						/>{' '}
						<span
							style={{
								width: 'max-content',
								color:
									record?.video?.stats?.comments >= 0 ? ORANGE_COLOR : 'gray',
							}}
						>
							{record?.video?.stats?.comments >= 0
								? formatNumber(record?.video?.stats?.comments)
								: 'n/a'}
						</span>
					</Typography.Text>
				</Flex>
			);
		},
	},
	// {
	// 	title: 'URLs count',
	// 	dataIndex: 'urls',
	// 	key: 'urls',
	// 	// sorter: false,
	// 	width: 50,
	// 	flex: true,
	// 	// hidden: true,
	// 	render: (_: any, record: TMockDataItem) => {
	// 		return record?.urls?.length;
	// 	},
	// },
];

export { mockData, columns };
